import React from "react";

interface Props {
  image: string;
  title: string;
  description: string;
  pushlishDate?: string;
  postedOn?: string;
  slug: string;
  onCardClick: () => void;
}

const CareerVisualSpotBlogCards: React.FC<Props> = ({
  image,
  title,
  description,
  pushlishDate,
  postedOn,
  slug,
  onCardClick,
}) => {
  return (
    <div className="bg-white border border-[#EAEAEA] rounded-xl overflow-hidden">
      <div className="flex flex-col gap-5 h-48 overflow-hidden">
        <a href={`../blogs/career-courses/${slug}`}>
          {image ? <img src={image} alt={title} className="" /> : null}
        </a>
      </div>
      <div className="flex flex-col gap-5 pt-4 px-4 pb-9">
        <div className="flex flex-col gap-2.5">
          <h4 className="text-base font-semibold text-black line-clamp-1">
            {title}
          </h4>
          <p className="text-sm text-[#555555] font-nomal flex flex-row gap-1">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_308_4127)">
                <path
                  d="M10.9968 2.35682H10.4574V1.27808H9.37865V2.35682H3.98494V1.27808H2.9062V2.35682H2.36683C1.77352 2.35682 1.28809 2.84225 1.28809 3.43556V12.0655C1.28809 12.6588 1.77352 13.1442 2.36683 13.1442H10.9968C11.5901 13.1442 12.0755 12.6588 12.0755 12.0655V3.43556C12.0755 2.84225 11.5901 2.35682 10.9968 2.35682ZM10.9968 12.0655H2.36683V5.05367H10.9968V12.0655Z"
                  fill="#00C798"
                />
              </g>
              <defs>
                <clipPath id="clip0_308_4127">
                  <rect
                    width="12.9449"
                    height="12.9449"
                    fill="white"
                    transform="translate(0.208984 0.73877)"
                  />
                </clipPath>
              </defs>
            </svg>
            {pushlishDate}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-sm h-10 text-[#555555] line-clamp-2">
            {description}
          </p>
        </div>
      </div>
    </div>
    // <div
    //   className="block rounded-xl overflow-hidden cursor-pointer"
    //   onClick={onCardClick}
    // >
    //   <div className="flex flex-col gap-5 overflow-hidden">
    //   {/* <a  href={`../blogs/career-courses/${slug}`} target="_blank">
    //       {image ? <img src={image} alt={title} className="" /> : null}
    //     </a> */}
    //   </div>
    //   <div className="flex px-6">
    //     <div className="relative w-full flex flex-col bg-white border border-[#CAC6C6] p-3.5 -translate-y-6 z-2 rounded-2xl justify-center items-center gap-2.5">
    //       {pushlishDate && (
    //         <p className="text-sm text-gray-500 mb-2">
    //           Published on: {pushlishDate}
    //         </p>
    //       )}
    //       {postedOn && (
    //         <p className="text-sm text-gray-500 mb-2">Posted on: {postedOn}</p>
    //       )}
    //       <h4 className="text-base font-bold text-blue text-center line-clamp-2">
    //         {title}
    //       </h4>
    //       <p className="text-sm text-gray-600 text-center line-clamp-3">
    //         {description}
    //       </p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CareerVisualSpotBlogCards;
