import "./Summary.scss";
interface SummaryProps {
  toughChanceColleges: number;
  goodChanceColleges: number;
  getinChanceColleges: number;
  examName: string;
}
const Summary: React.FC<SummaryProps> = ({
  toughChanceColleges,
  goodChanceColleges,
  getinChanceColleges,
  examName,
}) => {
  const goodChances = 93;
  const mayGet = 0;
  const hardChances = 60;
  return (
    <>
      <div className="w-full flex flex-col md:flex-row justify-center item-center py-12 gap-2 md:gap-0">
        <div className="summary text-[#383838] mr-4 flex items-center max-md:justify-center">
          Summary of Your Prediction{" "}
        </div>
        <div className="max-md:px-2 flex max-md:justify-center">
          <div className="summary w-[147px] bg-[#0C9] text-white p-2 rounded-lg mr-4 flex max-md:justify-center">
            Good chances in {goodChanceColleges} colleges
          </div>
          <div className="summary w-[147px] bg-[#F6C] text-white p-2 rounded-lg mr-4 ">
            May get in {getinChanceColleges} colleges
          </div>
          <div className="summary w-[147px] bg-[#173CBA] text-white p-2 rounded-lg mr-4 flex items-center max-md:justify-center">
            Hard chances in {toughChanceColleges} colleges
          </div>
        </div>
        <a
          href={`/college-predictor/${examName}`}
          className="changeinput flex items-center max-md:justify-center"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9513 4.61421L11.7868 5.77174C11.9711 6.31333 12.0711 6.89474 12.0711 7.50005C12.0711 10.4416 9.70658 12.827 6.79079 12.827C3.875 12.827 1.51053 10.443 1.51053 7.50005C1.51053 4.55846 3.875 2.17306 6.79079 2.17306C8.24079 2.17306 9.55395 2.76244 10.5079 3.71687L11.7789 2.85138C10.5382 1.4974 8.76316 0.64917 6.79079 0.64917C3.04079 0.64917 0 3.71687 0 7.50005C0 11.2832 3.04079 14.3509 6.79079 14.3509C10.5408 14.3509 13.5816 11.2832 13.5816 7.50005C13.5816 6.46864 13.3553 5.49032 12.9513 4.61421Z"
              fill="#00CC99"
            />
            <path
              d="M6.79088 8.27261C8.73298 5.55004 11.2067 3.72084 14.0106 2.44385C14.1514 2.3496 14.2593 2.54473 14.1606 2.62703C11.633 4.79739 9.31061 7.38721 7.16982 10.35C6.94877 10.5943 6.56325 10.577 6.36456 10.3142L5.04482 8.56332C4.21193 7.51199 3.41456 7.09385 2.65667 7.34075C2.59482 7.36332 2.5514 7.29694 2.60667 7.24119L3.18167 6.55624C4.56061 5.09208 6.12772 7.62217 6.79088 8.27261Z"
              fill="#00CC99"
            />
          </svg>{" "}
          <span>Change Inputs </span>{" "}
        </a>
      </div>
    </>
  );
};

export default Summary;
