import React, { useState } from "react";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { RootState } from "../../../../store/store";
import {
  nextStep,
  updateForm,
  prevStep,
} from "../../../../store/slices/registrationFormSlice";
import OptionGroup from "./common/OptionsCard/OptionGroup";
import { signupPAFDetail } from "../../../../store/thunks/authThunks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export const COURSE_DEPARTMENTS: string[] = [
  "Accounting",
  "Actuarial Science",
  "Artificial Intelligence",
  "Astronomy",
  "Biomedical Engineering",
];

const CourseLookingFor = () => {
  const dispatch = useDispatch<any>();
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    currentLevelOfStudy,
    stream,
  } = useSelector((state: RootState) => state.registrationFormSlice.data);
  const { register, handleSubmit } = useForm();

  const { pafData } = useSelector((state: RootState) => state.auth);
  const { step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  console.log("step", step);

  const [selectedCard, setSelectedCard] = useState<any>("");
  const [selectedSingle, setSelectedSingle] = useState<any>(null);

  const onSubmit = (data: any) => {
    dispatch(updateForm({ course: selectedCard }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleSingleSelectionChange = (selected: any) => {
    setSelectedSingle(selected);
  };

  const handleNext = () => {
    console.log("get list of courses based on course", selectedSingle);
    dispatch(updateForm({ course: selectedSingle }));
    dispatch(
      signupPAFDetail({
        streamId: stream?.streamId,
        courseId: selectedSingle?.courseId,
        state: 0,
        city: 0,

        currentStudy: "",

        lookingFor: "",
        higherEducation: "",
        educationFund: "",
        importantFactor: "",
        boardOfEducation: "",
      })
    )
      .unwrap()
      .then(() => {
        dispatch(nextStep());
      })
      .catch((err: any) => {
        console.error("OTP verification failed:", err);
      });
  };

  const handlePrev = () => {
    dispatch(prevStep());
  };
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={false}
        showBack={true}
        stepName="Which Course Specialization are You Interested In ? "
        stepDescription=""
      >
        {/* <PlaceholderChipGroup
          cards={COURSE_DEPARTMENTS}
          selectMode={"multiple"}
          handleCardSelect={handleCardClick}
        /> */}
        <div className="w-full overflow-auto h-[400px]">
          {/* Old Implementation */}
          {/* <OptionGroup
            mode={"single"}
            options={COURSE_DEPARTMENTS}
            handleCardSelect={handleCardClick}
            cardSize={"auto"}
          /> */}
          {/* <OptionGroup
            options={pafData.lookingForStream}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.streamName
            }
            handleCardSelect={handleCardClick}
            cardSize={"80"}
          /> */}

          <OptionGroup
            options={pafData.lookingForCourse}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.courseName
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="auto"
            localStorageKey="lookingForCourse"
          />
          {/* <h3 className="mt-4">Selected Multiple Options (Full Objects):</h3>
          <pre>{JSON.stringify(selectedSingle, null, 2)}</pre> */}
        </div>
        <div className="flex justify-center">
          {/* <button
            type="button"
            onClick={handleNext}
            className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
            style={{ padding: "8px 18px" }}
          >
            Continue
          </button> */}

          <div className="flex items-center gap-2">
            {/* <button
              className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={handlePrev}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <span>Step {step} of 10 </span>
            <button
              className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={handleNext}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button> */}
            <button
              className="px-2 py-2 m-3 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={handlePrev}
            >
              Back
            </button>

            <button
              className={`py-2.5 px-2 md:px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200 ${
                selectedSingle ? "opacity-100" : "opacity-50 cursor-not-allowed"
              }`}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={() => {
                if (selectedSingle) {
                  handleNext();
                }
              }}
              disabled={!selectedSingle}
            >
              Next
            </button>
          </div>
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default CourseLookingFor;
