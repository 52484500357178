import React from "react";

export const MentorGrid = () => {
  return (
    <div className="relative bg-[#0C9] min-h-screen h-[764px] max-md:h-[1200px]  ">
      <div
        className="absolute inset-0 w-full bg-no-repeat bg-cover bg-bottom"
        style={{
          backgroundImage: `url('/images/mentors/mentorsgrid.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          width: "100%",
          height: "auto",
        }}
      ></div>

      <div className="relative z-10 py-8 px-6 text-center max-md:py-3">
        <div className="text-white mb-12">
          <h2 className="text-4xl font-bold max-md:text-3xl">
            Where Mentors Help Students Because It Feels Great to Support Others
          </h2>
          <p className="text-xl mt-2">
            Turn your knowledge into impact & income.
          </p>
          <a
            href="#"
            className="inline-block text-3xl font-bold mt-4 max-md:mt-[6px] "
          >
            Become a Mentor →
          </a>
        </div>
      </div>
    </div>
  );
};
