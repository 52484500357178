import React from "react";
interface Project {
  description: string;
  orderIndex: string;
  title: string;
}

interface StudentsProjectsProps {
  title: string;
  array: Project[];
}

const StudentsProjects: React.FC<StudentsProjectsProps> = ({
  title,
  array,
}) => (
  <section className="latest-updates-section mb-8">
    <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
      {title}
    </h2>

    <div className="space-y-4">
      {array.map((project, index) => (
        <div key={index} className="bg-white p-4">
          <h5 className="text-lg font-semibold">{project.title}</h5>
          <p className="text-base text-black mt-2">{project.description}</p>
        </div>
      ))}
    </div>
  </section>
);

export default StudentsProjects;
