import React from "react";

interface CutoffTable2024Props {
  title: string;
  description: string;
}

const CutoffTable2024: React.FC<CutoffTable2024Props> = ({
  title,
  description,
}) => {
  return (
    <section className="cutoff-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>

      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

export default CutoffTable2024;
