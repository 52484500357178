import React from "react";
import AdmissionIcon from "../../../images/college/details/admissions-icon.svg";
import ChatIcon from "../../../images/college/details/chatIcon.svg";
import NewsIcon from "../../../images/college/details/newsIcon.svg";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";
import config from "../../../util/config";

const chatOptionsList = [
  {
    id: 2,
    title: "Chat",
    imageIcon: ChatIcon,
  },
  {
    id: 3,
    title: "News",
    imageIcon: NewsIcon,
  },
];
const ChatOptions = ({ collegeTitle }: { collegeTitle: string }) => {
  return (
    <div className="absolute top-1/2 right-0 translate-y-1/2 chatOptions z-10">
      <ul className="flex flex-col gap-[1px] rounded-lg shadow-lg bg-white">
        <li
          key={1}
          className="flex flex-col items-center justify-center gap-2 bg-[#113cc0] text-white p-2 cursor-pointer rounded-sm"
        >
          <LeadGenCtaBtn
            pageName="college-details"
            ctaName="apply-admissions"
            additionalInfo={collegeTitle}
            className="text-center"
          >
            <img
              src={AdmissionIcon}
              alt={"Apply admissions"}
              className="w-6 h-6 object-cover mx-auto"
            />
            <span className="text-base">{"Apply admissions"}</span>
          </LeadGenCtaBtn>
        </li>
        {chatOptionsList.map((chatOption) => {
          let href = "";
          if (chatOption.title === "News") {
            href = "/blogs";
          } else if (chatOption.title === "Chat") {
            href = `https://api.whatsapp.com/send?text=hi&phone=91${config.mobileNumber}`;
          }
          return (
            <li
              key={chatOption.id}
              className="flex flex-col items-center justify-center gap-2 bg-[#113cc0] text-white p-2 cursor-pointer rounded-sm"
            >
              <a href={href} className="text-center" target="_blank">
                <img
                  src={chatOption.imageIcon}
                  alt={chatOption.title}
                  className="w-6 h-6 object-cover mx-auto"
                />
                <span className="text-base">{chatOption.title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChatOptions;
