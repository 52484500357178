import React, { useState, useEffect, useRef, useCallback } from "react";
import CarouselModule from "react-spring-3d-carousel";
import { config } from "react-spring";

interface Card {
  key: number; // Key for each card
  content: JSX.Element; // Content of the card (React element)
}

interface CarrousselProps {
  cards: Card[]; // Array of cards
  width: string; // Width of the carousel
  height: string; // Height of the carousel
  margin: string; // Margin for the carousel
  offset?: number; // Optional offset value
  showArrows?: boolean; // Optional show arrows
  goToSlide?: number; // Optional initial slide index
}

const Carousel = (CarouselModule as any).default || CarouselModule;

const Carroussel: React.FC<CarrousselProps> = ({
  cards,
  width,
  height,
  margin,
  offset = 2,
  showArrows = false,
  goToSlide = 0,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isHovered) {
      intervalRef.current = setInterval(() => {
        console.log("Auto-slide active. Current slide:", goToSlide);
      }, 3000);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isHovered]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const highlightStyle: React.CSSProperties = {
    transform: "scale(1.1)",
    transition: "transform 0.3s",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
  };

  return (
    <div
      style={{ width, height, margin }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Carousel
        slides={cards.map((card, index) => ({
          ...card,
          content: (
            <div style={index === goToSlide ? highlightStyle : {}}>
              {card.content}
            </div>
          ),
        }))}
        goToSlide={goToSlide}
        offsetRadius={offset}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
};

export default Carroussel;
