import { useState } from "react";
import icon1 from "../images/choose-college-mentor/icon-1.svg";
import icon2 from "../images/choose-college-mentor/icon-2.svg";
import icon3 from "../images/choose-college-mentor/icon-3.svg";
import icon4 from "../images/choose-college-mentor/icon-4.svg";
import icon5 from "../images/choose-college-mentor/icon-5.svg";
import { Link } from "react-router-dom";

const phoneUIImages = [
  "/images/college/landing/choose-college-mentor/phone-ui-1.webp",
  "/images/college/landing/choose-college-mentor/phone-ui-2.webp",
  "/images/college/landing/choose-college-mentor/phone-ui-3.webp",
  "/images/college/landing/choose-college-mentor/phone-ui-4.webp",
  "/images/college/landing/choose-college-mentor/phone-ui-5.webp",
];

const menuData = [
  {
    title: "College Predictor",
    description: "Predict Your Dream College by Rank",
    icon: icon1,
    color: "#F4D2D2",
    link: "/college-predictor",
  },
  {
    title: "College Compare",
    description: "Compare Your Dream Colleges",
    icon: icon2,
    color: "#F49FB6",
    link: "/college-compare",
  },

  {
    title: "College Scholarships",
    description: "Discover College Scholarships",
    icon: icon4,
    color: "#A6E0DE",
    link: "/scholarships",
  },
  {
    title: "Education Loan",
    description: "Domestic | International",
    icon: icon5,
    color: "#D1F8B6",
    link: "/scholarships?scrollToTabs=true",
  },
];

const ChooseCollegeMentor = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const changeActiveIndex = (index: number) => setActiveIndex(index);

  return (
    <div className="bg-[#FFFAFA] text-black">
      <div className="container py-12 max-sm:py-8">
        <div>
          <h2 className="text-4xl lg:text-5xl font-bold">
            Choose Your College Mentor - Planning Tools
          </h2>
          <h3 className="text-xl">Explore & Prepare</h3>
          <p className="text-base text-grey">
            Get the Information You Care About on Every College in India
          </p>
        </div>
        <div className="mt-6 flex gap-12 items-end lg:items-center overflow-hidden max-sm:mt-1">
          <div className="w-full md:w-2/5 lg:w-1/2">
            <div className="flex flex-col mt-12 max-sm:mt-2 ml-0 lg:ml-24">
              {menuData.map((item, index) => (
                <MenuItem
                  {...item}
                  onHover={() => changeActiveIndex(index)}
                  isActive={index == activeIndex}
                />
              ))}
            </div>
          </div>
          <div className="hidden md:flex md:w-3/5 w-1/2 justify-end">
            <Link
              to={menuData[activeIndex].link}
              className="block lg:w-3/4 z-10"
            >
              <img className="w-full" src={phoneUIImages[activeIndex]} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseCollegeMentor;

const MenuItem = ({
  title,
  link,
  description,
  icon,
  color,
  onHover,
  isActive,
}: {
  title: string;
  link: string;
  description: string;
  icon: string;
  color: string;
  onHover: () => void;
  isActive: boolean;
}) => (
  <Link to={link}>
    <div>
      <div className="flex items-center pt-8" onMouseEnter={onHover}>
        <img src={icon} />
        <div>
          <h3 className="text-xl">{title}</h3>
          <p className="text-base text-grey">{description}</p>
        </div>
      </div>
      <div
        className="h-1 w-[200%] mt-2"
        style={{ backgroundColor: isActive ? color : "transparent" }}
        onMouseEnter={onHover}
      ></div>
    </div>
  </Link>
);
