import React from "react";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";

const News: React.FC<{ newsAlertsData: any[] }> = ({ newsAlertsData }) => {
  if (!newsAlertsData || newsAlertsData.length === 0) {
    return null;
  }

  const NewsCard = ({
    id,
    title,
    publishedDate,
    imageUrl,
    slug,
  }: {
    id: number;
    title: string;
    publishedDate: string;
    imageUrl: string;
    slug: string;
  }) => (
    <a href={`/news/${slug}`}>
      <div className="flex flex-col pt-4 text-sm bg-white rounded-xl !w-full min-max-md:!w-[580px] min-max-xss:w-[340px] min-max-md:ml-20">
        <div className="flex pt-3 pl-3 pb-5">
          <img
            loading="lazy"
            src={imageUrl}
            className="shrink-0 max-w-full rounded-md aspect-[1.82] w-[132px] h-[73px]"
            alt="News Alert"
          />
          <div className="flex flex-col self-start px-4">
            <a
              href={`/news/${slug}`}
              className="news-description font-bold text-[#232323] line-clamp-2"
            >
              {title}
            </a>
            <p className="mt-3 text-[#232323]">{publishedDate}</p>
          </div>
        </div>
        <div className="w-full rounded-b-xl bg-[#173CBA] bg-opacity-80 min-h-[10px]" />
      </div>
    </a>
  );

  const newsContent = newsAlertsData?.map((data, index) => (
    <NewsCard {...data} />
  ));

  return (
    <div className="bg-[#D9D9D9] min-h-[329px] my-7 pb-6">
      <h1 className="news-alerts-title text-center pt-9 mb-10 min-max-xs:mb-7 min-max-xss:text-3xl text-[#383838] text-[40px] font-medium">
        News & Alerts
      </h1>
      <div className="container">
        <CustomSwiper
          className="!px-2"
          items={newsContent}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 1, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
            1280: { slidesPerView: 3, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default News;
