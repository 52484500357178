import React, { useState } from "react";
import { Link } from "react-router-dom";

const examsList = ["Exam 1", "Exam 2", "Exam 3", "Exam 4"];

const SearchBox = () => {
  const [search, setSearch] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredExams, setFilteredExams] = useState<string[]>([]);

  const handleSearchChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setSearch(value);

    if (value.length >= 3) {
      const filtered = examsList.filter((exam) =>
        exam.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredExams(filtered);
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  const handleOptionClick = (exam: React.SetStateAction<string>) => {
    setSearch(exam);
    setIsDropdownOpen(false);
  };

  const handleClear = () => {
    setSearch("");
    setIsDropdownOpen(false);
  };

  return (
    <div className="w-full lg:w-[468px] flex gap-5 relative text-[--primary-color] mt-5 lg:mt-10">
      <form className="bg-white pl-3 py-1 pr-1 md:pl-4 md:pr-2 md:py-2 rounded-full flex flex-row w-full items-center justify-between">
        <div className="flex flex-row gap-2 items-center w-full">
          <div className="flex flex-col w-5 h-5">
            <svg
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 md:w-5 md:h-5"
            >
              <path
                d="M16.6425 14.9422C17.7163 13.4758 18.3586 11.6747 18.3586 9.72205C18.3586 4.83413 14.3817 0.857178 9.49374 0.857178C4.60582 0.857178 0.628906 4.83413 0.628906 9.72205C0.628906 14.61 4.60586 18.5869 9.49379 18.5869C11.4464 18.5869 13.2477 17.9445 14.7141 16.8707L20.5217 22.6783L22.4502 20.7498C22.4502 20.7498 16.6425 14.9422 16.6425 14.9422ZM9.49379 15.8593C6.10952 15.8593 3.35658 13.1063 3.35658 9.72205C3.35658 6.33778 6.10952 3.58485 9.49379 3.58485C12.8781 3.58485 15.631 6.33778 15.631 9.72205C15.631 13.1063 12.878 15.8593 9.49379 15.8593Z"
                fill="#113CC0"
              />
            </svg>
          </div>
          <div className="relative w-full">
            <input
              type="text"
              id="search"
              value={search}
              onChange={handleSearchChange}
              placeholder="Search your dream exam"
              className="block w-full px-2 pr-4 text-xs md:text-base text-[#A3A2AC] outline-none leading-10"
            />
            {search.length > 0 && (
              <button
                type="button"
                onClick={handleClear}
                className="absolute right-2 top-1/2 transform text-xl -translate-y-1/2 text-blue hover:text-gray-600 rotate-45 !py-2"
              >
                +
              </button>
            )}
            {isDropdownOpen && filteredExams.length > 0 && (
              <div className="absolute mt-1 w-full bg-white border rounded-md shadow-lg z-10">
                {filteredExams.map((exam) => (
                  <div
                    key={exam}
                    onClick={() => handleOptionClick(exam)}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                  >
                    {exam}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* <Link
          to={"/tcy-online"}
          type="submit"
          className="flex justify-center py-2 px-6 border border-transparent rounded-full text-xs md:text-base font-semibold text-white bg-[--blue] whitespace-nowrap min-max-md:text-xs min-max-md:px-2"
        >
          Search Exam
        </Link> */}
      </form>
      {/* The search functionality has not been implemented yet. It will be developed once the real data is available. */}
      <button
        type="button"
        className=" absolute top-1/2 right-0 transform -translate-y-1/2 bg-[--primary-color] text-white md:py-3 px-3 rounded-full right-1"
        style={{
          background: "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
        }}
      >
        <span className="min-max-xss:hidden min-max-xs:hidden min-max-sm:hidden min-max-md:hidden">
          Search Exam
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
          className="arrow-icon m-2"
        >
          <path
            d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  );
};

export default SearchBox;
