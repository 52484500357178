import React from "react";

interface TopRecruitersProps {
  title: string;
  description: string;
}

const TopRecruiters: React.FC<TopRecruitersProps> = ({
  title,
  description,
}) => (
  <section className="top-recruiters">
    <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
      {title}
    </h2>
    <div
      className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </section>
);

export default TopRecruiters;
