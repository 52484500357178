import { Key, useEffect, useRef, useState } from "react";

import Banner from "./components/Banner";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
  IClearFilter,
} from "../../components/filter/customHooks/useSelectedFilter";
import { IFilterDataItem } from "../../components/filter/components/DesktopFilterMenu";
import {
  FilterActions,
  FilterCategory,
} from "../../components/filter/FilterEnum";
import TabSelector from "../../components/filter/components/TabSelector";
import axios from "axios";
import ExamCard from "./components/ExamCard";

import { ExamFiltersData } from "../exams/mock/mockData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { Option } from "../colleges/components/Accordion";
import { SortBy } from "../colleges/components/SortBy";
import Loader from "../../components/Loader/Loader";
import FiltersSection from "../colleges/components/FilterSection";
import Pagination from "../../components/filter/components/Pagination";
import useQueryParamPagination from "../../components/filter/customHooks/useQueryParamPagination";
import useDebouncedSearch from "../../components/filter/customHooks/useDebouncedSearch";
import config from "../../util/config";
import {
  ISelectedFilters,
  useFiltersAndTags,
} from "../colleges/CollegeIntermediate";
import { log } from "console";
const EXAM_CATEGORY_FILTER_CATEGORY = "examCategory";

const filterCategories: IFilterDataItem = {
  categoryName: "Exam Category",
  categoryKey: EXAM_CATEGORY_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Engineering", key: "engineering" },
    { name: "Medical", key: "medicine-and-allied-sciences" },
    { name: "Management", key: "management-and-business-administration" },
    { name: "Law", key: "law" },
    { name: "Animation and Design", key: "animation-and-design" },
    {
      name: "Media",
      key: "media-and-mass-communication-and-journalism",
    },
    {
      name: "Arts",
      key: "arts-humanities-and-social-sciences",
    },
    { name: "Commerce", key: "commerce" },
    { name: "Computer Application", key: "computer-application-and-it" },
    { name: "Pharmacy", key: "pharmacy" },
    { name: "Education", key: "education" },
    { name: "Hospitality and Tourism", key: "hospitality-and-tourism" },
    { name: "Competition", key: "competition" },
    { name: "School", key: "school" },
    { name: "Study Abroad", key: "study-abroad" },
    { name: "Architecture", key: "architecture" },
    { name: "Accounts & Finance", key: "accounts-and-Finance" },
    { name: "Internships", key: "internships" },
  ],
};

type Category = {
  categoryName: string;
  categoryKey: string;
  count?: number;
  options: Option[];
};

type FiltersType = Category[];

const ExamIntermediate = () => {
  const listingContainerRef = useRef<HTMLDivElement>(null);

  const {
    filtersData,
    setFiltersData,
    selectedVal,
    setSelectedVal,
    handleFilterRemove,
  } = useFiltersAndTags();

  const initialSelectedFilter: ISelectedFilter = {
    singleSelectFilters: {
      "": "",
    },
    multiSelectFilters: {},
    radioFilters: {},
  };
  const [intermediateData, setIntermediateData] = useState([]);
  const [loading, setLoading] = useState(true);

  const isMediumDevice = window.innerWidth >= 1024;

  const [selectedFilter, setSelectedFilter] = useState<ISelectedFilter>(
    initialSelectedFilter
  );
  const [stream, setStream] = useState("");
  const [totalExamCount, setTotalExamCount] = useState();

  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  const updateSelectedFilter: IUpdateSelectedFilter = (key, action, value) => {
    setStream(value);
    setSelectedFilter((prev) => ({
      ...prev,
      singleSelectFilters: {
        ...prev.singleSelectFilters,
        [key]: value,
      },
    }));
  };

  const [selectedSort, setSelectedSort] = useState<string>("popular");

  const sortOptions =
    filtersData?.find((filter: any) => filter?.categoryKey === "sortBy")
      ?.options || [];

  const handleSortChange = (sortKey: string) => {
    setSelectedSort(sortKey);
  };
  const PAGE_SIZE = 10;
  const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    handleResetPagination,
  } = useQueryParamPagination(PAGE_SIZE);

  useEffect(() => {
    handleResetPagination();
  }, [selectedVal]);

  useEffect(() => {
    updateTotalCount(totalExamCount || 0);
  }, [totalExamCount]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          config.apiUrl + "/api/exam/intermediate",
          {
            ...selectedVal,
            sortBy: selectedVal.sortBy || selectedSort,
            stream: stream,
            offset: currentPage - 1,
            fetchCount: PAGE_SIZE,
            search: deboundedSearchKeyword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
          }
        );
        setIntermediateData(response.data.data.data.exams);
        if (filtersData?.length === 0) {
          setFiltersData(response.data.data.filters);
        }
        setTotalExamCount(response.data?.data?.data?.totalexamCount);
      } catch (error) {
        console.error("Error making API request:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedVal, selectedSort, stream, currentPage, deboundedSearchKeyword]);

  const handleClearFilters = () => {
    setSelectedVal({});

    setFiltersData((prevFilters: any) => {
      const updatedFilters = prevFilters.map((filter: any) => {
        if (!filter.options) {
          return filter;
        }

        return {
          ...filter,
          options: filter.options.map((option: any) => ({
            ...option,
            isSelected: false,
          })),
        };
      });

      return updatedFilters;
    });

    handleResetPagination();

    if (listingContainerRef.current) {
      listingContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const clearFilter: IClearFilter = () => {
    setSelectedFilter({
      singleSelectFilters: {
        "": "",
      },
      multiSelectFilters: {},
      radioFilters: {},
    });
  };

  const colour = "green";

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const handleMobileFilter = () => {
    setIsFilterMenuOpen(true);
  };
  const handleMobileFilterClose = () => {
    setIsFilterMenuOpen(false);
  };

  return (
    <div className="bg-grey-2 text-black font-inter">
      <Banner />
      <div className="container max-w-screen-2xl">
        <div className="flex gap-8 justify-center pt-12">
          <TabSelector
            filterData={filterCategories}
            selectedFilter={selectedFilter}
            updateSelectedFilter={updateSelectedFilter}
            clearFilter={clearFilter}
            color={colour}
          />
        </div>
        <div className="flex flex-col lg:flex-row min-max-md:flex-row md:gap-6 py-12">
          <div className="w-1/4 filter-section">
            <div
              className={`bg-[#061958] rounded-lg min-max-md:p-3 ${
                isMediumDevice ? "p-4" : ""
              }`}
            >
              {isMediumDevice && (
                <div className="flex flex-row justify-between border-b border-[#E0E0E0] items-center">
                  <h4 className="text-lg font-bold text-[#fff]">Filter by</h4>

                  <button
                    onClick={handleClearFilters}
                    className="text-[#fff] text-sm font-medium"
                  >
                    Clear All
                  </button>
                </div>
              )}
              <div>
                <FiltersSection
                  filters={filtersData}
                  setSelectedVal={setSelectedVal}
                  setFilters={setFiltersData}
                  mobileFilter={isFilterMenuOpen}
                  handleMobileFilterClose={handleMobileFilterClose}
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-3/4 min-max-md:w-3/4 flex flex-col gap-6 relative">
            <div className="flex items-center justify-between md:justify-end gap-4 w-full">
              <div className="flex flex-col xl:flex-row min-max-md:flex-row max-md:flex-row items-start justify-end w-full max-lg:gap-4">
                {!isMediumDevice && (
                  <button
                    className="bg-white rounded-md p-3 min-max-md:hidden"
                    onClick={handleMobileFilter}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="filter"
                      className="svg-inline--fa fa-filter fa-lg "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      color="white"
                      stroke="#7D7B89"
                      stroke-width="40"
                    >
                      <path
                        fill="currentColor"
                        d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
                      ></path>
                    </svg>
                  </button>
                )}

                <div className="flex flex-col gap-4 items-start w-full">
                  <div className="flex flex-col lg:flex-row items-center justify-between w-full">
                    <div className="search-box relative max-lg:w-full">
                      <input
                        type="text"
                        placeholder={`Search ${
                          isMediumDevice ? "your Dream College" : ""
                        }`}
                        className={`search-input ${
                          isMediumDevice ? "w-[378px]" : "w-full"
                        } rounded-md px-5 py-3`}
                        value={searchKeyword}
                        onChange={(e) => handleKeywordChange(e.target.value)}
                      />
                      {searchKeyword && searchKeyword.length > 0 ? (
                        <button
                          className="absolute right-5 top-3"
                          onClick={() => handleKeywordChange("")}
                        >
                          <FontAwesomeIcon
                            icon={faX}
                            size="sm"
                            className="text-grey"
                          />
                        </button>
                      ) : (
                        <button className="absolute right-5 top-3">
                          <FontAwesomeIcon
                            icon={faSearch}
                            size="sm"
                            className="text-grey"
                          />
                        </button>
                      )}
                    </div>

                    <div className="z-10 max-md:hidden">
                      {sortOptions.length > 0 && (
                        <div className="flex">
                          <SortBy
                            options={sortOptions}
                            selectedSort={selectedSort}
                            onSortChange={handleSortChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-3">
                    <div className="text-base lg:text-lg text-grey shrink-0 md:min-w-48 min-max-md:text-base">
                      <h3>{`Showing ${intermediateData.length} of ${totalExamCount} Exams`}</h3>
                    </div>
                    <div className="flex xl:flex-row min-max-md:flex-row items-center">
                      <div className="selected-filters-container flex gap-2">
                        {stream && (
                          <div className="filter-chip flex items-center border rounded-full px-3 py-1 text-sm bg-white shadow-sm">
                            <span>
                              {filterCategories.options.find(
                                (opt) => opt.key === stream
                              )?.name || stream}
                            </span>
                            <button
                              onClick={() => {
                                clearFilter();
                                setStream("");
                              }}
                              className="ml-2 text-gray-500 hover:text-gray-700"
                            >
                              &times;
                            </button>
                          </div>
                        )}
                        {Object.entries(selectedVal).map(
                          ([category, values]) => {
                            const filterOptions =
                              filtersData.find(
                                (f: any) => f.categoryKey === category
                              )?.options || [];

                            return Array.isArray(values) &&
                              values.length > 0 ? (
                              values.map((value) => {
                                const optionName =
                                  filterOptions.find(
                                    (opt: any) => opt.key === value
                                  )?.name || value;
                                return (
                                  <div
                                    key={value}
                                    className="filter-chip flex items-center border rounded-full px-3 py-1 text-sm bg-white shadow-sm"
                                  >
                                    <span>{optionName}</span>
                                    <button
                                      onClick={() =>
                                        handleFilterRemove(category, value)
                                      }
                                      className="ml-2 text-gray-500 hover:text-gray-700"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                );
                              })
                            ) : values && values.length > 0 ? (
                              <div className="filter-chip flex items-center border rounded-full px-3 py-1 text-sm bg-white shadow-sm">
                                <span>
                                  {filterOptions.find(
                                    (opt: any) => opt.key === values
                                  )?.name || values}
                                </span>
                                <button
                                  onClick={() =>
                                    handleFilterRemove(
                                      category,
                                      values as string
                                    )
                                  }
                                  className="ml-2 text-gray-500 hover:text-gray-700"
                                >
                                  &times;
                                </button>
                              </div>
                            ) : null;
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-base lg:text-lg text-grey shrink-0 md:min-w-48 min-max-md:text-base md:hidden">
              <h3>{`Showing ${intermediateData.length} of ${totalExamCount} Exams`}</h3>
            </div> */}
            {loading ? <Loader /> : <ExamCard data={intermediateData} />}

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamIntermediate;
