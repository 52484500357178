import React from "react";
import BannerSection from "../../components/study-abroad-student/StudyStudentBanner";
import JourneySection from "../../components/study-abroad-student/JourneySection";
import CourseSelection from "../../components/study-abroad-student/CourseSelection";
import UniversityShortlisting from "../../components/study-abroad-student/UniversityShortlisting";
import SOPLORCrafting from "../../components/study-abroad-student/SOPLORCrafting";
import ApplicationFiling from "../../components/study-abroad-student/ApplicationFiling";
import AdmissionInterview from "../../components/study-abroad-student/AdmissionInterview";
import ScholarshipGuidance from "../../components/study-abroad-student/ScholarshipGuidance";
import EducationLoan from "../../components/study-abroad-student/EducationLoan";
import PersonalisedGuidance from "../../components/study-abroad-student/PersonalisedGuidance";

const StudyAbroadStudent: React.FC = () => {
  const data = {
    bannerSection: {
      title: "The World is your",
      description:
        "We are making overseas education accessible to everyone. Our tech-enabled solutions assist international students and recruitment partners in selecting the best global universities.",
      services: {
        title: "College Mentor Services & Offerings",
        description:
          "Comprehensive Pre- and Post-Departure Services for a Smooth Transition Abroad",
        buttonText: "Expert Mentorship",
      },
    },
    journeySection: {
      title: "Start your Journey",
      subtitle: "Profile Evaluation",
      description:
        "Understanding your interests, preferences, and eligibility is crucial to selecting the right country, universities, and courses that align with your profile.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    courseSelection: {
      title: "Course Selection",
      description:
        "After evaluating your profile, we`ll assist you in choosing the best study programs that fit your career goals and university preferences.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    universityShortlisting: {
      title: "University Shortlisting",
      description:
        "We'll create a list of universities offering courses that match your interests, providing detailed information about each institution.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    sopLorCrafting: {
      title: "SOP, LOR Crafting",
      description:
        "Our documentation team will craft compelling admission essays, SOPs, LORs, and resumes, ensuring your application stands out.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    applicationFiling: {
      title: "Application Filing",
      description:
        "We ensure error-free applications tailored to each university's requirements, submitted promptly and accurately.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    admissionInterview: {
      title: "Admission Interview",
      description:
        "We'll guide you through the interview process with valuable tips and strategies to excel in your Q&A sessions with university representatives.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    scholarshipGuidance: {
      title: "Scholarship Guidance",
      description:
        "We provide support with scholarship applications, essay templates, and advice to enhance your profile and secure funding.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    educationLoan: {
      title: "Education Loan",
      description:
        "Our loan experts simplify the process of securing financial support, ensuring your education expenses are covered efficiently.",
      buttonText: "Connect to Study Abroad Mentor",
    },
    // Data passed to PersonalisedGuidance component
    personalisedGuidance: {
      title: "Visit our centres for Personalised Guidance",
      items: [
        {
          checkBoxText: "Direct access to one on one session with Mentors",
        },
        {
          checkBoxText:
            "Receive instant application guidance from industry experts",
        },
        {
          checkBoxText:
            "Bring your parents for a complete program understanding",
        },
        {
          checkBoxText: "Enjoy exclusive offers and unique goodies",
        },
      ],
      buttonText: "Schedule Visit",
    },
  };

  return (
    <div className="flex flex-col lg:gap-1.5">
      <BannerSection data={data.bannerSection} />
      <JourneySection data={data.journeySection} />
      <CourseSelection data={data.courseSelection} />
      <UniversityShortlisting data={data.universityShortlisting} />
      <SOPLORCrafting data={data.sopLorCrafting} />
      <ApplicationFiling data={data.applicationFiling} />
      <AdmissionInterview data={data.admissionInterview} />
      <ScholarshipGuidance data={data.scholarshipGuidance} />
      <EducationLoan data={data.educationLoan} />
      <PersonalisedGuidance data={data.personalisedGuidance} />
    </div>
  );
};

export default StudyAbroadStudent;
