import React from "react";
import { Link } from "react-router-dom";

const ScholorshipApply = () => {
  return (
    <div className="px-20 py-10 w-full bg-[#FFF9E5] max-lg:px-7 max-lg:w-full my-6">
      <div className="flex gap-5 max-lg:flex-col-reverse">
        <div className="flex flex-col w-[51%] max-lg:ml-0 max-lg:w-full">
          <img
            src="https://cdncollegementor.blob.core.windows.net/cm-scholarship/sc-apply.webp"
            alt="a woman using a laptop and noting down something in a notebook"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-lg:mt-10 max-lg:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 ml-5 w-[49%] max-lg:ml-0 max-lg:w-full my-auto leading-[30px]">
          <div>
            <span className="text-black text-2xl font-semibold leading-[48px]">
              How to Apply
            </span>
            <ul className="list-disc pl-4 p-3">
              <li>
                Click on{" "}
                <a href="#banner" className="text-blue">
                  this link
                </a>{" "}
                and start your application process
              </li>
              <li>Fill all the details in the registration form and submit.</li>
            </ul>
            <span className="text-black text-2xl font-semibold">
              Important Instructions
            </span>
            <ul className="list-disc pl-4 p-3">
              <li>Exam can be taken from at the comfort of your place</li>
              <li>
                Exam can be attended using any of the following devices –
                Laptop, Mobile, Tablet or computer during test dates
              </li>
              <li>
                An AI powered invigilator will monitor the exam. Any malpractice
                detected will result in immediate termination of the test
              </li>
              <li>Ensure a stable internet connection to avoid disruptions.</li>
            </ul>
            <span className="text-black text-2xl font-semibold">
              For Queries
            </span>
            <ul className="list-disc pl-4 p-3">
              <li>Email: admin@collegementor.com</li>
              <li>Phone: +91 7997166666, +91 8959591818</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholorshipApply;
