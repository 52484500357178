import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import Loader from "./Loader/Loader";
import config from "../util/config";

const LeadGeneration = () => {
  const { isLoading, isSuccessful, isAlertOpen } = useSelector(
    (state: RootState) => state.leadGen
  );

  if (isLoading)
    return (
      <div className="fixed inset-0 bg-[#000000dd] z-[2000000]">
        <Loader />
      </div>
    );
  else if (isAlertOpen && isSuccessful)
    return (
      <div className="fixed top-12 left-1/2 -translate-x-1/2 px-4 py-2 rounded bg-green text-white z-[2000000] w-max max-w-[95vw]">
        <p className="lg:text-center">
          Thank you for registering with College Mentor!
        </p>
        <p className="lg:text-center">
          Our expert mentors will connect with you soon to guide you toward your
          goals.
        </p>
        <p className="lg:text-center">
          For immediate assistance, call us at {config.mobileNumber}.
        </p>
      </div>
    );
  else if (isAlertOpen)
    return (
      <div className="fixed top-12 left-1/2 -translate-x-1/2 px-4 py-2 rounded bg-red text-white z-[2000000]">
        Error sending request!
      </div>
    );
};

export default LeadGeneration;
