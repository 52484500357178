import { FC, useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  useFieldArray,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import Modal from "../../../components/Modal";
import { getMyProfile, updateMyProfile } from "./myProfileThunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { FieldInput, FormInput } from "./FieldInput";

interface FormData {
  [field: string]: string | number;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  subCategory: {
    name?: string;
    repeatedFieldGroupKey?: string;
    fields: {
      key: string;
      name: string;
      type: FormInput;
      required: boolean;
      options?: { key: string | number; name: string }[];
      validationRegex?: RegExp;
    }[];
  }[];
}

const ProfileDetailEditModal: FC<IProps> = ({
  isOpen,
  onClose,
  name,
  subCategory,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<FormData>({ mode: "onChange" });

  const userData = useSelector((state: RootState) => state.myProfile.userData);
  const [hasChanges, setHasChanges] = useState(false);

  const watchedValues = watch();

  useEffect(() => {
    reset(userData);
  }, [userData]);

  useEffect(() => {
    const hasFieldChanged = Object.keys(watchedValues).some(
      (key) => watchedValues[key] !== userData[key]
    );
    setHasChanges(hasFieldChanged);
  }, [watchedValues, userData]);

  const onSubmit = async (data: FormData) => {
    // for sending only the updated fields (as of now commented because phone number is required by the API)
    // const updatedData: { [key: string]: string | number } = {};
    // Object.entries(data).forEach(([key, value]) => {
    //   if (!userData[key] || userData[key] != value) updatedData[key] = value;
    // });

    await dispatch(updateMyProfile(data));
    dispatch(getMyProfile());
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form
        className="w-[90vw] lg:w-[60vw] bg-white p-6 lg:p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-xl font-bold">{name}</h2>
        <div className="divide-y">
          {subCategory.map((subCategory, index) => (
            <div className="py-4" key={index}>
              {subCategory.name && (
                <h3 className="text-md font-medium text-grey mb-1">
                  {subCategory.name}
                </h3>
              )}

              {subCategory.repeatedFieldGroupKey ? (
                <SubCategoryFormWithRepeatedFieldGroup
                  register={register}
                  errors={errors}
                  control={control}
                  subCategory={subCategory}
                />
              ) : (
                <div className="grid lg:grid-cols-3 gap-4">
                  <SubCategoryForm
                    register={register}
                    errors={errors}
                    subCategory={subCategory}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <button
          type="submit"
          disabled={!isValid || !hasChanges}
          className={`text-base font-semibold text-white px-12 py-2 mx-auto rounded ${
            isValid && hasChanges
              ? "bg-blue-500 hover:bg-blue-600"
              : "bg-gray-400 cursor-not-allowed"
          }`}
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

interface ISubCategoryForm {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
  subCategory: {
    name?: string;
    fields: {
      key: string;
      name: string;
      type: FormInput;
      required: boolean;
      options?: { key: string | number; name: string }[];
      validationRegex?: RegExp;
    }[];
  };
}

const SubCategoryForm: FC<ISubCategoryForm> = ({
  register,
  errors,
  subCategory,
}) => {
  return subCategory.fields.map((field) => (
    <div key={field.key}>
      <div className="mb-2">{field.name}</div>
      <FieldInput
        fieldKey={field.key}
        {...field}
        register={register}
        errors={errors[field.key]?.message || ""}
      />
    </div>
  ));
};

interface ISubCategoryFormWithRepeatedFieldGroup {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
  control: Control<FormData | any>;
  subCategory: {
    name?: string;
    repeatedFieldGroupKey?: string;
    fields: {
      key: string;
      name: string;
      type: FormInput;
      required: boolean;
      options?: { key: string | number; name: string }[];
      validationRegex?: RegExp;
    }[];
  };
}

const SubCategoryFormWithRepeatedFieldGroup: FC<
  ISubCategoryFormWithRepeatedFieldGroup
> = ({ register, errors, control, subCategory }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: subCategory.repeatedFieldGroupKey || "",
  });

  return (
    <div className="flex flex-col gap-4">
      {fields.map((fieldArrayItem, fieldArrayItemIndex) => (
        <div
          key={fieldArrayItem.id}
          className="grid lg:grid-cols-3 gap-4 items-end justify-center"
        >
          {subCategory.fields.map((field) => (
            <div key={field.key}>
              <div className="mb-2">{field.name}</div>
              <FieldInput
                fieldKey={`${subCategory.repeatedFieldGroupKey}.${fieldArrayItemIndex}.${field.key}`}
                {...field}
                register={register}
                errors={
                  errors[
                    `${subCategory.repeatedFieldGroupKey}.${fieldArrayItemIndex}.${field.key}`
                  ]?.message || ""
                }
              />
            </div>
          ))}
          <button
            className="text-base font-semibold text-white px-12 py-2 bg-red mx-auto rounded"
            onClick={(e) => {
              e.preventDefault();
              remove(fieldArrayItemIndex);
            }}
          >
            remove
          </button>
        </div>
      ))}
      <button
        className="text-base font-semibold text-white px-12 py-2 bg-green mx-auto rounded"
        onClick={(e) => {
          e.preventDefault();
          append({});
        }}
      >
        add
      </button>
    </div>
  );
};

export default ProfileDetailEditModal;
