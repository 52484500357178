import { useState } from "react";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import "./Banner.scss";

const Banner = () => {
  const data = useSelector(
    (state: RootState) => state.collegePredictorIntermediate.landingData
  );

  console.log(data);

  const breadcrumbs = data?.breadCrumbs?.paths;

  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <section className="college-predictor-intermediate py-7 lg:py-5 bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] text-black">
      <div className="container mx-auto flex flex-col gap-4">
        <div className="flex flex-col leading-10">
          <Breadcrumbs
            links={[
              { name: "Home", path: "/" },
              {
                name: breadcrumbs?.[1],
                path: "/college-predictor",
              },
              { name: breadcrumbs?.[2], path: "/" },
            ]}
          />
        </div>
        <div className="flex gap-12 max-lg:flex-col items-center">
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-6">
                <h1 className="text-5xl font-bold text-blue leading-[55px]">
                  {data?.bannerInfo?.title}
                </h1>
                <div
                  className="description flex flex-col gap-4"
                  dangerouslySetInnerHTML={{
                    __html: data?.bannerInfo?.description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="relative w-full h-0 pb-[56.25%]">
              {isPlaying ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={"https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1"}
                  title="YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
                ></iframe>
              ) : (
                <button
                  onClick={handlePlayVideo}
                  className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
                >
                  <img
                    src={
                      "/images/college/landing/building-bridges-video-thumbnail.png"
                    }
                    alt="video thumbnail"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
