import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const FAQ = () => {
  // Mock data
  const mockData = [
    {
      question: "How to compare between two universities?",
      answer:
        "Steps to Compare Universities: Check the applicable basis of university comparison that you prefer. It could be based on the Global, Regional, or Subject rankings, as well as Academics, Cost, Test Scores, and Numbers.",
    },
    {
      question: "Which is better, College or Institute?",
      answer:
        "Universities offer a wide range of degree programs across different fields of study, while institutes often focus on a specific area of study or research. Institutes may offer undergraduate or graduate degree programs, but their programs are more concentrated in a specific field.",
    },
    {
      question: "Is IIT an Institute or University?",
      answer:
        "The Indian Institutes of Technology (IITs) are a group of autonomous prestigious engineering and technology-oriented institutes of higher education established and declared as Institutes of National Importance by the Parliament of India.",
    },
    {
      question: "Which is No 1 IIT in India?",
      answer:
        "According to the NIRF Ranking, IIT Madras is the No. 1 IIT, and Top Institutes in India also include Chennai Tamil Nadu.",
    },
  ];

  const [viewMore, setViewMore] = useState(false);

  // Display only the first 3 questions by default
  const dataToShow = viewMore ? mockData : mockData.slice(0, 3);

  const cardBgColors = ["#FFF9E5", "#FFEFEE", "#FFF1FA", "#EBFFFA"];
  const cardBorderColors = ["#FFD540", "#DA4A41", "#F6C", "#27D6AC"];

  return (
    <div className="py-12 bg-[#F0F4FF] text-black">
      <div className="container flex flex-col gap-6">
        <h1 className="text-4xl lg:text-5xl font-bold text-center">
          Frequently Asked Questions (FAQs)
        </h1>
        {dataToShow.map((item, index) => (
          <div
            key={index}
            className="p-6 rounded-2xl"
            style={{
              border: `1px solid ${
                cardBorderColors[index % cardBorderColors.length]
              }`,
              background: cardBgColors[index % cardBgColors.length],
            }}
          >
            <p className="text-sm lg:text-xl">
              Question: <span className="font-semibold">{item.question}</span>
            </p>
            <p className="text-xs lg:text-lg">
              <span className="font-medium">Answer: </span>
              {item.answer}
            </p>
          </div>
        ))}
        {mockData.length > 3 && (
          <button
            className="self-end px-4 py-1 text-xs lg:text-xl text-white bg-blue"
            onClick={() => setViewMore((prev) => !prev)}
          >
            {`View ${viewMore ? "less" : "more"} `}
            <FontAwesomeIcon icon={viewMore ? faChevronUp : faChevronDown} />
          </button>
        )}
      </div>
    </div>
  );
};

export default FAQ;
