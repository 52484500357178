import React, { useState, useEffect } from "react";
import "./Banner.scss";

import happyStudent from "../../images/banner/happy-student.webp";
import womenIcon from "../../images/banner/women.jpg";
import designIcon from "../../images/banner/design-icon.svg";
import graduateIcon from "../../images/banner/graduate-icon.svg";
import medicineIcon from "../../images/banner/medicine-icon.svg";
import engineeringIcon from "../../images/banner/engineering-icon.svg";
import managementIcon from "../../images/banner/management-icon.svg";
import lawIcon from "../../images/banner/law-icon.svg";
import fashionDesignIcon from "../../images/banner/fashion-design-icon.svg";
import { MotionValue, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const careerPathData = [
  {
    path: "Engineering",
    avatar: null,
    icon: engineeringIcon,
  },
  {
    path: "Alum & Mentor",
    avatar: null,
    icon: graduateIcon,
  },
  {
    path: "Medicine",
    avatar: null,
    icon: medicineIcon,
  },
  {
    path: "Law",
    avatar: null,
    icon: lawIcon,
  },
  {
    path: "Fashion design",
    avatar: null,
    icon: fashionDesignIcon,
  },
  {
    path: "Management",
    avatar: null,
    icon: managementIcon,
  },
  {
    path: "Design",
    avatar: null,
    icon: designIcon,
  },
];

interface props {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
  animationItemRef: React.RefObject<HTMLDivElement>;
  animatedItemProps: {
    x: MotionValue<number>;
    y: MotionValue<number>;
    scale: MotionValue<number>;
  };
  isPulse: boolean;
}

const pulseVariant1 = {
  pulse: { scale: [1, 2], opacity: [1, 0], transition: { duration: 2 } },
  stop: { scale: [1], opacity: [1] },
};

const pulseVariant2 = {
  pulse: {
    scale: [1, 2],
    opacity: [1, 0],
    transition: { duration: 1.6, delay: 0.4, ease: "easeOut" },
  },
  stop: { scale: [1], opacity: [1] },
};

export default function Banner({
  animationCheckPointRef,
  animationItemRef,
  animatedItemProps,
  isPulse,
}: props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isGif, setIsGif] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  const handleOpenModal = () => {
    setIsOpen(true);
    setIsGif(false);
  };
  const showModalPopup = () => {
    setIsOpen(true);
    setIsGif(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="banner-section bg-[#0c9] py-12">
      <div className="container max-w-[1200px] flex flex-row mx-auto justify-between">
        <div className="text-left md:w-[50%] flex items-center banner-left max-md:w-full  text-base font-semibold text-neutral-600">
          <div className="text-white">
            <div className="w-full max-md:max-w-full">
              Nurturing Your Pathway to Success
            </div>
            <div className="w-full text-5xl leading-[66px] text-blue-950 max-md:max-w-full max-md:text-4xl">
              Dreams to Degrees
            </div>
            <div className="mt-3.5 w-full text-sm leading-7 max-md:max-w-full">
              College Mentor connects you with Mentors who share their
              experiences, provide insights, educational content and inspire you
              to reach for the stars. It's Mentorship in the Digital Age.
            </div>
            <div className="flex gap-3.5 mt-10 text-white max-md:flex-wrap">
              <div className="w-[80%] relative">
                <svg
                  width="23"
                  height="23"
                  className="absolute top-1/2 left-5 transform -translate-y-1/2"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3203 14.4873C17.3941 13.021 18.0364 11.2198 18.0364 9.26722C18.0364 4.37929 14.0594 0.402344 9.17148 0.402344C4.28355 0.402344 0.306641 4.37929 0.306641 9.26722C0.306641 14.1551 4.28359 18.1321 9.17152 18.1321C11.1241 18.1321 12.9255 17.4897 14.3918 16.4159L20.1994 22.2235L22.1279 20.295C22.1279 20.2949 16.3203 14.4873 16.3203 14.4873ZM9.17152 15.4044C5.78725 15.4044 3.03431 12.6515 3.03431 9.26722C3.03431 5.88295 5.78725 3.13001 9.17152 3.13001C12.5558 3.13001 15.3087 5.88295 15.3087 9.26722C15.3087 12.6515 12.5557 15.4044 9.17152 15.4044Z"
                    fill="currentColor"
                  />
                </svg>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "100%" }}
                >
                  <div style={{ width: "100%" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      className="w-full py-[14px] pl-14 pr-16 lg:pr-[164px] rounded-full focus:outline-none focus:border-[#00A3FF] text-black text-xs md:text-base"
                    />
                  </div>
                </form>
                <button
                  className="arw-button absolute top-1/2 right-0 transform -translate-y-1/2 bg-[--primary-color] text-white md:py-3 px-3 rounded-full right-1"
                  style={{
                    background:
                      "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
                  }}
                >
                  <span className="min-max-xss:hidden min-max-xs:hidden min-max-sm:hidden min-max-md:hidden">
                    Search
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    className="arrow-icon m-2"
                  >
                    <path
                      d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-right max-md:hidden">
          <div className="relative flex flex-col justify-center px-16 py-16 rounded-full border border-solid border-stone-300 aspect-square max-w-full max-md:px-5">
            {careerPathData.map((data, index) => {
              const angle = (index * 360) / 7 - 50;
              const angleInRadians = (angle * Math.PI) / 180;
              const x = Math.cos(angleInRadians);
              const y = Math.sin(angleInRadians);

              return (
                <>
                  {data.avatar && (
                    <div
                      className="absolute w-12 h-12 z-50 -translate-x-1/2 -translate-y-1/2"
                      style={{
                        top: `calc(50% + ${x} * 50%)`,
                        left: `calc(50% + ${y} * 50%)`,
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 1, scale: 1 }}
                        animate={{
                          opacity: [0, 1, 1, 1, 1, 1, 0, 0],
                          scale: [0, 1, 1, 1, 1, 1, 0, 0],
                        }}
                        transition={{
                          duration: 5,
                          repeatDelay: 30,
                          repeat: Infinity,
                          delay: index * 5,
                        }}
                        className="h-full w-full flex items-center justify-center"
                      >
                        {" "}
                        <img
                          loading="lazy"
                          src={data.avatar}
                          alt="women icon"
                          className="w-full h-full object-cover rounded-full z-50"
                        />
                        <motion.div
                          initial={{ opacity: 1, scale: 1 }}
                          animate={{
                            opacity: [1, 1, 0, 0, 0, 0],
                            scale: [1, 1, 2, 2, 2, 2],
                          }}
                          transition={{
                            duration: 5,
                            repeatDelay: 30,
                            repeat: Infinity,
                            delay: 0.2 + index * 5,
                          }}
                          className="absolute w-12 h-12 rounded-full bg-[#00c798]"
                        ></motion.div>
                        <motion.div
                          initial={{ opacity: 1, scale: 1 }}
                          animate={{
                            opacity: [1, 1, 0, 0, 0, 0],
                            scale: [1, 1, 2, 2, 2, 2],
                          }}
                          transition={{
                            duration: 5,
                            repeatDelay: 30,
                            repeat: Infinity,
                            delay: 0.4 + index * 5,
                          }}
                          className="absolute w-12 h-12 rounded-full bg-[#00c798]"
                        ></motion.div>
                      </motion.div>
                    </div>
                  )}
                  <div
                    className="absolute z-50 -translate-x-1/2 -translate-y-1/2"
                    style={{
                      top: `calc(50% + ${x} * 50%)`,
                      left: `calc(50% + ${y} * 50%)`,
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 1, scale: 1 }}
                      animate={{
                        opacity: [0, 0, 1, 1, 0, 0],
                        scale: [0, 0, 1, 1, 0, 0],
                      }}
                      transition={{
                        duration: 6,
                        repeatDelay: 36,
                        repeat: Infinity,
                        delay: 1 + index * 6,
                      }}
                    >
                      <div
                        className={`relative py-1.5 px-4 w-max shadow-xl bg-white border-2 border-[#00c798] rounded-full flex gap-2 items-center`}
                      >
                        <img
                          loading="lazy"
                          src={data.icon}
                          alt={data.path + " icon"}
                          className={`h-6 aspect-square object-cover`}
                        />
                        <span className="text-base text-[#0e2059]">
                          {data.path}
                        </span>
                        {data.avatar && (
                          <svg
                            className="absolute top-[100%] left-4"
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polygon points="0,0 16,0 0,16" fill="#00c798" />
                          </svg>
                        )}
                        {data.avatar == null && (
                          <motion.div
                            initial={{ opacity: 1, scale: 1 }}
                            animate={{
                              opacity: [0, 0, 1, 1, 0, 0],
                              scale: [0, 0, 1, 1, 0, 0],
                            }}
                            transition={{
                              duration: 3,
                              repeatDelay: 39,
                              repeat: Infinity,
                              delay: 2.5 + index * 6,
                            }}
                            className={`aspect-square bg-[#0e2059] rounded-full py-1 px-2 flex items-center absolute bottom-[80%] -right-2`}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="1x"
                              style={{ color: "#00c798" }}
                            />
                          </motion.div>
                        )}
                      </div>
                    </motion.div>
                  </div>
                </>
              );
            })}
            <div className="small-circle shrink-0 w-full rounded-full border border-solid border-stone-300 aspect-square max-md:mr-0.5">
              <div className="flex flex-col pt-10">
                <img
                  src={happyStudent}
                  alt="happy student"
                  className="absolute md:bottom-[-7.1rem]"
                />
                <div
                  ref={animationCheckPointRef}
                  className="flex flex-col items-start w-full max-md:pl-5 max-md:max-w-full absolute -left-20 top-1/2 z-50"
                >
                  <motion.div
                    className="w-16 h-16 flex items-center justify-center"
                    ref={animationItemRef}
                    style={{ ...animatedItemProps }}
                  >
                    <div className="absolute w-16 h-16 rounded-full overflow-hidden z-10">
                      <img
                        loading="lazy"
                        src={womenIcon}
                        alt="women icon"
                        className="h-full w-full object-cover women-logo"
                      />
                    </div>
                    <motion.div
                      variants={pulseVariant1}
                      animate={isPulse ? "pulse" : "stop"}
                      className="absolute w-16 h-16 rounded-full bg-[#00c798]"
                    ></motion.div>
                    <motion.div
                      variants={pulseVariant2}
                      animate={isPulse ? "pulse" : "stop"}
                      className="absolute w-16 h-16 rounded-full bg-[#00c798]"
                    ></motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
