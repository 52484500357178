import React, { useEffect, useState } from "react";
import MentorBanner from "./components/MentorBanner";
import FeaturedMentors from "./components/FeaturedMentors";
import { MentorMenteeMatch } from "./components/MentorMenteeMatch";
import { FAQSection } from "./components/FAQSection";
import { NoteSection } from "./components/NoteSection";
import { PricingPlans } from "./components/PricingPlans";
import { MentorGrid } from "./components/MentorGrid";
import { SpeakToMentors } from "./components/SpeakToMentors";
import MentorNetwork from "./components/MentorNetwork";
import ExploreMore from "./components/ExploreMore";
import config from "../../util/config";
import Loader from "../../components/Loader/Loader";

export interface Country {
  countryId: number;
  countryName: string;
}

export interface MentorSocialLinks {
  mentorId: number;
  instagram: string;
  twitter: string;
  linkedin: string;
  facebook: string;
  telegram: string;
}

export interface Mentor {
  active: string;
  respondtime: string;
  videoUrl: any;
  id: number;
  name: string;
  role: string;
  experience: string;
  worksOn: string | null;
  imageUrl: string;
  location: string | null;
  country: Country;
  mentorsSocialLinks: MentorSocialLinks;
  languages: string[];
  featured: boolean;
}

export const Mentors: React.FC = () => {
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          config.apiUrl + "/api/auth/mentors/mentorList"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }
        const responseData = await response.json();
        setMentors(responseData.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);

  return (
    <div>
      {!loading ? (
        <div className="mentors-section">
          <MentorBanner mentors={mentors} />
          <FeaturedMentors mentors={mentors} />
          <MentorMenteeMatch />
          <MentorNetwork />
          <SpeakToMentors />
          <PricingPlans />
          <NoteSection />
          <ExploreMore />
          <FAQSection />
          <MentorGrid />
        </div>
      ) : (
        <>
          <div className="container">
            <Loader />
          </div>
        </>
      )}
    </div>
  );
};
