import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "animate.css";
import "swiper/css";
import "swiper/css/bundle";
import { Autoplay, Navigation } from "swiper/modules";
import rightarrow from "../../../images/courses/course-details/rightarrow.svg";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

const TopIndianPrivateCollegesList = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  useEffect(() => {
    if (swiperRef && swiperRef.slides) {
      setTotalSlides(swiperRef.slides.length);
    }
  }, [swiperRef]);

  const handleSlideChange = () => {
    if (swiperRef) {
      setCurrentSlide(swiperRef.realIndex || 0);
      setNavBtnDisabled({
        prev: swiperRef.isBeginning,
        next: swiperRef.isEnd,
      });
    }
  };

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const progressBarElement = (
    <div className="flex gap-4 items-center">
      <span>{currentSlide + 1}</span>
      <div className="w-24 h-1 bg-[#D9D9D9]">
        <div
          className="h-full bg-[#173CBA]"
          style={{
            width: totalSlides
              ? ((currentSlide + 1) / totalSlides) * 100 + "%"
              : "0%",
          }}
        ></div>
      </div>
      <span>{totalSlides}</span>
    </div>
  );

  const prevButton = (
    <button
      className={`${navBtnDisabled.prev ? "" : ""} p-2.5`}
      onClick={handlePrevious}
      onMouseEnter={() => setIsPrevHovered(true)}
      onMouseLeave={() => setIsPrevHovered(false)}
    >
      <LeftArrow />
    </button>
  );

  const nextButton = (
    <button
      className={`${navBtnDisabled.next ? "" : ""} p-2.5`}
      onClick={handleNext}
    >
      <RightArrow isPrevHovered={isPrevHovered} />
    </button>
  );

  const ApplicatonToAdmissionContent = {
    title: "Top Private Colleges & Universities ",
    collegeList: [
      {
        collegeImg:
          "/images/course-details/ManipalAcademyofHigherEducation.png",
        title: "Manipal Academy of Higher Education",
        location: "Karnataka",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/AmritaVishwaVidyapeetham.png",
        title: "Amrita Vishwa Vidyapeetham, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Coimbatore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/Vellore InstituteofTechnology.png",
        title: "Vellore Institute of Technology (VIT), ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Vellore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/SaveethaInstitute.png",
        title: "Saveetha Institute of Medical and Technical Sciences, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Chennai",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/AmritaVishwaVidyapeetham.png",
        title: "Amrita Vishwa Vidyapeetham, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Coimbatore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/Vellore InstituteofTechnology.png",
        title: "Vellore Institute of Technology (VIT), ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Vellore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/SaveethaInstitute.png",
        title: "Saveetha Institute of Medical and Technical Sciences, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Chennai",
        moreLink: "/college",
      },
    ],
  };

  return (
    <div className="flex w-full flex-col ">
      <Swiper
        onSwiper={setSwiperRef}
        onSlideChange={handleSlideChange}
        spaceBetween={30}
        className="w-full"
        modules={[Navigation]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2.5,
          },
          1270: {
            slidesPerView: 4,
          },
        }}
      >
        {ApplicatonToAdmissionContent.collegeList.map(
          (college: any, index: number) => (
            <SwiperSlide
              className="flex flex-col gap-4 bg-[#FFF6F1] rounded-lg"
              style={{
                boxShadow: "0px 2.033px 7.622px 0px rgba(0, 0, 0, 0.10)",
              }}
              key={index}
            >
              <div className="flex flex-col gap-1">
                <img
                  src={college.collegeImg}
                  alt="college"
                  className="w-full h-48 object-cover"
                  style={{ borderRadius: "7px 7px 0px 0px" }}
                />
                <div className="flex flex-col gap-2 py-2 px-2 xl:h-[104px]">
                  <h4 className="text-sm font-semibold text-[#383838] m-0">
                    {college.title}
                    <span className="font-normal">{college.location}</span>
                  </h4>

                  <p className="text-[10px] text-[#383838] m-0 flex items-center gap-2">
                    {college?.program}
                  </p>
                  <p className="text-[10px] text-[#4C495C] flex flex-row items-center gap-[3px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <path
                        d="M7.625 4.00022C7.625 6.00239 6.00201 7.62543 4 7.62543C1.99799 7.62543 0.375 6.00238 0.375 4.00022C0.375 1.99804 1.99799 0.375 4 0.375C6.00201 0.375 7.625 1.99804 7.625 4.00022Z"
                        stroke="#00CC99"
                        stroke-width="0.75"
                      />
                      <path
                        d="M1.81836 4.54552L3.63664 5.45466C4.00029 4.60613 4.98217 2.83633 6.0004 2.54541"
                        stroke="#00CC99"
                        stroke-width="0.75"
                        stroke-linecap="round"
                      />
                    </svg>
                    {college.specialisations}
                  </p>
                </div>
              </div>
              <div className="mx-auto flex flex-row w-[240px] justify-center rounded-md my-2 gap-2 bg-[--primary-color] hover:bg-[--secondary-color] transition-all duration-300 ease-in-out group">
                <LeadGenCtaBtn
                  pageName="application-to-direct-admission-landing"
                  ctaName="book-campus-tour"
                  additionalInfo={college.title}
                  className=" flex  text-[11px] text-white font-semibold rounded-md py-2 px-5 cursor-pointer "
                >
                  <span> Apply Now & Book College Tour</span>
                </LeadGenCtaBtn>
                <img
                  src={rightarrow}
                  alt="rightarrow"
                  className="w-3 h-3 max-xss:w-4 max-xss:h-4 lg:w-8 max-lg:w-6  max-lg:h-6 lg:h-8 group-hover:translate-x-2 transition-transform duration-300 max-lg:my-auto "
                />
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
      <div className="flex justify-center container swiper-buttons relative mx-auto py-7 ">
        {prevButton}
        {progressBarElement}
        {nextButton}
      </div>
    </div>
  );
};

const ApplicatonToAdmission = () => {
  return (
    <div className="bg-[#FFF1FA] w-full relative md:pt-[51px] md:pb-[70px] md:px-20 max-md:py-10">
      <h3 className="pt-0 mb-7 w-full text-4xl font-bold leading-none text-blue-800 max-md:text-center">
        Application to <span className="text-teal-500">Admission</span>
      </h3>
      <div className="flex flex-col  bg-white max-md:mx-[30px]">
        <div className="flex ">
          <h2 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {`Top Private Colleges & Universities `}
          </h2>
        </div>
        <div className="flex container p-9 ">
          <TopIndianPrivateCollegesList />
        </div>
      </div>
    </div>
  );
};

export default ApplicatonToAdmission;

const LeftArrow = () => {
  return (
    <div className="p-4 border border-black hover:border-[#173CBA] rounded-full bg-white hover:bg-[#173CBA] group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-current text-black group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
        />
      </svg>
    </div>
  );
};

const RightArrow = ({ isPrevHovered }: { isPrevHovered: boolean }) => {
  return (
    <div
      className={`p-4 border rounded-full ${
        isPrevHovered ? "bg-white border-black" : "bg-blue border-blue"
      }`}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${
            isPrevHovered
              ? "fill-current text-black"
              : "fill-current text-white"
          }`}
          d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
        />
      </svg>
    </div>
  );
};
