import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./IkigaiMentorSection.scss";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import config from "../../../util/config";
import { convertToKebabCase } from "../../../util/commonUtils";

interface MentorCardProps {
  imageUrl: string;
  name: string;
  role: string;
  experiance: string;
  id: string;
}

interface Mentor {
  imageUrl: string;
  name: string;
  role: string;
  experiance: string;
  id: string;
}

const MentorCard: React.FC<MentorCardProps> = ({
  imageUrl,
  name,
  role,
  experiance,
  id,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="bg-white rounded-lg overflow-hidden shadow-md w-[237px] max-md:w-[247px] max-md:mx-auto relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        loading="lazy"
        src={imageUrl}
        alt={`${name} - ${role}`}
        className="w-full h-52 object-cover"
      />
      <div className="bg-[#173CBA] text-white text-center p-4 relative">
        <h3 className="text-lg font-semibold">{name}</h3>
        <p className="text-sm">{role}</p>
      </div>

      <div
        className={`bg-[#FFF9E5] shadow-lg text-center absolute top-0 left-0 w-full h-full p-2 flex flex-col items-center justify-between transition-opacity duration-300 ease-in-out ${isHovered ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
      >
        <div className="flex flex-col gap-3 justify-between">
          <div className="flex flex-col gap-2">
            <h4 className="font-bold text-xl text-black">{name}</h4>
            <p className="text-sm text-black">{role}</p>
          </div>
          <p className="text-base text-black line-clamp-1">{experiance}</p>
          <p className="mt-2 text-base text-black">
            "My passion lies in guiding others to reach their career potential
            and find fulfillment."
          </p>
        </div>
        <div className="flex gap-1 items-center justify-center">
          <a
            href={`/mentor/${convertToKebabCase(name)}-${id}`}
            className="bg-blue text-sm button-styles hover:bg-blue text-white flex gap-1 items-center px-3 py-1.5 rounded-full know-more"
          >
            Connect to Mentor
          </a>
        </div>
      </div>
    </div>
  );
};

export const IkigaiMentorSection: React.FC = () => {
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${config.apiUrl}/api/auth/mentors/mentorList`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }

        const responseData = await response.json();
        setMentors(responseData.data || []);
      } catch (error: any) {
        console.error("Error fetching mentors:", error);
        setError(error.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading mentors...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col items-center px-20 pt-10 pb-16 w-full bg-[#FFF1FA] max-md:px-5 max-md:w-full">
      <div className="flex flex-col w-full max-md:w-full">
        <h3 className="md:self-start md:ml-20 text-4xl font-light text-[#173CBA] md:leading-[55px] max-md:max-w-full">
          We will get you Admitted into <br /> Top Class Universities Worldwide
        </h3>

        <div className="ikigai-mentor-section xl:w-[1250px] min-max-lg:w-full mx-auto md:w-full flex items-center mt-8 px-10 relative max-md:w-full">
          <div className="swiper-button-prev md:ml-[-45px]"></div>
          <div className="swiper-button-next md:mr-[-25px]"></div>
          <Swiper
            modules={[Navigation, Autoplay]}
            loop={true}
            spaceBetween={20}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            breakpoints={{
              320: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
              1280: { slidesPerView: 4 },
            }}
            className="mt-8"
          >
            {mentors.map((mentor, index) => (
              <SwiperSlide key={index}>
                <MentorCard {...mentor} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default IkigaiMentorSection;
