import React from "react";

const GetAdmissionAdvise = () => {
  return (
    <div className="w-full bg-[#FFF9E5] mb-7 py-11">
      <div className="container flex  justify-between flex-wrap max-md:flex-col max-md:gap-8">
        <div className="md:w-[217px] max-md:w-[172px] max-md:mx-auto">
          <p className="text-black text-lg font-normal max-md:text-base">
            Get Admission Advice from a &nbsp;
            <strong className="font-semibold">College Mentor</strong>
          </p>
        </div>
        <div className="md:w-[196px] max-md:w-[187px] max-md:mx-auto flex  md:gap-3 max-md:gap-2">
          <img
            src="/images/get-admission02.jpg"
            className="w-[35px] h-[52px]"
            alt="getAdmissionIcon"
          />
          <p className="text-black text-base  font-normal ">
            Achieved a <br />
            <span className="text-lg max-md:text-base font-bold">90% </span> +
            success rate.
          </p>
        </div>
        <div className="md:w-[182px] gap-3 flex  max-md:mx-auto">
          <img
            src="/images/get-admission01.jpg"
            className="w-[72px] h-[72px]"
            alt="getAdmissionIcon"
          />
          <p className="text-black text-sm font-normal">
            <span className="font-bold text-lg">Over 100 </span> <br />
            Experienced <br />
            Mentors
          </p>
        </div>
        <div className="md:w-[273px] gap-3 flex max-md:hidden ">
          <img
            src="/images/get-admission03.jpg"
            className="w-[124px] h-[42px] my-2"
            alt="getAdmissionIcon"
          />
          <p className="text-black text-sm font-normal">
            <span className="text-lg font-bold">95% Positive</span> Customer
            Reviews
          </p>
        </div>
        <div className="md:w-[273px] gap-3 flex max-md:w-[218px]  max-md:mx-auto">
          <img
            src="/images/get-admission04.jpg"
            className="w-[42px] h-[58px] my-auto"
            alt="getAdmissionIcon"
          />
          <p className="text-black text-sm font-normal">
            <span className="text-lg max-md:text-base font-bold">
              College Mentor
            </span>
            <br /> Assurance for Top university offers, India and Abroad.
          </p>
        </div>
        <div className="mx-auto w-[273px] gap-3 flex  md:hidden ">
          <img
            src="/images/get-admission03.jpg"
            className="w-[124px] h-[42px] my-2"
            alt="getAdmissionIcon"
          />
          <p className="text-black text-sm font-normal">
            <span className="text-lg font-bold">95% Positive</span> Customer
            Reviews
          </p>
        </div>
      </div>
    </div>
  );
};

export default GetAdmissionAdvise;
