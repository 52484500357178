import React, { useState } from "react";
import { Link } from "react-router-dom";

const WhatWeOffer: React.FC = () => {
  const [selectedCategory, setSelectedCategory] =
    useState<string>("For Students");

  const categories = [
    {
      label: "For Students",
      content: (
        <>
          <p className="w-full">
            <span className="font-semibold">
              Step Confidently into your Dream College
            </span>{" "}
            <br />
            From Application to Admission Acceptance, College Mentor is your
            guide through every step.
          </p>
          <p className="mt-4 ">
            We provide personalized support and hand-hold you through the entire
            college journey until you receive your dream offer letter from the
            top-ranked universities.
          </p>
          <p className="mt-4 ">
            College Mentor's guiding principles promote access, equity, and
            integrity in the college admissions process for all students.
          </p>
        </>
      ),
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/whatweoffer-for-student.jpg",
    },
    {
      label: "For Parents",
      content: (
        <>
          <p className="w-full">
            <span className="font-semibold">
              Taking the worry out of your college admissions
            </span>
            <br />
            For parents, College Mentor offers invaluable assistance in choosing
            colleges, courses, scholarships, and placements.
          </p>
          <p className="mt-4 ">
            Our experienced mentors provide end-to-end support with up-to-date
            information, ensuring a smooth and informed college journey.
          </p>
          <p className="mt-4 ">
            Promoting access, equity, and integrity in the college admissions
            process for all parents & students.
          </p>
        </>
      ),
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/whatweoffer-for-parent.jpg",
    },
    {
      label: "For Institutions",
      content: (
        <>
          <p className="w-full">
            <span className="font-semibold">
              Connect with users who are actively researching their educational
              Partner{" "}
            </span>
            with College Mentor to enhance your student recruitment and pairing
            process.
          </p>
          <p className="mt-4 ">
            Join forces with our expert college counseling services to influence
            students' higher education pathways and start a mutually beneficial
            partnership.
          </p>
          <p className="mt-4 ">
            Promoting access, equity, and integrity in the college admissions
            process for all students, parents, and institutions
          </p>
        </>
      ),
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/what-we-offer-for-institusions.jpg",
    },
    {
      label: "For Student Buddy Mentors",
      content: (
        <>
          <p className="w-full">
            <span className="font-semibold">
              Working with students across the globe and every field of study
            </span>
            <br />
            We empower international students to leverage their study abroad
            experiences to assist others in enrolling at universities globally -
            while getting paid!
          </p>
          <p className="mt-4 ">
            Students can register as College Mentor Student Buddies, create
            their profiles, and start their journey with us. The more they
            support others, the more points they earn, which can be converted
            into cash directly deposited into their bank accounts.
          </p>
        </>
      ),
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/what-we-offer-for-studentbuddymentor.jpg",
    },
  ];

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <div className="flex flex-col items-start xl:px-20 py-16 w-full bg-[#F4F6F8] max-md:px-5 max-md:max-w-full mb-6 min-max-md:px-8 min-max-lg:px-8">
      <div className="md:w-[630px]">
        <h2 className="text-4xl font-bold leading-none text-[#0c9]">
          What We Offer
        </h2>
        <p className="mt-5 text-base leading-7 text-black  max-md:max-w-full">
          College Mentor is creating a dynamic online community for higher
          education,{" "}
          <span className="font-semibold">
            Connecting Students, Parents, and Institutions
          </span>
        </p>
      </div>
      <div className="self-stretch mt-5 max-md:max-w-full">
        <div className="flex gap-8 max-md:flex-col-reverse">
          <div className="relative flex flex-row md:w-[66%] max-md:ml-0 max-md:w-full items-center min-max-md:w-[60%] min-max-lg:w-[60%]  ">
            <div className="bg-[#173CBA] max-md:w-[198px] max-md:text-[12px] max-md:h-[137px] text-white py-4 max-md:py-2 max-md:my-auto rounded-md md:w-[305px] md:h-[211px] my-auto relative z-10 -right-[70px] max-lg:-right-[20px]">
              <ul className="list-none space-y-3 max-md:space-y-1">
                {categories.map((category) => (
                  <li
                    key={category.label}
                    className="hover:text-[#0C9] transition-colors"
                  >
                    <button
                      onClick={() => handleCategoryClick(category.label)}
                      className={`w-full text-left focus:outline-none pl-4 ${
                        selectedCategory === category.label
                          ? "font-semibold"
                          : ""
                      }`}
                    >
                      {category.label}
                    </button>
                    <div className="border-b border-white mt-2 max-md:mt-[6px] w-[97px]"></div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="md:w-[562px] md:h-[324px] overflow-hidden max-md:w-[249px]">
              <img
                src={
                  categories.find(
                    (category) => category.label === selectedCategory
                  )?.image
                }
                alt="Whatweoffer"
                className="md:w-[562px] md:h-[324px] max-md:h-[214px] rounded-md "
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full min-max-md:w-[40%] min-max-lg:w-[40%]">
            <div className="flex flex-col w-full max-md:mt-10">
              <div className="flex flex-col text-base leading-7 text-black">
                {
                  categories.find(
                    (category) => category.label === selectedCategory
                  )?.content
                }
              </div>
              <Link
                to={"/mentors"}
                className="flex gap-0.5 w-[315px] min-max-md:w-full button-styles arrow-button justify-center items-center self-start  mt-5 bg-[#0C9] rounded min-h-[32px] arrow-btn hover:bg-[#0c9] text-white"
                aria-label="Connect to Admission mentor"
              >
                <span className="self-stretch  text-base font-semibold min-max-md:font-light min-max-md:text-[10px] ">
                  Connect to Admission mentor
                </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
