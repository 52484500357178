import React from "react";

interface ImportantDatesSectionProps {
  title: string;
  description: string;
}

const ImportantDatesSection: React.FC<ImportantDatesSectionProps> = ({
  title,
  description,
}) => {
  return (
    <div className="section-container py-5 bg-[#F4F4F4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="flex flex-col gap-5">
            <h2 className="text-3xl font-normal text-blue">{title}</h2>
            <div
              className="text-black text-base examDetailsTabletwo flex flex-col gap-4"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportantDatesSection;
