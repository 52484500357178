import React from "react";

interface VideoSectionProps {
  title: string;
  videos?: Array<{ url: string; title: string }>;
}

const VideoSection: React.FC<VideoSectionProps> = ({ title, videos }) => {
  return (
    <div className="video-section py-6">
      <h2 className="text-3xl font-semibold text-[#113cc0] text-center">
        {title}
      </h2>
      {videos && videos.length > 0 ? (
        <div className="video-gallery mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {videos.map((video, index) => (
            <div key={index} className="video-item">
              <iframe
                src={video.url}
                title={video.title}
                className="w-full h-48 rounded-lg"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p className="text-center mt-2">{video.title}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-lg mt-4">No videos available.</p>
      )}
    </div>
  );
};

export default VideoSection;
