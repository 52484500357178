import { FC, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { postCtaData } from "./leadGenThunk";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  ctaName: string;
  pageName: string;
  courseName?: string;
  additionalInfo?: string;
  children: ReactNode;
  className?: string;
}

const LeadGenCtaBtn: FC<IProps> = ({
  ctaName,
  pageName,
  courseName,
  additionalInfo,
  children,
  className,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const leadPageName = pageName;
  const leadOn = `${ctaName}${
    additionalInfo && additionalInfo.length > 0 ? "|" + additionalInfo : ""
  }`;

  const handleClick = () => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/secure/login", {
        state: { sourcePagePath: pathname, leadPageName, leadOn },
      });
    } else {
      dispatch(
        postCtaData({
          leadPageName,
          leadOn: `${leadOn}${courseName ? `|${courseName}` : ""}`,
        })
      );
    }
  };
  return (
    <button onClick={handleClick} className={className || ""}>
      {children}
    </button>
  );
};

export default LeadGenCtaBtn;
