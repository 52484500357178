import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import TestimonialCard from "../../components/TestimonialCard";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import { Key } from "react";

const fallbackData = [
  {
    review:
      "The college rank predictor gave me a clear idea of where I stand, helping me make informed choices. It boosted my confidence and made the counseling process much smoother.",
    reviewBy: "Kavya",
    imageUrl:
      "https://img.freepik.com/free-photo/brunette-lady-stays-city-front-big-buildings_231208-2832.jpg",
  },
  {
    review:
      "Using the college seat matrix tool helped me understand which colleges had available seats in my preferred programs. It was a game-changer for making strategic decisions.",
    reviewBy: "Swetha",
    imageUrl:
      "https://img.freepik.com/free-photo/brunette-lady-stays-city-front-big-buildings_231208-2832.jpg",
  },
  {
    review:
      "Before my counseling, the predictors gave me a solid understanding of my likely college options and ranks. This clarity was invaluable and greatly increased my confidence in the process.",
    reviewBy: "Afreen",
    imageUrl:
      "https://img.freepik.com/free-photo/brunette-lady-stays-city-front-big-buildings_231208-2832.jpg",
  },
  {
    review:
      "The predictor for college choices was incredibly useful. It narrowed down my options based on my preferences and scores, making me feel confident about my decisions before counseling.",
    reviewBy: "Krishna",
    imageUrl:
      "https://img.freepik.com/free-photo/brunette-lady-stays-city-front-big-buildings_231208-2832.jpg",
  },
];

const Testimonial = () => {
  const studentReviewsData = useSelector(
    (state: RootState) =>
      state.collegePredictorIntermediate.landingData?.studentReviews
  );

  const testimonials =
    studentReviewsData && studentReviewsData.length > 0
      ? studentReviewsData
      : fallbackData;

  const testimonialsContent = testimonials.map(
    (
      data: { review: string; reviewBy: string; imageUrl: string },
      index: number
    ) => <TestimonialCard key={index} {...data} />
  );

  return (
    <section className="py-12 bg-[#FFF6F1] text-black">
      <div className="container">
        <h3 className="text-4xl lg:text-5xl font-bold">
          Students Love College Mentor Predictor Tools
        </h3>
        <CustomSwiper
          className="!px-2"
          items={testimonialsContent}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
            1280: { slidesPerView: 4, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      </div>
    </section>
  );
};

export default Testimonial;
