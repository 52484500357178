import React from "react";
import JobsProfiles from "./jobs/JobsProfiles";
import AverageSalary from "./jobs/AverageSalary";
import { Link } from "react-router-dom";

const CourseJobs: React.FC = () => {
  return (
    <div>
      <div className="bg-[#ECECEC] py-6">
        <div className="container ">
          <div className="flex lg:flex-row flex-col ">
            <div className="w-full lg:w-9/12 flex flex-col">
              <JobsProfiles />
              <AverageSalary />
            </div>
            <div className="w-full lg:w-3/12 hidden md:block">
              <div className="bg-[#FFF1FA] w-full h-full flex flex-col justify-start items-center py-10 lg:pt-28">
                <div className="py-4 bg-white">
                  <img
                    src="/images/course-details/TopMedical Courses_071.png"
                    alt="TopMedicalCourses"
                  />
                  <Link
                    to={"/blogdetails-page"}
                    className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group w-[125px]"
                  >
                    <span className="mr-2">Read more</span>
                    <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>

                <div className="py-4 mt-4 bg-white">
                  <div className="relative">
                    <img
                      src="/images/course-details/medical-course.png"
                      alt="medical-course"
                    />
                    <div
                      className="w-[189px] h-[75px] bg-[#fff] absolute bottom-0 right-0"
                      style={{ borderRadius: "25px 0px 0px 0px" }}
                    >
                      <h3 className="text-[#383838] text-base font-normal p-3">
                        Top Medical Courses Without <strong> NEET</strong>
                      </h3>
                    </div>
                  </div>
                  <Link
                    to={"/blogdetails-page"}
                    className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group w-[125px] mt-2"
                  >
                    <span className="mr-2">Read more</span>
                    <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseJobs;
