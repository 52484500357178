import React, { useEffect } from "react";

interface SocialMediaFeedsProps {
  overview: {
    title: string;
    array: Array<{
      title: string;
      description: string;
    }>;
  };
}

const SocialMediaFeeds: React.FC<SocialMediaFeedsProps> = ({ overview }) => {
  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }

    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, [overview]);

  if (!overview?.array || overview.array.length === 0) {
    return <div>No social media links available.</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold text-center mb-6">
        {overview.title || "Social Media Feeds"}
      </h2>
      <div
        className={`flex flex-col lg:grid gap-4 ${
          overview.array.length === 1
            ? "grid-cols-1"
            : overview.array.length === 2
            ? "grid-cols-2"
            : overview.array.length === 3
            ? "grid-cols-3"
            : "grid-cols-4"
        }`}
      >
        {overview.array.map((item, index) => {
          const { title, description } = item;

          if (title === "facebook") {
            return (
              <div
                key={`facebook-${index}`}
                className="max-h-80 overflow-hidden"
              >
                <div id="fb-root"></div>
                <div
                  className="fb-page"
                  data-href={description}
                  data-tabs="timeline"
                  data-width="300"
                  data-height="400"
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true"
                ></div>
              </div>
            );
          }

          if (title === "twitter") {
            return (
              <div
                key={`twitter-${index}`}
                className="max-h-80 overflow-hidden"
              >
                {" "}
                <a
                  className="twitter-timeline"
                  href={description}
                  data-tweet-limit="5"
                  data-width="500"
                  data-height="600"
                >
                  Tweets by {description.split("/")[3]}
                </a>
              </div>
            );
          }

          if (title === "youtube") {
            const channelId = description.split("/").pop();

            return (
              <div
                key={`youtube-${index}`}
                className="max-h-80 overflow-hidden"
              >
                <iframe
                  width="300"
                  height="200"
                  src={`https://www.youtube.com/embed?listType=playlist&list=${channelId}`}
                  title="YouTube channel feed"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            );
          }

          if (title === "linkedin") {
            return (
              <div
                key={`linkedin-${index}`}
                className="max-h-80 overflow-hidden"
              >
                <p>
                  LinkedIn currently does not support dynamic embedding. Visit
                  our{" "}
                  <a
                    href={description}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    LinkedIn page
                  </a>
                  .
                </p>
              </div>
            );
          }

          return null; // Handle unsupported titles
        })}
      </div>
    </div>
  );
};

export default SocialMediaFeeds;
