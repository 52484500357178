import React from "react";
interface BenefitItem {
  title: string;
  description: string;
}

const ScholarshipDisbursement = () => {
  const benefits: BenefitItem[] = [
    {
      title: "Tuition fees",
      description: "will be disbursed directly to institutions.",
    },
    {
      title: "Cash prizes",
      description: "will be transferred to the winners' bank accounts.",
    },
    {
      title: "Enjoy a ₹5,000 to ₹10,000 scholarship",
      description:
        "applied at the time of admission to partner universities, exclusively through the College Mentor admission process. Scholarship scores will be prioritized in the admission process, with a list of participating universities provided upon results.",
    },
  ];

  const BenefitList: React.FC<{ benefits: BenefitItem[] }> = ({ benefits }) => {
    return (
      <ul>
        {benefits.map((benefit, index) => (
          <li key={index}>
            <span className="font-semibold">{benefit.title}</span>
            {` ${benefit.description}`}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div className="px-20 py-10 w-full bg-[#EFF] max-lg:px-7 max-lg:w-full my-6">
      <div className="flex gap-5 max-lg:flex-col-reverse">
        <div className="flex flex-col w-1/2 max-lg:ml-0 max-lg:w-full">
          <img
            src="https://cdncollegementor.blob.core.windows.net/cm-scholarship/scholorship-disbusrement.jpg"
            alt="person holding hands towards trophy"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-lg:mt-10 max-lg:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 w-1/2 max-lg:ml-0 max-lg:w-full my-auto leading-[30px] lg:p-4 max-lg:mx-auto">
          <h3 className="text-[#173CBA] text-[48px] font-light max-lg:text-[24px] leading-[48px]">
            Scholarship Disbursement
          </h3>
          <div>
            <p className="p-2">
              Join the exclusive World Education Tour to Europe! Experience rich
              cultures, gain invaluable insights, and expand your horizons.
              Don't miss this chance to elevate your education.
            </p>
            <div className="mt-5 pl-7">
              <BenefitList benefits={benefits} />
              <span>
                <strong>Note :</strong> All participants in the Scholarship Test
                will receive a Digital Certificate acknowledging their
                participation.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipDisbursement;
