import React from "react";
import CutoffTable2024 from "../cutoffs/CutoffTable2024";
import CutoffTable2023 from "../cutoffs/CutoffTable2023";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface CutoffSectionProps {
  title: string;
  description: string;
  orderIndex?: string;
}

interface CollegeProps {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface CutoffTabProps {
  cutoffData: {
    [key: string]: CutoffSectionProps | CollegeProps[] | undefined;
  };
}

const CutoffSection: React.FC<CutoffSectionProps> = ({
  title,
  description,
}) => {
  return (
    <section className="cutoff-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const CutoffTab: React.FC<CutoffTabProps> = ({ cutoffData }) => {
  const similarColleges = cutoffData.similarColleges as CollegeProps[];
  const keys = Object.keys(cutoffData)
    .filter((key) => key.startsWith("key_") && cutoffData[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (cutoffData[a] as CutoffSectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (cutoffData[b] as CutoffSectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            {keys.map((key) => {
              const section = cutoffData[key] as CutoffSectionProps;

              if (!section?.title || !section?.description) return null;

              if (key === "key_0") {
                return (
                  <CutoffTable2024
                    key={key}
                    title={section.title}
                    description={section.description}
                  />
                );
              }

              if (key === "key_1") {
                return (
                  <CutoffTable2023
                    key={key}
                    title={section.title}
                    description={section.description}
                  />
                );
              }

              return (
                <CutoffSection
                  key={key}
                  title={section.title}
                  description={section.description}
                />
              );
            })}
          </div>
          <div className="w-full lg:w-1/4">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </div>
  );
};

export default CutoffTab;
