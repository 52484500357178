import React from "react";
import DidYouKnow from "./DidYouKnow";

// Define the type for the slide data
interface SlideData {
  mainText: string;
  subText?: string;
  subItems?: string[]; // Corrected type to be an array of strings
}

// Define the data as an array
const didYouKnowData: SlideData[] = [
  {
    mainText:
      "On average, students tend to apply to five engineering entrance exams",
    subText:
      "JEE Main, JEE Advanced, BITS Pilani, VIT, Domicile State Entrance Exam",
  },
  {
    mainText:
      "In 2024, approximately 14.15 lakh students appeared for the JEE Main exam",
    subText:
      "There are only 17,000 seats across the 23 Indian Institutes of Technology (IITs), with 83 candidates vying for each IIT seat.",
  },
  {
    mainText: "NEET 2024, there were around 2.4 million registrations",
    subText:
      "There are only about 92,250 MBBS seats, with 23 candidates per seat.",
  },
  {
    mainText: "Institutions of Eminence (IoE) Scheme",
    subText:
      "Only 8 government universities and 4 private universities have been granted the status, enabling them to develop into world-class teaching and research institutions.",
  },
  {
    mainText:
      "Institutes of National Importance (INIs): 159, Including IITs, IIMs, NITs, and AIIMS",
    subItems: [
      "Central Universities - 56",
      "State Universities - 479",
      "Deemed-to-be Universities - 125",
      "Open Universities - 14",
      "Fake Universities - 21",
      "Affiliated Colleges - 52,627",
    ],
  },
];

function DidYouKnowData() {
  return (
    <div className="App">
      <DidYouKnow slidesData={didYouKnowData} />
    </div>
  );
}

export default DidYouKnowData;
