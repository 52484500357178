import ApplySmarter from "./components/ApplySmarter";
import BuildingBridges from "./components/BuildingBridges";
import FeaturedColleges from "./components/FeaturedColleges";
import CollegeBanner from "./components/CollegeBanner";
import StateWise from "./components/StateWise";
import { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import About from "./components/About";
import ChooseCollegeMentor from "./components/ChooseCollegeMentor";
import WorldClassUni from "./components/WorldClassUni";
import Preference from "./components/Preference";
import Dreams from "./components/Dreams";
import FindPerfectCollege from "./components/FindPerfectCollege";
import axios from "axios";
import collegeLanding, { update } from "../../../store/slices/collegeLanding";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import config from "../../../util/config";
import WebinarAndArticles from "./components/WebinarAndArticles";
import collegeLandingMock from "../../exams/mock/mockData";

const CollegeLanding = () => {
  // Extract data from the mock
  const {
    featuredColleges,
    statewiseColleges,
    worldClass,
    dreamsHappen,
    cardsDreams,
  } = collegeLandingMock;
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios({
  //         url: config.apiUrl + "/api/college/landing-page",
  //         method: "GET",
  //       });
  //       dispatch(update(response.data?.data));
  //     } catch (e) {
  //       console.log("error fetching college landing data");
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <div className="font-inter">
      <CollegeBanner />
      <FeaturedColleges
        title={"Featured Colleges"}
        featuredColleges={featuredColleges}
      />
      <BuildingBridges />
      <FindPerfectCollege />
      <ApplySmarter />
      <Preference />
      <ChooseCollegeMentor />
      <Dreams dreamsHappen={dreamsHappen} cardsDreams={cardsDreams} />
      <WorldClassUni worldClass={worldClass} />
      <StateWise stateWiseColleges={statewiseColleges} />
      <WebinarAndArticles />
      <About />
    </div>
  );
};

export default CollegeLanding;
