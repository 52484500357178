import { FC, useState } from "react";
import AnimatedTabSlider from "../../../../components/AnimatedTabSlider";
import collegeIcon from "../images/find-perfect-college/college-icon.svg";
import examIcon from "../images/find-perfect-college/exam-icon.svg";
import courseIcon from "../images/find-perfect-college/course-icon.svg";
import rankingIcon from "../images/find-perfect-college/ranking-icon.svg";
import leftArrowIcon from "../images/find-perfect-college/left-arrow.svg";
import rightArrowIcon from "../images/find-perfect-college/right-arrow.svg";
import SearchBarWithSuggestion from "./SearchBarWithSuggestion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import TabSelectorV1 from "../../../../components/TabSelectorV1";

const cardsList: {
  title: (stream: string) => string;
  key: "college" | "specialization" | "exam" | "resource";
  colorDark: string;
  colorLight: string;
  icon: string;
}[] = [
  {
    title: (stream: string) => `Top ${stream} Colleges`,
    key: "college",
    colorDark: "#F49FB6",
    colorLight: "#FFEFFF",
    icon: collegeIcon,
  },
  {
    title: (stream: string) => `${stream} Exams`,
    key: "exam",
    colorDark: "#F4D2D2",
    colorLight: "#FFFAFA",
    icon: rankingIcon,
  },
  {
    title: (stream: string) => `${stream} Specializations`,
    key: "specialization",
    colorDark: "#A6E0DE",
    colorLight: "#ECFFFF",
    icon: examIcon,
  },
  {
    title: (stream: string) => `${stream} Resources`,
    key: "resource",
    colorDark: "#FFE5D9",
    colorLight: "#FFFFFF",
    icon: courseIcon,
  },
];

const indianColleges = [
  "Indian Institute of Technology Bombay",
  "Indian Institute of Technology Delhi",
  "Indian Institute of Technology Madras",
  "Indian Institute of Technology Kanpur",
  "Indian Institute of Technology Kharagpur",
  "Indian Institute of Science",
  "Indian Institute of Technology Roorkee",
  "Indian Institute of Technology Guwahati",
  "National Institute of Technology Tiruchirappalli",
  "Birla Institute of Technology and Science, Pilani",
  "All India Institute of Medical Sciences, New Delhi",
  "Jawaharlal Nehru University",
  "Banaras Hindu University",
  "Anna University",
  "University of Delhi",
  "National Institute of Technology Surathkal",
  "Manipal Academy of Higher Education",
  "Vellore Institute of Technology",
  "Savitribai Phule Pune University",
  "Amrita Vishwa Vidyapeetham",
];

const data = [
  {
    data: {
      exam: [
        { name: "JEE MAIN" },
        { name: "JEE ADVANCED" },
        { name: "BITS -PILANI" },
        { name: "VITEEE" },
        { name: "AMEEE" },
        { name: "SRM – JEE" },
      ],
      specialization: [
        { name: "Artificial Intelligence Engineer" },
        { name: "Cybersecurity Specialist" },
        { name: "Data Scientist" },
        { name: "Robotics and Automation" },
        { name: "space technology" },
        { name: "Environmental Engineer" },
      ],
      college: [
        { name: "IIT Madras" },
        { name: "IIT Delhi" },
        { name: "IIT Bombay" },
        { name: "IIT Kanpur" },
        { name: "IIT Kharagpur" },
        { name: "IIT Roorkee" },
      ],
      resource: [
        { name: "Application to Admission", link: "/applications-admissions" },
        { name: "Engineering Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "Engineering Predictor" },
        { name: "Engineering Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Engineering",
  },
  {
    data: {
      exam: [
        { name: "CAT" },
        { name: "XAT" },
        { name: "CMAT" },
        { name: "MAT" },
        { name: "GMAT" },
        { name: "IPMAT" },
        { name: "CUET" },
      ],
      specialization: [
        { name: "Finance" },
        { name: "Marketing" },
        { name: "Human Resource Management (HRM)" },
        { name: "Operations and Supply Chain Management" },
        { name: "International Business" },
        { name: "Healthcare Management" },
        { name: "Retail and E-Commerce Management" },
      ],
      college: [
        { name: "IIM Ahmedabad" },
        { name: "IIM Bangalore" },
        { name: "IIM Kozhikode" },
        { name: "IIT Delhi" },
        { name: "IIM Calcutta" },
        { name: "IIM Mumbai" },
      ],
      resource: [
        { name: "Application to Admission", link: "/applications-admissions" },
        { name: "Management Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "Management Predictor" },
        { name: "Management Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Management",
  },
  {
    data: {
      exam: [
        { name: "NEET - UG" },
        { name: "NEET -PG" },
        {
          name: "INI-CET (Institute of National Importance Combined Entrance Test)",
        },
        { name: "FMGE (Foreign Medical Graduate Examination)" },
      ],
      specialization: [
        { name: "General Medicine (MD)" },
        { name: "General Surgery (MS)" },
        { name: "Pediatrics (MD)" },
        { name: "Dermatology (MD/DDVL)" },
        { name: "Obstetrics and Gynecology (MD/MS)" },
        { name: "Orthopedics (MS/DNB)" },
        { name: "Ophthalmology (MD/MS)" },
      ],
      college: [
        { name: "All India Institute of Medical Sciences Delhi" },
        { name: "Post Graduate Institute of Medical Education and Research" },
        { name: "Christian Medical College" },
        {
          name: "National Institute of Mental Health and Neuro Sciences, Bangalore",
        },
        {
          name: "Jawaharlal Institute of Post Graduate Medical Education and Research",
        },
        { name: "Sanjay Gandhi Postgraduate Institute of Medical Sciences" },
      ],
      resource: [
        { name: "Medical Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "NEET Predictor" },
        { name: "Medical Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Medical",
  },
  {
    data: {
      exam: [
        { name: "CLAT" },
        { name: "AILET" },
        { name: "LSAT India" },
        { name: "SLAT" },
        { name: "MHCET Law" },
        { name: "AP LAWCET" },
      ],
      specialization: [
        { name: "Corporate Law" },
        { name: "Criminal Law" },
        { name: "Intellectual Property Rights (IPR)" },
        { name: "International Law" },
        { name: "Family and Personal Law" },
        { name: "Cyber Law and Technology Law" },
      ],
      college: [
        { name: "National Law School of India University" },
        { name: "National Law University" },
        { name: "Nalsar University of Law" },
        { name: "The West Bengal National University of Juridical Sciences" },
        { name: "Symbiosis Law School" },
        { name: "Jamia Millia Islamia" },
      ],
      resource: [
        { name: "Application to Admission", link: "/applications-admissions" },
        { name: "Law Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "Law Predictor" },
        { name: "Law Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Law",
  },
  {
    data: {
      exam: [
        { name: "NATA" },
        { name: "JEE Main Paper 2 (Architecture & Planning)" },
        { name: "CEED" },
      ],
      specialization: [
        { name: "Urban Design" },
        { name: "Landscape Architecture" },
        { name: "Interior Architecture" },
        { name: "Architectural Lighting Design" },
        { name: "Furniture Design and Manufacturing" },
        { name: "Smart Cities and Infrastructure Development" },
      ],
      college: [
        { name: "IIT Roorkee" },
        { name: "IIT Kharagpur" },
        { name: "NIT Calicut" },
        {
          name: "Indian Institute of Engineering Science and Technology, Shibpur",
        },
        { name: "School of Planning and Architecture New Delhi" },
        { name: "Centre for Environmental Planning and Technology University" },
      ],
      resource: [
        { name: "Application to Admission", link: "/applications-admissions" },
        { name: "Architecture Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "NATA Predictor" },
        { name: "Architecture Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Architecture & Planning",
  },
  {
    data: {
      exam: [
        { name: "ICAR AIEEA" },
        { name: "AIEEA UG & PG" },
        { name: "CUET" },
        { name: "JNU CEEB" },
        { name: "EAMCET" },
      ],
      specialization: [
        { name: "Agricultural Engineering" },
        { name: "Horticulture" },
        { name: "Agronomy" },
        { name: "Animal Husbandry" },
        { name: "Forestry and Environmental Science" },
        { name: "Food Science and Technology" },
      ],
      college: [
        { name: "Indian Agricultural Research Institute" },
        { name: "ICAR - National Dairy Research Institute" },
        { name: "Punjab Agricultural University" },
        { name: "Banaras Hindu University" },
        { name: "Indian Veterinary Research Institute, Izatnagar" },
        { name: "Tamil Nadu Agricultural University" },
      ],
      resource: [
        { name: "Application to Admission", link: "/applications-admissions" },
        { name: "Agriculture Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "Agri Predictor" },
        { name: "Agriculture Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Agriculture",
  },
  {
    data: {
      exam: [
        { name: "CUCET (Central Universities Common Entrance Test)" },
        { name: "Flame University Aptitude Test (FEAT)" },
        { name: "Shiv Nadar University Entrance Exam (SNUEE)" },
        { name: "Ashoka Aptitude Test (AAT)" },
        { name: "Krea Immersive Case (KIC)" },
        { name: "Azim Premji University (AET)" },
        { name: "Tata Institute of Social Sciences (TISS)" },
      ],
      specialization: [
        { name: "Public Policy" },
        { name: "Clinical Psychologist" },
        { name: "Social Worker" },
        { name: "Political Analyst" },
        { name: "Graphic Designer" },
        { name: "Art Director" },
        { name: "Journalist" },
      ],
      college: [
        { name: "Ashoka University" },
        { name: "Flame University, Pune" },
        { name: "Tata Institute of Social Sciences (TISS)" },
        { name: "OP Jindal Global University" },
        { name: "Azim Premji University" },
        { name: "Krea University" },
        { name: "Lady Shri Ram College for Women" },
      ],
      resource: [
        { name: "Application to Admission", link: "/applications-admissions" },
        { name: "Liberal arts Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "Liberal Arts Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Liberal Arts",
  },
  {
    data: {
      exam: [
        { name: "IHM Aurangabad Exam" },
        { name: "NCHM JEE" },
        { name: "MAH MHMCT CET" },
        { name: "MET -2025" },
        { name: "Oberoi STEP" },
        { name: "NCHM MSc JEE" },
        { name: "CUCET -2025" },
        { name: "Ecole Hoteliere Lavasa" },
      ],
      specialization: [
        { name: "Food Safety and Hygiene" },
        { name: "Event Planning and Management" },
        { name: "Luxury Hospitality Management" },
        { name: "Food and Beverage Management" },
        { name: "Front Office Management" },
        { name: "Culinary Arts" },
        { name: "Health and Wellness Tourism" },
      ],
      college: [
        { name: "Institute of Hotel Management (IHM), Delhi" },
        { name: "Oberoi Centre of Learning and Development, Delhi" },
        { name: "Les Roches Global Hospitality Education, Pune" },
        { name: "Welcomegroup Graduate School (WGSHA), Manipal" },
        { name: "Culinary Institute of America, San Antonio Noida" },
        { name: "Institute of Hotel Management (IHM), Mumbai" },
        { name: "Ecole Hoteliere Lavasa, Maharashtra" },
      ],
      resource: [
        { name: "Application to Admission", link: "/applications-admissions" },
        { name: "Hospitality Councils" },
        {
          name: "College Mentor Scholarship",
          link: "/college-mentor-scholarship",
        },
        { name: "Hospitality Rankings" },
        { name: "Connect Admission Mentors", link: "/mentors" },
      ],
    },
    stream: "Hospitality",
  },
];

const indianCollegesWithLinks = indianColleges.map((college) => ({
  name: college,
  link: "/college-details",
}));

const isLargeScreen = window.innerWidth > 1280;

const FindPerfectCollege = () => {
  // const data = useSelector(
  //   (state: RootState) => state.collegeLanding.perfectCollege
  // );

  const [backgroundColor, setBackgroundColor] = useState(
    cardsList[0].colorDark
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const changeSelectedTabIndex = (index: number) => setSelectedTabIndex(index);

  if (!data || data.length <= 0) return;

  return (
    <div
      style={{
        background: isLargeScreen
          ? `linear-gradient(to bottom, transparent 60%, ${backgroundColor} 40%)`
          : "transparent",
      }}
    >
      <div className="container text-black py-12 max-sm:py-8">
        <div className="flex flex-col lg:flex-row lg:items-end gap-6 pb-12 max-sm:pb-4">
          <div className="flex-[2_2_0%]">
            <h1 className="text-4xl lg:text-5xl font-bold">
              Find the Perfect College For You
            </h1>
            <p className="text-base text-grey">
              Explore the leading College Profiles, Rankings & Accreditations.
            </p>
          </div>
          <div className="flex-1">
            <SearchBarWithSuggestion data={indianCollegesWithLinks} />
          </div>
        </div>
        <TabSelectorV1
          tabList={data.map((item) => item.stream)}
          activeTab={selectedTabIndex}
          changeActiveTab={changeSelectedTabIndex}
        />
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
          {cardsList.map((card) => {
            let title = card.title(data[selectedTabIndex].stream);
            return (
              <Card
                title={title}
                list={data[selectedTabIndex].data[card.key]}
                colorDark={card.colorDark}
                colorLight={card.colorLight}
                icon={card.icon}
                handleHover={(color) => setBackgroundColor(color)}
              />
            );
          })}
        </div>
        <Link
          to="/list-of-colleges-in-india"
          className="block w-fit mt-6 mx-auto bg-green text-white text-lg font-bold px-6 py-2 rounded-full"
        >
          View all
        </Link>
      </div>
    </div>
  );
};

export default FindPerfectCollege;

interface CardProps {
  title: string;
  list: { name: string; link?: string }[];
  colorDark: string;
  colorLight: string;
  icon: string;
  handleHover: (color: string) => void;
}

const Card: FC<CardProps> = ({
  title,
  list,
  colorDark,
  colorLight,
  icon,
  handleHover,
}) => {
  const SIZE = 8;
  const totalCount = list?.length;
  const totalPages = Math.ceil(totalCount / SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const start = currentPage * SIZE;
  const listItemsToShow = list?.slice(start, start + SIZE);

  return (
    <div
      className="w-full p-4 flex flex-col gap-4"
      style={{ backgroundColor: colorDark }}
      onMouseEnter={() => handleHover(colorDark)}
    >
      <h5 className="text-xl font-bold">{title}</h5>
      <div className="flex-1">
        <div style={{ backgroundColor: colorLight }}>
          {listItemsToShow?.map((item) =>
            item.link ? (
              <Link
                to={item.link}
                className="p-2 flex items-center gap-2"
                style={{ borderBottom: `2px solid ${colorDark}` }}
              >
                <img src={icon} />
                <span className="hover:text-blue">{item.name}</span>
              </Link>
            ) : (
              <div
                className="p-2 flex items-center gap-2"
                style={{ borderBottom: `2px solid ${colorDark}` }}
              >
                <img src={icon} />
                <span className="hover:text-blue">{item.name}</span>
              </div>
            )
          )}
        </div>
      </div>
      <div className="flex gap-4 items-center justify-center text-grey">
        <button
          onClick={() =>
            setCurrentPage((prev) => (totalPages + prev - 1) % totalPages)
          }
        >
          <img src={leftArrowIcon} className="w-4" />
        </button>
        <span>{currentPage + 1}</span>
        <div className="w-24 h-1 bg-grey">
          <div
            className="h-full bg-blue"
            style={{
              width: ((currentPage + 1) / totalPages) * 100 + "%",
            }}
          ></div>
        </div>
        <span>{totalPages}</span>
        <button
          onClick={() => setCurrentPage((prev) => (prev + 1) % totalPages)}
        >
          <img src={rightArrowIcon} className="w-4" />
        </button>
      </div>
    </div>
  );
};
