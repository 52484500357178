import Accordion from "../../components/Accordion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const fallbackData = [
  {
    question: "What is JEE Predictor Tools by College mentor?",
    answer:
      "JEE Predictor Tools help students estimate their potential JEE rank based on scores.",
  },
  {
    question: "Is JEE rank predictors reliable?",
    answer:
      "JEE rank predictors are reasonably accurate but should be used for guidance only.",
  },
  {
    question: "What is the qualifying marks required for JEE main?",
    answer:
      "The qualifying marks for JEE Main vary yearly based on the cutoff.",
  },
  {
    question: "Is JEE main scores are eligible to join in IITs and NITs?",
    answer:
      "Yes, JEE Main scores are used for admission to NITs and as a qualification for JEE Advanced.",
  },
  {
    question: "What is JEE Rank Predictor?",
    answer: "It is a tool to predict your rank based on your JEE score.",
  },
];

const KnowledgeHub = () => {
  const apiData = useSelector(
    (state: RootState) =>
      state.collegePredictorIntermediate.landingData?.knowledgeHubFaqs
  );

  const data = apiData && apiData.length > 0 ? apiData : fallbackData;

  return (
    <section className="py-12 bg-[#FFF1FA] text-black">
      <div className="container flex flex-col gap-4">
        <h3 className="text-4xl lg:text-5xl">
          <span className="font-bold">Knowledge Hub</span> (FAQs)
        </h3>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-2">
          {data.map(
            (item: { question: string; answer: string }, index: number) => (
              <Accordion
                key={index}
                question={item.question}
                answer={item.answer}
              />
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default KnowledgeHub;
