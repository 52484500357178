import React from "react";
import type { SVGProps } from "react";
import { Link } from "react-router-dom";
import StudyAboutImgOne from "../../images/study-abroad/study-abroad-about-img-1.png";
import StudyAboutImgTwo from "../../images/study-abroad/study-abroad-about-img-2.png";
import rankIcon from "../../images/study-abroad/rankIcon.svg";
export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}
interface MentorSupportItem {
  list: string;
}

interface AboutEducationMentorsGloballyProps {
  subHeading: string;
  description: string;
  supportItems: MentorSupportItem[];
}

const aboutEducationMentorsGloballyData = {
  subHeading: "Education is a Passport to a Successful Career",
  description:
    "At College Mentor, we are dedicated to helping students achieve their dreams of studying abroad. With a team of experienced Mentors and a passion for education, we provide personalized guidance and comprehensive support throughout the entire study abroad journey.",
  supportItems: [
    { list: "Expert Mentorship" },
    { list: "Scholarship and Financial Aid" },
    { list: "Application Assistance" },
    { list: "Visa Support" },
    { list: "Pre-Departure and Post-Arrival Services" },
  ],
};

const AboutEducationMentorsGlobally: React.FC<
  AboutEducationMentorsGloballyProps
> = ({ subHeading, description, supportItems }) => {
  return (
    <div className="bg-[#FFF9E5] pt-8 pb-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-10 xl:gap-28">
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2.5">
                <div className="flex flex-col gap-3">
                  <h4 className="text-4xl lg:text-5xl font-normal max-lg:font-semibold text-blue leading-10 lg:leading-[55px] m-0">
                    {subHeading}
                  </h4>
                </div>

                <p className="text-base text-black leading-7">{description}</p>
              </div>

              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                {supportItems.map((item, index) => (
                  <li key={index} className="flex items-center gap-1.5">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3611 4.19132L10.4295 5.11734C10.5768 5.55061 10.6568 6.01575 10.6568 6.5C10.6568 8.85327 8.76526 10.7616 6.43263 10.7616C4.1 10.7616 2.20842 8.85433 2.20842 6.5C2.20842 4.14672 4.1 2.2384 6.43263 2.2384C7.59263 2.2384 8.64316 2.70991 9.40632 3.47345L10.4232 2.78106C9.43053 1.69787 8.01053 1.01929 6.43263 1.01929C3.43263 1.01929 1 3.47345 1 6.5C1 9.52654 3.43263 11.9807 6.43263 11.9807C9.43263 11.9807 11.8653 9.52654 11.8653 6.5C11.8653 5.67486 11.6842 4.89221 11.3611 4.19132Z"
                        fill="#00CC99"
                        stroke="#00CC99"
                        stroke-width="0.1232"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M6.082 7.47502L6.49953 7.88455L6.83917 7.40843C7.60087 6.34062 8.46899 5.45039 9.42532 4.70286C8.37611 5.82818 7.37622 7.04953 6.42223 8.36001L5.43455 7.04967L5.43467 7.04958L5.42719 7.04014C5.02685 6.53481 4.57791 6.13082 4.09126 5.93825C4.1852 5.88541 4.26265 5.88101 4.32807 5.88873C4.46939 5.90542 4.65617 5.99512 4.88604 6.1837C5.10942 6.36694 5.32702 6.60442 5.53242 6.84439C5.58311 6.90362 5.63555 6.96617 5.68813 7.02888C5.8288 7.19666 5.97043 7.36559 6.082 7.47502Z"
                        fill="black"
                        stroke="#00CC99"
                      />
                    </svg>
                    <span className="text-base text-black leading-7">
                      {item.list}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-row gap-6 md:gap-10">
              <Link
                to="../study-abroad-student"
                className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
              >
                For Students <EpRight className="green-arrow-icon" />
              </Link>
              <Link
                to="../study-abroad-university"
                className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
              >
                For Universities <EpRight className="green-arrow-icon" />
              </Link>
            </div>
          </div>

          <div className="w-full lg:w-1/2 flex items-end justify-center lg:justify-end">
            <div className="flex flex-row justify-end items-end gap-4 sm:gap-6">
              <div className="w-full sm:w-64 h-full">
                <img
                  src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Education-passpost-groupimage.jpg"
                  alt="Students"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex flex-col gap-2.5">
                <div className="w-full sm:w-64 h-full">
                  <img
                    src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Education-passpost-degreeimage.jpg"
                    alt="Graduate"
                    className="w-full h-full object-contain"
                  />
                </div>

                <div className="bg-blue text-white py-6 px-2.5 w-full sm:w-64">
                  <div className="flex flex-wrap items-center justify-center gap-2.5">
                    <p className="flex flex-wrap items-center justify-center gap-2 text-lg md:text-4xl font-thin text-white md:leading-9 text-center ">
                      <img src={rankIcon} alt="Rank Icon" />{" "}
                      <strong className="text-xl md:text-2xl font-bold text-white">
                        100+ Top{" "}
                      </strong>
                      Education Mentors Globally
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutEducation = () => {
  return (
    <AboutEducationMentorsGlobally {...aboutEducationMentorsGloballyData} />
  );
};

export default AboutEducation;
