import React, { useState } from "react";
import Modal from "../../../components/Modal";

const ScholorshipEligibility = () => {
  const [termsAndConditionsPopupOpen, setTermsAndConditionsPopupOpen] =
    useState(false);
  return (
    <>
      <div className="px-20 py-10 w-full bg-[#FFF1FA] max-lg:px-7 max-lg:w-full my-6">
        <div className="flex gap-10 max-lg:flex-col-reverse">
          <div className="flex flex-col w-[51%] max-lg:ml-0 max-lg:w-full">
            <img
              src="https://cdncollegementor.blob.core.windows.net/cm-scholarship/sc-eligibility.jpg"
              alt="a woman in graduation gown holdling certificate"
              className="object-contain grow w-full rounded-xl aspect-[1.45] max-lg:mt-10 max-lg:max-w-full"
            />
          </div>
          <div className="flex flex-row max-lg:flex-col gap-5 w-[49%] max-lg:ml-0 max-lg:w-full my-auto justify-between">
            <div className="flex flex-col gap-5 flex-1">
              <div className="p-[15px] text-base border border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
                <h2 className="text-base font-semibold ">
                  Eligibility Criteria:
                </h2>
                <ul className="list-disc pl-4 p-3">
                  <li>Must be an Indian citizen.</li>
                  <li>
                    Currently studying in Class 12 or equivalent from a
                    recognized board in India.
                  </li>
                </ul>
                <button
                  onClick={() => setTermsAndConditionsPopupOpen(true)}
                  className="text-blue"
                >
                  See Terms and Conditions
                </button>
              </div>
              <div className="p-[15px] text-base border border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
                <h2 className="text-base font-semibold ">
                  Scholarship Test Pattern:
                </h2>
                <ul className="list-disc pl-4 space-y-2 font-normal">
                  <li>
                    <strong>Stream : </strong>Science (PCM)
                  </li>
                  <li>
                    <strong>Format:</strong> Online Exam (MCQs)
                  </li>
                  <li>
                    <strong>Total Questions:</strong> 45 (15 in Section A GK, 30
                    in Section B PCM)
                  </li>
                  <li>
                    <strong>Duration:</strong> 60 minutes
                  </li>
                  <li>
                    <strong>Maximum Marks:</strong> 135
                  </li>
                  <li>
                    <strong>Scoring:</strong> +3 for correct answers, -1 for
                    incorrect answers
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-5 flex-1">
              <div className="pl-[25px] pr-[35px] py-[15px] text-base border border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
                <h2 className="text-base font-semibold ">Important Dates:</h2>
                <ul className="list-disc pl-4 space-y-2 font-normal ">
                  <li>
                    <strong>Registration Start:</strong> <br />
                    November 15, 2024
                  </li>
                  <li>
                    <strong>Registration End:</strong> <br />
                    March 30, 2025
                  </li>
                  <li>
                    <strong>Test Dates:</strong> <br />
                    April 1-10, 2025
                  </li>
                  <li>
                    <strong>Results Announcement:</strong>
                    <br /> April 15, 2025
                  </li>
                </ul>
              </div>
              <div className="p-[15px] text-base border text-black border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
                <strong className="text-blue">
                  Dr. APJ Abdul Kalam Scholarships
                </strong>
                <p>
                  The Dr. APJ Abdul Kalam Scholarships Rewards celebrates
                  academic excellence by awarding deserving students across five
                  prestigious categories with scholarships totaling{" "}
                  <strong className="text-blue">₹1 crore.*</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={termsAndConditionsPopupOpen}
        onClose={() => setTermsAndConditionsPopupOpen(false)}
      >
        <div className="p-4 lg:p-8 max-h-[80vh] w-[90vw] lg:w-[80vw] overflow-y-auto bg-white flex flex-col gap-2">
          <h1 className="text-xl font-bold mt-4">
            College Mentor Scholarship Terms and Conditions
          </h1>

          <h1 className="text-lg text-blue mt-4">1. General Terms</h1>
          <p>
            <strong>Eligibility:</strong> The College Mentor Scholarship is open
            to Indian citizens who are currently studying in Class 12 or
            equivalent from a recognized educational board in India.
          </p>
          <p>
            <strong>Registration:</strong> Registration for the scholarship test
            starts on November 15, 2024, and ends on March 30, 2025. Only those
            who complete the registration process within this period are
            eligible to appear for the scholarship test.
          </p>
          <p>
            <strong>Test Format:</strong> The scholarship test will consist of
            45 questions: 15 General Knowledge (GK) questions and 30 Physics,
            Chemistry, and Mathematics (PCM) questions.
          </p>
          <p>
            <strong>Duration:</strong> 60 minutes
          </p>
          <p>
            <strong>Maximum Marks:</strong> 135
          </p>
          <p>
            <strong>Scoring:</strong> +3 marks for each correct answer, -1 mark
            for each incorrect answer.
          </p>

          <h1 className="text-lg text-blue mt-4">
            2. Scholarship Test Conditions
          </h1>
          <p>
            The test will be conducted online during the specified dates (April
            1-10, 2025). If the test scores are the same for two or more
            students in the same rank, the tie-breaker will be based on the
            higher number of correct answers in the GK section first, then the
            PCM section.
          </p>
          <p>
            <strong>Test Integrity:</strong> Any attempt to cheat, misrepresent,
            or engage in dishonest conduct during the test will lead to
            immediate disqualification, and the participant will forfeit their
            eligibility for the scholarship.
          </p>
          <p>
            <strong>Test Results:</strong> The results of the scholarship test
            will be announced on April 15, 2025. The merit list will be
            published on the official platform.
          </p>

          <h1 className="text-lg text-blue mt-4">3. Scholarship Categories</h1>
          <p>
            <strong>Merit-Based Awards:</strong> Dr. A.P.J. Abdul Kalam
            Scholarship recognizes academic excellence. Scholarships will be
            awarded to the top rankers in the merit list. Details of the awards
            are as follows:
          </p>
          <ul className="pl-4">
            <li>
              Ranks 1-10: Choice of World Education Tour, ₹75,000 cash prize, or
              25% off first-year tuition fees at prestigious institutions (IIT,
              NIT, AIIMS, NIFT, NID).
            </li>
            <li>Ranks 11-15: ₹50,000 cash prize.</li>
            <li>Ranks 16-20: ₹25,000 cash prize.</li>
          </ul>
          <p>
            <strong>Championing Gender Equality:</strong> 500 scholarships for
            boys and 500 for girls will be awarded based on the merit list.
          </p>
          <ul className="pl-4">
            <li>
              Ranks 1-25: Cash prize of ₹10,000, ₹7,500 scholarship at partner
              universities, and College Mentor services worth ₹5,000.
            </li>
            <li>
              Ranks 51-300: ₹7,500 scholarship and College Mentor services worth
              ₹5,000.
            </li>
            <li>
              Ranks 301-500: ₹5,000 scholarship and College Mentor services
              worth ₹5,000.
            </li>
          </ul>
          <p>
            <strong>Special Category Scholarships:</strong> Scholarships for
            Government School Students, LGBTQ+ Students, and Students with
            Disabilities. Awards for Top 100 Rankers in these categories:
          </p>
          <ul className="pl-4">
            <li>
              Ranks 1-15: Cash prize of ₹10,000, ₹7,500 scholarship, and College
              Mentor services worth ₹5,000.
            </li>
            <li>
              Ranks 16-60: ₹7,500 scholarship and College Mentor services worth
              ₹5,000.
            </li>
            <li>
              Ranks 61-100: ₹5,000 scholarship and College Mentor services worth
              ₹5,000.
            </li>
          </ul>
          <p>
            <strong>Note:</strong> To claim under this category, the student has
            to produce a certificate from the college Principal that he/she is
            studying in the government school.
          </p>
          <a
            href="/pdf/Certificate_format.pdf"
            download
            target="blank"
            className="text-blue hover:underline cursor-pointer"
          >
            certificate format
          </a>
          <br />

          <p>
            <strong>Sports and Arts Category:</strong> Specific scholarships
            will be awarded to students excelling in sports and arts, as per
            eligibility.
          </p>
          <ul className="pl-4">
            <li>
              Ranks 1-15: Cash prize of ₹10,000, ₹7,500 scholarship, and College
              Mentor services worth ₹5,000.
            </li>
            <li>
              Ranks 16-60: ₹7,500 scholarship and College Mentor services worth
              ₹5,000.
            </li>
            <li>
              Ranks 61-100: ₹5,000 scholarship and College Mentor services worth
              ₹5,000.
            </li>
          </ul>
          <div className=" pl-7">
            <strong className="inline-block mt-2">Note: </strong> Students need
            to present their achievements in the following areas.
            <ul>
              <li>
                <strong>State-Level Sports Certificate:</strong> Achieved within
                the last two years in any sport.
              </li>
              <li>
                <strong>District-Level Sports Certificate:</strong> Achieved
                within the last four years in any sport.
              </li>
              <li>
                <strong>Painting & Sketching:</strong> Submit portfolio of
                original artwork that showcases their skills and creativity.
              </li>
              <li>
                <strong>Photography:</strong> Present a collection of
                photographs or a photography project demonstrating technical
                proficiency and artistic vision.
              </li>
              <li>
                <strong>Community Service:</strong> Provide documentation of
                involvement in meaningful community service projects,
                highlighting the impact and leadership demonstrated.
              </li>
            </ul>
          </div>
          <h1 className="text-lg text-blue mt-4">
            4. Disbursement of Scholarships
          </h1>
          <p>
            <strong>World Education Tour to Europe:</strong> Awarded to the top
            rankers as a part of the Ratan Tata Scholarship.
          </p>
          <p>
            <strong>Tuition Fees:</strong> Scholarships for tuition fees will be
            directly transferred to the respective educational institutions.
          </p>
          <p>
            <strong>Cash Prizes:</strong> Cash prizes will be transferred to the
            winners' bank accounts upon verification.
          </p>
          <p>
            <strong>College Mentor Services:</strong> The scholarship for
            College Mentor services will be applied at the time of admission to
            partner universities and prioritized in the admission process. All
            participants will receive a Digital Certificate of Participation
            upon completion of the scholarship test.
          </p>

          <h1 className="text-lg text-blue mt-4">5. Terms for Rankers</h1>
          <p>
            <strong>Rank Eligibility:</strong> Only students who are ranked
            based on the scholarship test results will be eligible for the prize
            awards. Scholarships are non-transferable.
          </p>
          <p>
            <strong>Tie-Breaker:</strong> In case of a tie between two or more
            students, the higher score in the General Knowledge (GK) section
            will be used as the primary factor for breaking the tie. If still
            tied, the Physics, Chemistry, and Mathematics (PCM) score will be
            considered.
          </p>
          <p>
            <strong>Prize Distribution:</strong> Scholarship prizes, including
            cash prizes and tuition fee discounts, will be awarded based on the
            rankings and specific prize categories.
          </p>
          <p>
            <strong>Compliance:</strong> All prize recipients must comply with
            the requirements for verification of their eligibility for the
            respective scholarships. Non-compliance will result in
            disqualification.
          </p>

          <h1 className="text-lg text-blue mt-4">
            6. Participation and Test Conditions
          </h1>
          <p>
            <strong>Online Examination:</strong> The scholarship test will be
            conducted online. Participants must ensure they have a stable
            internet connection and the necessary hardware to take the test.
          </p>
          <p>
            <strong>Test Validity:</strong> The test will be valid only for the
            specified dates, and no rescheduling of test dates will be
            permitted.
          </p>
          <p>
            <strong>Test Integrity:</strong> The scholarship test is monitored
            for security. Any use of unfair means will lead to immediate
            disqualification.
          </p>

          <h1 className="text-lg text-blue mt-4">
            7. Privacy and Data Protection
          </h1>
          <p>
            By participating in the College Mentor Scholarship, you consent to
            the collection, use, and processing of your personal data as per the
            Privacy Policy outlined by Dreams & Degrees EduTech Pvt Ltd.
            Personal information will be used solely for the purpose of
            administering the scholarship, notifying winners, and awarding
            scholarships.
          </p>

          <h1 className="text-lg text-blue mt-4">8. Disqualification</h1>
          <p>
            Participants found providing false information, engaging in
            cheating, or misrepresentation during registration or the
            examination will be disqualified. Participants who fail to comply
            with the terms and conditions will lose eligibility for the
            scholarship.
          </p>

          <h1 className="text-lg text-blue mt-4">
            9. Changes to Terms and Conditions
          </h1>
          <p>
            Dreams & Degrees reserves the right to amend or modify these Terms
            and Conditions at any time. Participants will be notified of any
            changes, and continued participation in the scholarship test
            signifies acceptance of these changes.
          </p>
          <p>
            <strong>Note:</strong> In case of any mismatch of information
            between booklet given and website, the information displayed in the
            website is final
          </p>

          <h1 className="text-lg text-blue mt-4">
            Contact Information for College Mentor Scholarship
          </h1>
          <p>
            For any clarity regarding scholarships, rank analysis, scholarship
            distribution, registrations, or any other issues related to the
            College Mentor Scholarship, please connect with the College Mentor
            team through the following contact details:
          </p>
          <p>
            <strong>Email:</strong> admin@collegementor.com
          </p>
          <p>
            <strong>Phone:</strong>+91 7997166666, +91 8959591818
          </p>
          <p>
            Our team is available to assist you with any queries you may have
            and guide you through the scholarship process.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ScholorshipEligibility;
