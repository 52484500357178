import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "./MentorNetwork.scss";
import config from "../../../util/config";
import { Link } from "react-router-dom";
import { convertToKebabCase } from "../../../util/commonUtils";

interface MentorCardProps {
  name: string;
  year: string;
  imageUrl: string;
  id: any;
  experiance: any;
  worksOn: any;
  location: any;
  country: {};
}

interface MentorSectionProps {
  title: string;
  mentors: MentorCardProps[];
}

const MentorCard: React.FC<MentorCardProps> = ({
  name,
  year,
  imageUrl,
  id,
}) => {
  return (
    <Link to={`/mentor/${convertToKebabCase(name)}-${id}`}>
      <div className="bg-[#F7F7F7] rounded-lg shadow-md overflow-hidden max-md:w-[80%] max-md:mx-auto">
        <img src={imageUrl} alt={`${name}`} className="w-full h-[184px]" />
        <div className="p-4">
          <h3 className="text-sm font-semibold">{name}</h3>
          <p className="text-[#333] text-sm">{year}</p>
        </div>
      </div>
    </Link>
  );
};

const MentorSection: React.FC<MentorSectionProps> = ({ title, mentors }) => {
  var prevButtonClass = `swiper-button-prev-${title.replace(
    /[,\s]+|[,\s]+/g,
    ""
  )}`;
  var nextButtonClass = `swiper-button-next-${title.replace(
    /[,\s]+|[,\s]+/g,
    ""
  )}`;
  const [autoplay, setAutoplay] = useState({
    delay: 3000,
    disableOnInteraction: false,
  });

  const handleMouseEnter = () => {
    setAutoplay({
      delay: 15000,
      disableOnInteraction: false,
    });
  };

  const handleMouseLeave = () => {
    setAutoplay({
      delay: 3000,
      disableOnInteraction: false,
    });
  };

  return (
    <div className="flex flex-col mb-5">
      <h3 className="text-base text-[#173CBA] leading-10">{title}</h3>

      <div
        className="relative mentor-network"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`swiper-button-prev ${prevButtonClass}`}></div>
        <div className={`swiper-button-next ${nextButtonClass}`}></div>

        <Swiper
          navigation={{
            nextEl: `.${nextButtonClass}`,
            prevEl: `.${prevButtonClass}`,
          }}
          spaceBetween={20}
          slidesPerView={4.5}
          className="w-full"
          autoplay={autoplay}
          breakpoints={{
            320: { slidesPerView: 1 },
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4.5,
            },
          }}
          loop={true}
          modules={[Autoplay, Navigation, Pagination]}
        >
          {mentors.map((mentor, index) => (
            <SwiperSlide key={index}>
              <MentorCard {...mentor} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const MentorNetwork: React.FC<any> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [mentorResponse, setmentorsSection] = useState([]);

  useEffect(() => {
    const getMentorsForSection = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          config.apiUrl + "/api/auth/mentors/mentorSectionList"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }
        const responseData = await response.json();
        setmentorsSection(responseData.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getMentorsForSection();
  }, []);

  return (
    <div className="container mx-auto  mt-8">
      <h3 className="text-center mb-12 text-[#383838] text-4xl">
        Why settle for just one mentor when you can have a whole network of
        them?
      </h3>
      <div className="flex flex-row min-max-xss:flex-col min-max-xs:flex-col">
        <div className="w-2/6 pr-8 min-max-xss:w-full min-max-xs:w-full min-max-xss:pr-0 min-max-xs:pr-0">
          {mentorResponse?.map((section: any, index: any) => (
            <button key={index} className={`block text-left p-2 mb-2 `}>
              <h2 className="text-base font-semibold">{section.title}</h2>
              <div className="md:flex flex-wrap gap-1">
                <span className="bg-[#F7F7F7] text-[#747474] px-2 py-1 rounded-full text-xs">
                  #Peer Support
                </span>
                <span className="bg-[#F7F7F7] text-[#747474] px-2 py-1 rounded-full text-xs">
                  #Campus Guide
                </span>
                <span className="bg-[#F7F7F7] text-[#747474] px-2 py-1 rounded-full text-xs">
                  #Academic Companion
                </span>
                <span className="bg-[#F7F7F7] text-[#747474] px-2 py-1 rounded-full text-xs">
                  #Student Life
                </span>
                <span className="bg-[#F7F7F7] text-[#747474] px-2 py-1 rounded-full text-xs">
                  #Social Integration
                </span>
                <span className="bg-[#F7F7F7] text-[#747474] px-2 py-1 rounded-full text-xs">
                  #Peer Assistance
                </span>
              </div>
            </button>
          ))}
        </div>

        <div className="w-4/6 md:pl-6 min-max-xss:w-full min-max-xs:w-full min-max-xs:pl-2 min-max-xss:pl-2 h-[950px] overflow-y-scroll scrollbar-hide mb-10">
          {mentorResponse?.map((section: any, index: any) => (
            <div key={index}>
              <MentorSection
                title={section.title.replace(/\(|\)/g, "")}
                mentors={section.mentors}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MentorNetwork;
