import React, { useCallback, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import CounterAnimation from "../../../components/CounterAnimation";
import { Autoplay } from "swiper/modules";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

interface Exam {
  id: number;
  examName: string;
  description: string;
  applicationCost: string;
  applicationOpen: string;
  examDate: string;
  onlineExam: string;
  iconUrl: string;
  imageUrl: string;
}

export const FeaturedExams = (data: any) => {
  const featuredExamsData = data?.data?.featuredExams;
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const progressBarElement = (
    <div className="flex gap-4 items-center xl:hidden">
      <span>{currentSlide + 1}</span>
      <div className="w-24 h-1 bg-[#D9D9D9]">
        <div
          className="h-full bg-[#173CBA]"
          style={{
            width: featuredExamsData.mostRecentExam.length
              ? ((currentSlide + 1) / featuredExamsData.mostRecentExam.length) *
                  100 +
                "%"
              : "0%",
          }}
        ></div>
      </div>
      <span>{featuredExamsData.mostRecentExam.length}</span>
    </div>
  );
  const prevButton = (
    <button
      className={`${navBtnDisabled.prev ? "" : ""} p-2.5`}
      onClick={handlePrevious}
      onMouseEnter={() => setIsPrevHovered(true)}
      onMouseLeave={() => setIsPrevHovered(false)}
    >
      <LeftArrow />
    </button>
  );

  const nextButton = (
    <button
      className={`${navBtnDisabled.next ? "" : ""} p-2.5`}
      onClick={handleNext}
    >
      <RightArrow isPrevHovered={isPrevHovered} />
    </button>
  );

  const [activeSlideData, setActiveSlideData] = useState<Exam | null>(null);

  const handleSlideChange = (swiper: any) => {
    const activeIndex = swiper.realIndex;
    const slideData = featuredExamsData.mostRecentExam[activeIndex];
    if (swiper) {
      setCurrentSlide(activeIndex);
      setNavBtnDisabled({
        prev: swiper.isBeginning,
        next: swiper.isEnd,
      });
    }

    setActiveSlideData(slideData);
  };

  return (
    <div className="featured-exams-section bg-[#fff] pt-14 pb-20 max-sm:py-8">
      <h2 className="text-[32px] text-center font-medium mt-5 md:hidden max-sm:mt-0 ">
        Featured Exams
      </h2>
      <div className="container mx-auto md:flex md:flex-row gap-5">
        <div className=" items-end w-full md:w-3/12 bg-[#B2EDFF] min-max-md:pl-3 pl-7 pr-5 pt-7 pb-9 rounded-lg relative overflow-hidden max-md:hidden">
          <div className=" flex justify-between">
            <div className="w-24 max-h-8">
              <img
                src={activeSlideData?.iconUrl}
                alt="clg logo"
                className="max-h-8"
              />
            </div>
            <div className="text-xl text-[#173CBA] font-bold truncate">
              {activeSlideData?.examName}
            </div>
          </div>
          <div className="text-[#383838] text-base">
            <p className="font-bold mb-10 mt-7 truncate">
              {activeSlideData?.examName}
            </p>
            <p className="border-b-[0.5px] border-[#173CBA] mb-[6px] w-44">
              {activeSlideData?.description}
            </p>
            <p className="border-b-[0.5px] border-[#173CBA] mb-[6px]">
              Application cost <br /> {activeSlideData?.applicationCost}
            </p>
            <p className="border-b-[0.5px] border-[#173CBA] mb-[6px]">
              Application open <br /> {activeSlideData?.applicationOpen}
            </p>
            <p className="border-b-[0.5px] border-[#173CBA] mb-[6px]">
              Exam date {activeSlideData?.examDate}
            </p>
            <p className=" mb-[6px]">
              Online exam {activeSlideData?.onlineExam}
            </p>
          </div>
          <div className="w-64 h-36 min-max-md:hidden rounded-full bg-cover bg-center transform rotate-45 bg-[#2efe58] absolute top-[128px] -right-[128px] z-10 overflow-hidden">
            <img
              className="transform -rotate-45 relative -top-9"
              src={activeSlideData?.imageUrl}
              alt=""
            />
          </div>
          <div className="w-64 h-36 min-max-md:hidden rounded-full bg-cover bg-center transform rotate-45 bg-[#0C9] absolute top-[138px] -right-[108px]"></div>
          <LeadGenCtaBtn
            pageName="exam-landing"
            ctaName="featured-exam-apply"
            additionalInfo={activeSlideData?.examName || ""}
            className="absolute bottom-0 right-0 text-white text-base bg-[#173CBA] py-1 px-3 rounded-tl-lg"
          >
            Apply now
          </LeadGenCtaBtn>
        </div>

        <div className="w-full md:w-9/12 ">
          <h2 className="text-5xl text-center font-medium mt-5 max-sm:hidden">
            Featured Exams
          </h2>
          <div className="flex">
            <div className="mt-6 max-md:pr-5 w-full">
              <div className="flex justify-between gap-5 max-md:flex-col max-md:gap-0">
                {featuredExamsData.stats.map((stat: any) => {
                  return (
                    <div className="md:w-[33%] ">
                      <div className="flex flex-row grow items-center px-5 py-6 w-full text-center bg-white ">
                        <div className="card-logo w-14 h-16 mr-5 relative max-md:ml-16">
                          <div className="w-12 h-12 bg-[#B5C5F9] rounded-full absolute top-0 left-0"></div>
                          <div className="w-11 h-11 absolute right-0 bottom-0">
                            <img
                              src={stat.iconUrl}
                              alt="icon"
                              className="w-full"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <span className="mt-4 text-4xl font-bold leading-5 text-[#383838]">
                            <CounterAnimation start={0} end={stat.count} /> +
                          </span>
                          <span className="mt-5 text-base  leading-5 text-[#383838]">
                            {stat.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className=" items-end w-full md:w-3/12 bg-[#B2EDFF] pl-7 pr-5 pt-7 pb-9 rounded-lg relative overflow-hidden md:hidden">
            <div className=" flex justify-between">
              <div className="">
                <img
                  src={activeSlideData?.iconUrl}
                  alt="clg logo"
                  className="max-h-8"
                />
              </div>
              <div className="text-xl text-[#173CBA] font-bold truncate">
                {activeSlideData?.examName}
              </div>
            </div>
            <div className="text-[#383838] text-base">
              <p className="font-bold mb-10 mt-7 truncate">
                {activeSlideData?.examName}
              </p>
              <p className="border-b-[0.5px] border-[#173CBA] mb-[6px] w-44">
                {activeSlideData?.description}
              </p>
              <p className="border-b-[0.5px] border-[#173CBA] mb-[6px]">
                Application cost <br /> {activeSlideData?.applicationCost}
              </p>
              <p className="border-b-[0.5px] border-[#173CBA] mb-[6px]">
                Application open <br /> {activeSlideData?.applicationOpen}
              </p>
              <p className="border-b-[0.5px] border-[#173CBA] mb-[6px]">
                Exam date {activeSlideData?.examDate}
              </p>
              <p className=" mb-[6px]">
                Online exam {activeSlideData?.onlineExam}
              </p>
            </div>
            <div className="w-64 h-36 rounded-full bg-cover bg-center transform rotate-45 bg-[#2efe58] absolute top-[128px] -right-[128px] z-10 overflow-hidden">
              <img
                className="transform -rotate-45 relative -top-9"
                src="/images/center-image.png"
                alt=""
              />
            </div>
            <div className="w-64 h-36 rounded-full bg-cover bg-center transform rotate-45 bg-[#0C9] absolute top-[138px] -right-[108px]"></div>
            <LeadGenCtaBtn
              pageName="exam-landing"
              ctaName="featured-exam-apply"
              additionalInfo={activeSlideData?.examName || ""}
              className="absolute bottom-0 right-0 text-white text-base bg-[#173CBA] py-1 px-3 rounded-tl-lg"
            >
              Apply now
            </LeadGenCtaBtn>
          </div>
          <div className="flex justify-center container swiper-buttons relative mx-auto py-7 max-sm:py-1 md:hidden">
            {prevButton}
            {progressBarElement}
            {nextButton}
          </div>
          <div className="featured-exams-slider flex ">
            <Swiper
              onSwiper={(swiper) => {
                setSwiperRef(swiper);
              }}
              onSlideChange={handleSlideChange}
              spaceBetween={30}
              className="w-full"
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              {featuredExamsData.mostRecentExam.map(
                (exam: any, index: number) => (
                  <SwiperSlide
                    className="flex flex-col gap-4 bg-[#FFF6F1] rounded-lg relative"
                    style={{
                      boxShadow: "0px 2.033px 7.622px 0px rgba(0, 0, 0, 0.10)",
                    }}
                    key={exam.id}
                  >
                    <div
                      className="flex flex-col gap-6 h-[180px] bg-black bg-opacity-75 bg-cover bg-center w-full rounded-lg max-md:hidden"
                      style={{
                        backgroundSize: "cover",
                        backgroundImage: `url(${exam.imageUrl})`,
                      }}
                    >
                      <div className="flex flex-col px-2 ">
                        <div className="w-16 h-9 bg-white rounded-br-lg absolute left-0 flex items-center">
                          <img
                            src={exam.iconUrl}
                            alt="logo"
                            className=" mx-auto max-h-full max-w-full object-contain"
                          />
                        </div>
                        <button className="w-36 text-xs bg-[#173CBA] text-white mt-12 mb-2 -ml-2 truncate">
                          {exam.examName}
                        </button>

                        <p className="text-base text-[#fff] line-clamp-2">
                          {exam.description}
                        </p>
                        <p className="text-base text-[#fff] ">
                          {exam.applicationOpen}
                        </p>
                        <LeadGenCtaBtn
                          pageName="exam-landing"
                          ctaName="featured-exam-apply"
                          additionalInfo={activeSlideData?.examName || ""}
                          className="text-[#14E0AD] text-base font-bold text-left absolute bottom-2"
                        >
                          Apply Now
                        </LeadGenCtaBtn>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

const LeftArrow = () => {
  return (
    <div className="p-4 border border-black hover:border-[#173CBA] rounded-full bg-white hover:bg-[#173CBA] group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-current text-black group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
        />
      </svg>
    </div>
  );
};

const RightArrow = ({ isPrevHovered }: { isPrevHovered: boolean }) => {
  return (
    <div
      className={`p-4 border rounded-full ${
        isPrevHovered ? "bg-white border-black" : "bg-blue border-blue"
      }`}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${
            isPrevHovered
              ? "fill-current text-black"
              : "fill-current text-white"
          }`}
          d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
        />
      </svg>
    </div>
  );
};
