import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "animate.css";
import "swiper/css";
import "swiper/css/bundle";
import { Navigation, Pagination } from "swiper/modules";

import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
import LeadGenCtaBtn from "../../../../features/lead-generation/LeadGenCtaBtn";

const TopIndianPrivateCollegesList = () => {
  const { data } = useCourseDetails();
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const TopIndianPrivateCollegesContent = {
    title: "Top Indian Private colleges offering mechanical engineering ",
    collegeList: [
      {
        collegeImg:
          "/images/course-details/ManipalAcademyofHigherEducation.png",
        title: "Manipal Academy of Higher Education",
        location: "Karnataka",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/AmritaVishwaVidyapeetham.png",
        title: "Amrita Vishwa Vidyapeetham, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Coimbatore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/Vellore InstituteofTechnology.png",
        title: "Vellore Institute of Technology (VIT), ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Vellore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/SaveethaInstitute.png",
        title: "Saveetha Institute of Medical and Technical Sciences, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Chennai",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/AmritaVishwaVidyapeetham.png",
        title: "Amrita Vishwa Vidyapeetham, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Coimbatore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/Vellore InstituteofTechnology.png",
        title: "Vellore Institute of Technology (VIT), ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Vellore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/SaveethaInstitute.png",
        title: "Saveetha Institute of Medical and Technical Sciences, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Chennai",
        moreLink: "/college",
      },
    ],
  };

  return (
    <div className="flex w-full flex-col lg:flex-row">
      <Swiper
        onSwiper={setSwiperRef}
        spaceBetween={30}
        className="w-full"
        modules={[Navigation]}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
      >
        {TopIndianPrivateCollegesContent.collegeList.map(
          (college: any, index: number) => (
            <SwiperSlide
              className="flex flex-col gap-4 bg-[#FFF6F1] rounded-lg"
              style={{
                boxShadow: "0px 2.033px 7.622px 0px rgba(0, 0, 0, 0.10)",
              }}
              key={index}
            >
              <div className="flex flex-col gap-6">
                <img
                  src={college.collegeImg}
                  alt="college"
                  className="w-full h-48 object-cover"
                />
                <div className="flex flex-col gap-2 py-2 px-4">
                  <h4 className="text-sm font-semibold text-[#383838] m-0">
                    {college.title}, <span>{college.location}</span>
                  </h4>

                  <p className="text-[10px] text-[#383838] m-0 flex items-center gap-2">
                    {college?.program}
                  </p>
                  <p className="text-[10px] text-[#4C495C]">
                    {college.specialisations}
                  </p>
                  <p className="text-[8px] text-right text-[#4C495C] font-bold">
                    Total Fees: {college.totalFee}
                  </p>
                </div>
              </div>
              <div className="flex w-full justify-center my-2">
                <LeadGenCtaBtn
                  pageName="course-detail"
                  ctaName="book-college-tour"
                  className="text-[11px] bg-[--primary-color] text-white font-semibold rounded-md py-2 px-5 cursor-pointer hover:bg-[--secondary-color] transition-all duration-300 ease-in-out group"
                >
                  Apply Now & Book College Tour
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 bg-[#0C9] text-[#fff] rounded-full p-1 inline-block ml-2 group-hover:translate-x-2 transition-transform duration-300"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </LeadGenCtaBtn>
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
      <div className="flex lg:flex-col gap-4 items-center justify-center  swiper-buttons relative mt-4 lg:mt-0 lg:ml-14 ">
        <button
          onClick={handlePrevious}
          className="text-white bg-blue p-4 rounded-full text-base"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={`fill-current text-white`}
              d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
            />
          </svg>
        </button>
        <button
          onClick={handleNext}
          className="text-white bg-blue p-4 rounded-full text-base"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="curentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={`fill-current text-white`}
              d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const TopIndianPrivateColleges: React.FC = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "" } = data || {};
  return (
    <div className="bg-[#fff] relative">
      <div className="flex flex-col">
        <div className="flex max-md:w-full">
          <h2 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {`Top Indian Private Colleges `}
            <ToolTip text="As per NIRF private colleges ranking" mb={3} />
          </h2>
        </div>
        <div className="flex p-9">
          <TopIndianPrivateCollegesList />
        </div>
      </div>
    </div>
  );
};

export default TopIndianPrivateColleges;

const ToolTipIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.497 6.125C8.497 6.50633 8.38867 7.1 8.172 7.906C7.95533 8.70333 7.847 9.22333 7.847 9.466C7.847 9.70867 7.89467 9.83 7.99 9.83C8.08533 9.83 8.25867 9.78667 8.51 9.7L8.627 9.648L8.861 10.298C8.56633 10.5233 8.198 10.7227 7.756 10.896C7.32267 11.0693 6.92833 11.156 6.573 11.156C6.21767 11.156 5.936 11.0563 5.728 10.857C5.52867 10.6577 5.429 10.3717 5.429 9.999C5.429 9.61767 5.533 9.04133 5.741 8.27C5.95767 7.49 6.066 6.996 6.066 6.788C6.066 6.46733 5.95333 6.164 5.728 5.878L5.624 5.735L5.663 5.514C6.11367 5.37533 6.989 5.306 8.289 5.306C8.42767 5.462 8.497 5.735 8.497 6.125ZM7.522 4.292C7.132 4.292 6.82433 4.188 6.599 3.98C6.38233 3.772 6.274 3.50767 6.274 3.187C6.274 2.85767 6.40833 2.58467 6.677 2.368C6.95433 2.14267 7.288 2.03 7.678 2.03C8.068 2.03 8.37133 2.121 8.588 2.303C8.80467 2.485 8.913 2.74067 8.913 3.07C8.913 3.39933 8.77867 3.68533 8.51 3.928C8.25 4.17067 7.92067 4.292 7.522 4.292Z"
      fill="white"
    />
    <circle cx="7" cy="7" r="6.5" stroke="white" />
  </svg>
);

const ToolTip = ({ text, mb }: { text: string; mb: number }) => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (tooltipRef?.current) {
      const buttonRight = tooltipRef.current.getBoundingClientRect().right;
      const screenWidth = window.innerWidth;

      if (buttonRight > screenWidth) setOverflow(true);
      else setOverflow(false);
    }
  }, [tooltipRef]);

  return (
    <div className={`inline-block relative group mb-${mb} `}>
      <ToolTipIcon />
      <span
        ref={tooltipRef}
        className={`absolute opacity-0 group-hover:opacity-100 w-max px-2 py-1 text-sm text-grey font-normal bg-white border border-grey rounded top-0 left-1/2 -translate-y-[110%] ${
          overflow ? "-translate-x-full" : ""
        }`}
      >
        {text}
      </span>
    </div>
  );
};
