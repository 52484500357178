import React, { useState } from "react";
import { Accordion, Option } from "./Accordion";
import "./Accordion.scss";

interface FilterOption {
  key: string;
  label: string;
  isSelected: boolean;
}

interface FilterCategory {
  categoryName: string;
  categoryKey: string;
  options: Option[];
}

interface MobileFiltersProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: (filters: FilterCategory[]) => void;
  handleMobileFilterClose: () => void;
}

const MobileFilters: React.FC<MobileFiltersProps> = ({
  filters,
  setSelectedVal,
  setFilters,
  handleMobileFilterClose,
}) => {
  const [activeCategory, setActiveCategory] = useState<string>(
    filters[0]?.categoryName || ""
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showMore, setShowMore] = useState<boolean>(false);

  const handleFilterChange = (
    value: string,
    filter: FilterCategory,
    index: number
  ) => {
    setSelectedVal((prevSelected: any) => {
      const updatedCategory =
        prevSelected[filter.categoryKey] === value ? "" : value;

      const newSelectedVal = {
        ...prevSelected,
        [filter.categoryKey]: updatedCategory,
      };

      const requestPayload: { [key: string]: string | number } = {};

      Object.keys(newSelectedVal).forEach((key) => {
        const value = newSelectedVal[key];
        if (value) {
          requestPayload[key] = value;
        }
      });

      requestPayload.sortBy = "";
      requestPayload.offset = 0;
      requestPayload.fetchCount = 10;
      requestPayload.search = "";

      return newSelectedVal;
    });

    const updatedFilter = filters.map((filter, filterIndex) => {
      if (
        filterIndex ===
        filters.findIndex((f) => f.categoryName === filter.categoryName)
      ) {
        return {
          ...filter,
          options: filter?.options?.map((option) => ({
            ...option,
            isSelected: option.key === value, // Select only the current option
          })),
        };
      }
      return filter;
    });

    setFilters(updatedFilter);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearAll = () => {
    setSelectedVal({});

    const resetFilters = filters.map((filter) => ({
      ...filter,
      options: filter.options.map((option) => ({
        ...option,
        isSelected: false,
      })),
    }));
    setFilters(resetFilters);

    setSearchTerm("");
  };

  const filteredOptions = filters
    .find((filter) => filter.categoryName === activeCategory)
    ?.options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-white z-[100] flex flex-col">
      <div className="h-[10%] flex items-center justify-between bg-[#061958] p-4 text-white">
        <button
          className="text-white font-bold"
          onClick={handleMobileFilterClose}
        >
          &larr; Filter By
        </button>
        <button className="text-white" onClick={handleClearAll}>
          Clear All
        </button>
      </div>

      <div className="flex h-[70%]">
        <div className="filter-categories w-5/12 bg-[#ECECEC] overflow-y-auto">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`filter-category py-2 px-4 cursor-pointer ${
                activeCategory === filter.categoryName
                  ? "bg-white border-l-4 border-[#00B489]"
                  : ""
              }`}
              onClick={() => setActiveCategory(filter.categoryName)}
            >
              <h4
                className={`text-sm font-medium ${
                  activeCategory === filter.categoryName
                    ? "text-[#173CBA]"
                    : "text-[#242323]"
                }`}
              >
                {filter.categoryName}
              </h4>
            </div>
          ))}
        </div>

        <div className="filter-options bg-white w-7/12 p-4">
          <div className="search-bar mb-4 flex items-center border border-[#D7D5D5] rounded px-2">
            <input
              type="text"
              placeholder={`Search by ${activeCategory}`}
              className="w-full p-2 rounded-md bg-[#fff] text-[#212121] placeholder-[#ABA9A9] border-none focus:outline-none"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span className=" ml-2 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M10.9552 10.8858L14.0975 14.0509M12.6452 6.78966C12.6452 9.99787 10.0445 12.5986 6.83629 12.5986C3.6281 12.5986 1.02734 9.99787 1.02734 6.78966C1.02734 3.58147 3.6281 0.980713 6.83629 0.980713C10.0445 0.980713 12.6452 3.58147 12.6452 6.78966Z"
                  stroke="#113CC0"
                  stroke-width="1.45224"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>

          <div className="options-list max-h-72 overflow-y-auto">
            {filteredOptions &&
              (showMore ? filteredOptions : filteredOptions.slice(0, 5)).map(
                (option, optionIndex) => (
                  <div key={option.key} className="flex items-center mb-3">
                    <input
                      type="checkbox"
                      id={option.key}
                      name={activeCategory}
                      className="custom-checkbox !border-[#242323]"
                      checked={option.isSelected}
                      onChange={() =>
                        handleFilterChange(
                          option.key,
                          filters.find(
                            (f) => f.categoryName === activeCategory
                          )!,
                          optionIndex
                        )
                      }
                    />
                    <label htmlFor={option.key} className="ml-2 text-[#212121]">
                      {option.name}
                    </label>
                  </div>
                )
              )}
            {filteredOptions && filteredOptions.length > 5 && (
              <button
                className="text-[#00CC99] mt-2"
                onClick={() => setShowMore((prev) => !prev)}
              >
                {showMore ? "Show less" : "Show more"}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className=" bg-white shadow-lg p-4 flex justify-end items-center">
        <button
          className="flex items-center justify-center bg-[#173CBA] text-white px-4 py-2 rounded-md w-1/2 ml-2"
          onClick={handleMobileFilterClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 13l4 4L19 7"
            />
          </svg>
          Apply
        </button>
      </div>
    </div>
  );
};

export default MobileFilters;
