import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import Loader from "../../components/Loader/Loader";
import config from "../../util/config";

interface ExamUpdatesProp {
  imageUrl: string;
  description: string;
  title: string;
  order: string;
}

const ExamUpdates: React.FC = () => {
  const { examName } = useParams<{ examName: string }>();
  const [updates, setUpdates] = useState<ExamUpdatesProp[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUpdates = async () => {
      if (!examName) {
        setError("Exam name is missing.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${config.apiUrl}/api/exam-details/${examName}/examdetails-updates`,
          {
            method: "GET",
            headers: { Accept: "*/*" },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch updates");
        }

        const data = await response.json();
        const sortedUpdates = (Object.values(data) as ExamUpdatesProp[]).sort(
          (a, b) => parseInt(a.order) - parseInt(b.order)
        );
        setUpdates(sortedUpdates);
      } catch (err) {
        console.error("Error fetching updates:", err);
        setError("Unable to load updates.");
      } finally {
        setLoading(false);
      }
    };

    fetchUpdates();
  }, [examName]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="text-center text-red-500 font-bold py-4">{error}</div>
    );
  }
  const formatExamName = (examName: string): string => {
    return examName
      .split("-") // Split words by hyphen
      .map((word) => {
        if (word.length < 5) {
          return word.toUpperCase(); // Fully capitalize if less than 5 characters
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize first letter
      })
      .join(" "); // Join with spaces
  };

  return (
    <div className="bg-[#D9F6EF] py-8">
      <div className="container mx-auto">
        <div className="flex flex-col gap-5">
          <h2 className="text-2xl font-bold text-blue">
            {formatExamName(examName || "")}{" "}
            <span className="font-normal">Updates</span>
          </h2>
          <Swiper
            modules={[Navigation, Autoplay]}
            navigation
            spaceBetween={16}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            loop={true}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 4 },
            }}
            className="sliderExamUpdates w-full"
          >
            {updates.map((update, index) => (
              <SwiperSlide key={index}>
                <div className="bg-white rounded-md py-5 px-3.5 flex items-center text-center gap-2">
                  <div className="w-16 h-auto overflow-hidden">
                    <img
                      src={update.imageUrl}
                      alt={update.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-gray-700 text-left">
                      {update.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ExamUpdates;
