import React, { useEffect, useState } from "react";
import { Accordion, Option } from "./Accordion";
import { useSearchParams } from "react-router-dom";

export interface FilterCategory {
  categoryName: string;
  categoryKey: string;
  options: Option[];
}

interface DesktopFiltersProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: (filters: FilterCategory[]) => void;
}

const DesktopFilters: React.FC<DesktopFiltersProps> = ({
  filters,
  setSelectedVal,
  setFilters,
}) => {
  const onlyFilter = filters?.filter(
    (filter) => filter.categoryKey !== "sortBy"
  );
  const [initDone, setinitDone] = useState(false);
  const [searchParams] = useSearchParams();

  const toggleInUrlParams = (
    key: string,
    value: string,
    isMutiSelect: boolean
  ) => {
    const url = new URL(window.location.href); // Get the current URL
    const params = new URLSearchParams(url.search); // Get the current search parameters
    console.log(params.toString(), params.has(key), "paramddds");

    if (params.has(key)) {
      const currentValue = params.get(key);
      console.log("log1");
      let values = currentValue?.split(",");
      console.log("value iss ", isMutiSelect, value, values, currentValue);
      if (!isMutiSelect) {
        if (currentValue === value) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value);
        }
      } else {
        const isValueAlreadyThere = values?.includes(value);
        if (isValueAlreadyThere) {
          console.log("log2");
          console.log("log3");
          values = values?.filter((r) => r !== value);
          if (values) {
            console.log("log4");
            searchParams.set(key, values?.join(","));
          }
        } else {
          console.log("log6");
          const updatedValue = [currentValue, value];
          searchParams.set(key, updatedValue.join(","));
        }
      }
    } else {
      console.log("log7");
      const normalizedValue = Array.isArray(value) ? value.join(",") : value;
      searchParams.set(key, normalizedValue);
    }
    console.log(searchParams.toString(), "searchParams");

    window.history.replaceState(
      null,
      "",
      `${url.pathname}?${searchParams.toString()}`
    );
  };

  const handleFilterChange = (
    value: string,
    filter: FilterCategory,
    index: number
  ) => {
    console.log(filter.categoryKey, "filter.categoryKey,");
    const isMutiSelect =
      filter.categoryKey === "stage" || filter.categoryKey === "city";
    toggleInUrlParams(filter.categoryKey, value, isMutiSelect);

    setSelectedVal((prevSelected: any) => ({
      ...prevSelected,
      [filter.categoryKey]:
        prevSelected[filter.categoryKey] === value ? "" : value,
    }));

    const updatedFilter = filters.map((f, filterIndex) => {
      if (f.categoryKey === filter.categoryKey) {
        const options = f.options.map((option) => ({
          ...option,
          isSelected: option.key === value ? !option.isSelected : false,
        }));
        return { ...f, options };
      }
      return f;
    });

    setFilters(updatedFilter);
  };
  useEffect(() => {
    if (initDone) {
      return;
    }
    const url = new URL(window.location.href); // Get the current URL
    const params = new URLSearchParams(url.search);
    const keys = Array.from(params.keys());

    console.log("search params changed", keys);

    let filtersFromValues: Record<any, any> = {};

    keys.forEach((eachKey) => {
      const value = params.get(eachKey);

      filtersFromValues[eachKey] = value;
    });

    setSelectedVal((prevSelected: any) => {
      return {
        ...prevSelected,
        ...filtersFromValues,
        // [filter.categoryKey]:
        //   prevSelected[filter.categoryKey] === value ? "" : value,
      };
    });
    const updatedFilter = filters.map((f, filterIndex) => {
      if (Object.keys(filtersFromValues).includes(f.categoryKey)) {
        // const value = filtersFromValues[f.categoryKey]
        const options = f.options.map((option) => ({
          ...option,
          isSelected: filtersFromValues[f.categoryKey],
        }));
        return { ...f, options };
      }
      return f;
    });
    console.log(updatedFilter, "updatedFilter");
    setinitDone(true);
    setFilters(updatedFilter);
  }, [filters, initDone, setFilters, setSelectedVal]);

  return (
    <>
      {filters.map((filter, index) => (
        <Accordion
          key={index}
          filters={{
            label: filter.categoryName,
            options: filter.options,
          }}
          isOpen={false}
          onFilterChange={(value: string) => {
            handleFilterChange(value, filter, index);
          }}
          title={filter.categoryName}
        />
      ))}
    </>
  );
};

export default DesktopFilters;
