import { Swiper, SwiperSlide } from "swiper/react";
import React, { useCallback, useState } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./CareerDetailsStyles.scss";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";

const RelatedColleges: React.FC<{ relatedColleges: any }> = ({
  relatedColleges,
}) => {
  const [swiperRef, setSwiperRef] = useState<any>(null);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  if (!relatedColleges) return null;
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col gap-6">
            <div className="flex max-md:flex-col max-md:w-full">
              <h2 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                Top Colleges
              </h2>
            </div>
            <div className="flex flex-col lg:flex-row gap-5 p-7 lg:p-8">
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 blog-content"
                dangerouslySetInnerHTML={{
                  __html: relatedColleges.description as string,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedColleges;
