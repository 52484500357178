import React from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
interface Course {
  id: number;
  courseId: number;
  durationOfCourse: string;
  degreeLevel: string;
  eligibilityCriteria: string;
}

interface CourseTableProps {
  courseData: Course[];
}
export const EligibilityCriteria = ({ courseData }: CourseTableProps) => {
  const { data } = useCourseDetails();
  const { courseTitle = "", eligibilityCriteria } = data || {};
  return (
    <div className="relative bg-white">
      <div className="flex max-md:w-full">
        <h2 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
          {`Eligibility Criteria (Diploma, UG, PG & Ph.D) of ${courseTitle}`}
        </h2>
      </div>
      <div
        className="flex flex-col px-2 py-8 md:py-5 md:px-9 relative overflow-x-auto blog-content"
        dangerouslySetInnerHTML={{
          __html: eligibilityCriteria,
        }}
      />
    </div>
  );
};
