import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface IFeaturedPredictor {
  image: string;
  collegeLogo: string;
  url:string;
}

interface IPredictedExam {
  name: string;
  logo: string;
  slug: string;
}

interface IPredictedExams {
  stateLevel: IPredictedExam[];
  nationalLevel: IPredictedExam[];
  privateUniversity: IPredictedExam[];
  streams: string[];

}

interface IApplyToDirectAdmission {
  image: string;
  collegeLogo: string;
  url:string;
}

interface INewAndAlert {
  id: number;
  title: string;
  date: string;
}

interface IEntranceExam {
  examName: string;
  iconUrl: string;
  collegesParticipating: string;
  examDate: string;
  examLevel: string;
}

interface IStudentReview {
  imageUrl: string;
  reviewBy: string;
  review: string;
}

interface IBlogAndArticle {
  id: number;
  description: string;
  imageUrl: string;
  title: string;
}

interface IKnowledgeHub {
  categories: string[];
  fqas: {
    question: string;
    answer: string;
  }[];
}

interface PredictorLanding {
  featuredPredictors: IFeaturedPredictor[];
  
    predictedExams: IPredictedExams;

  directAdmissions: IApplyToDirectAdmission[];
  newsAlerts: INewAndAlert[];
  entranceExams: IEntranceExam[];
  studentsReviews: IStudentReview[];
  blogsAndArticles: IBlogAndArticle[];
  knowledgrHub: IKnowledgeHub;
}

const initialState: PredictorLanding = {
  featuredPredictors: [],
    predictedExams: {
      stateLevel: [],
      nationalLevel: [],
      privateUniversity: [],
      streams:[],
    },
  
    directAdmissions: [],
    newsAlerts: [],
  entranceExams: [],
  studentsReviews: [],
  blogsAndArticles: [],
  knowledgrHub: {
    categories: [],
    fqas: [],
  },
};

// Create the slice
const predictorLandingSlice = createSlice({
  name: "predictorLanding",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<PredictorLanding>) => {
      return action.payload;
    },
  },
});

export const { update } = predictorLandingSlice.actions;
export default predictorLandingSlice.reducer;
