import React from "react";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface SectionProps {
  title: string;
  description: string;
  orderIndex?: string;
}

interface ScholarshipsTabProps {
  scholarshipData: {
    [key: string]: SectionProps | College[] | undefined;
  };
}

const ScholarshipSection: React.FC<SectionProps> = ({ title, description }) => {
  return (
    <section className="scholarship-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const ScholarshipsTab: React.FC<ScholarshipsTabProps> = ({
  scholarshipData,
}) => {
  const similarColleges = scholarshipData.similarColleges as College[];
  const keys = Object.keys(scholarshipData)
    .filter((key) => key.startsWith("key_") && scholarshipData[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (scholarshipData[a] as SectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (scholarshipData[b] as SectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            <div className="flex flex-col gap-5">
              {keys.map((key) => {
                const section = scholarshipData[key] as SectionProps;
                if (!section?.title || !section?.description) {
                  return null;
                }

                return (
                  <ScholarshipSection
                    key={key}
                    title={section.title}
                    description={section.description}
                  />
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-3/12 hidden lg:block">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 && (
          <div className="container mx-auto mt-8">
            <SimilarEngineeringColleges colleges={similarColleges} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScholarshipsTab;
