import React, { useState } from "react";
import AuthHeader from "../../registration/RevampedRegistration/components/AuthHeader";
import { Login, sendOtp } from "../../../../store/thunks/authThunks";
import { updateForm } from "../../../../store/slices/registrationFormSlice";
import { useForm } from "react-hook-form";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import OTPInput from "react-otp-input";
import "react-phone-input-2/lib/style.css";
import { useEffect } from "react";
import axios from "axios";
import { postCtaData } from "../../../lead-generation/leadGenThunk";
import config from "../../../../util/config";
interface PersonalInfoFormInputProps {
  mobileNumber: string;
  otp: string;
}

const RevampedLogin = () => {
  const [registered, setRegistered] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { width } = GetWindowDimensions();

  const { mobileNumber, otp } = useSelector(
    (state: RootState) => state.registrationFormSlice.data
  );

  const { otpSent, loading, isAuthenticated, error } = useSelector(
    (state: RootState) => state.auth
  );

  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
  const [otpInvalidError, setOtpInvalidError] = useState(null);
  const [otpValue, setOtp] = useState(otp);
  const [timeRemaining, setTimeRemaining] = useState<number>(60);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
    trigger,
  } = useForm<PersonalInfoFormInputProps>();

  const onSubmit = (data: any) => {
    debugger;
    dispatch(updateForm({ ...data, otp: otpValue }));
  };
  const onSubmitFinal = (data: any) => {
    window.location.href = "/";
  };
  const mobile = watch("mobileNumber");
  console.log("mobileNumber", mobile);

  useEffect(() => {
    const checkMobileNumber = async () => {
      if (mobile?.length === 10) {
        try {
          const response = await axios.get(
            config.apiUrl + `/api/auth/user/details/${mobile}`
          );
          if (response.status === 200 && response?.data?.data) {
            setRegistered(true);
          } else {
            setRegistered(false);
            console.log("Number not registered");
          }
        } catch (error) {
          setRegistered(false);
          console.log("Number not registered");
        }
      }
    };

    checkMobileNumber();
  }, [mobile]);

  const handleVerifyOtp = () => {
    let isLoginSuccess = true;
    // add logic for otp verify
    if (otpValue) {
      dispatch(Login({ mobile, otp: otpValue }))
        .unwrap()
        .then((status: any) => {
          if (state?.sourcePagePath && state?.leadPageName && state?.leadOn) {
            const leadPageName = state?.leadPageName;
            const leadOn = state?.leadOn;
            console.log("🎸🎸🎸", state?.sourcePagePath);
            navigate(state?.sourcePagePath || "/");
            dispatch(
              postCtaData({
                leadPageName,
                leadOn,
              })
            );
          } else if (state?.sourcePagePath) {
            navigate(state?.sourcePagePath || "/");
          } else if (isLoginSuccess) {
            const downloadHref = localStorage.getItem("downloadHref");
            const downloadFileName = localStorage.getItem("downloadFileName");
            const redirectPath =
              localStorage.getItem("redirectAfterLogin") || "/";

            localStorage.removeItem("downloadHref");
            localStorage.removeItem("downloadFileName");
            localStorage.removeItem("redirectAfterLogin");

            navigate(redirectPath, { replace: true });

            if (downloadHref && downloadFileName) {
              const link = document.createElement("a");
              link.href = downloadHref;
              link.download = downloadFileName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch((err: any) => {
          console.error("OTP verification failed:", err);
          setOtpInvalidError(err?.message);
        });
      // isLoginSuccess = true;
    }
    setIsVerifyOtp(!isVerifyOtp);
    // // Redirect to the originally requested page
    // const redirectPath = localStorage.getItem("redirectAfterLogin");

    // if (redirectPath) {
    //   localStorage.removeItem("redirectAfterLogin");
    //   navigate(redirectPath, { state: { downloadRequested: true } });
    // } else {
    //   navigate("/");
    // }
  };

  const handleSendOTP = () => {
    if (mobile.length === 10) {
      dispatch(sendOtp({ mobile }));
      setBtnDisable(true);
      setTimeRemaining(60);
    } else {
      console.log("Enter 10 digit Mobile number");
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeRemaining > 0 && otpSent && !isAuthenticated) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setBtnDisable(false);
    }

    return () => clearInterval(timer);
  }, [timeRemaining, otpSent, isAuthenticated]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    flag: "https://flagcdn.com/w40/in.png",
    code: "+91",
  });

  const countries = [
    { name: "India", flag: "https://flagcdn.com/w40/in.png", code: "+91" },
    {
      name: "United States",
      flag: "https://flagcdn.com/w40/us.png",
      code: "+1",
    },
    {
      name: "United Kingdom",
      flag: "https://flagcdn.com/w40/gb.png",
      code: "+44",
    },
    { name: "Germany", flag: "https://flagcdn.com/w40/de.png", code: "+49" },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectCountry = (country: any) => {
    setSelectedCountry(country);
    setDropdownOpen(false);
  };

  console.log("isOtpSent", otpSent);
  if (error) {
    console.log("OTP Error", error);
  }

  console.log("otpValue", otpValue);

  const handleChangeOTP = (otp: any) => {
    setOtp(otp);
  };
  return (
    <div
      className="relative flex flex-col bg-[#fff] bg-no-repeat bg-bottom min-h-screen"
      //   style={{
      //     backgroundImage: "url('../../../../images/authentication/auth-bg.svg')",
      //     backgroundSize: "contain",
      //   }}
    >
      {/* Auth Header */}
      <AuthHeader />

      {/* Main Content Section */}
      <div className="z-10 flex justify-center flex-1 p-4 md:items-center ">
        <div className="flex flex-col w-full max-w-5xl mx-auto gap-x-4 md:flex-row gap-y-4 md:items-start">
          {/* Information Section - Hidden on Mobile */}
          {/* <div className="hidden w-1/3 h-[40rem] p-6 bg-[#00c697] rounded-lg shadow-md md:block gap-4">
            <div className="flex items-center p-2 mb-4 space-x-4 bg-white rounded-md shadow max-w-max">
              <div className="flex flex-col items-center">
                <span className="text-lg font-semibold text-gray-800">4</span>
                <span className="text-sm text-gray-600">Universities</span>
              </div>

              <div className="h-8 border-l border-gray-400"></div>

              <div className="flex flex-col items-center">
                <span className="text-lg font-semibold text-gray-800">5</span>
                <span className="text-sm text-gray-600">Courses</span>
              </div>
            </div>
            <h2 className="mb-4 text-xl font-semibold text-white">
              Let’s help you find your dream Career
            </h2>
            <p className="text-white">
              Here you can include any important information or guidelines
              related to the registration process.
            </p>
          </div> */}

          <div
            className="sm:h-auto md:h-[35rem] p-6 bg-[#00c697] rounded-lg shadow-md md:block md:w-1/2 relative bg-bottom"
            style={{
              // backgroundImage:
              // "url('https://assets.admitkard.com/images/generic_banner.svg')",
              backgroundImage:
                "url('../../../../images/authentication/auth-bg.svg')",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* Small Card with Rows for Universities and Courses */}

            {/* <div className="flex items-center p-2 mb-4 space-x-4 bg-gray-100 rounded-md shadow max-w-max">
              <div className="flex flex-col items-center">
                <span className="text-3xl text-gray-800">400</span>
                <span className="text-sm text-gray-600">universities</span>
              </div>

              <div className="h-8 border-l border-gray-400"></div>

              <div className="flex flex-col items-center">
                <span className="text-3xl text-gray-800">103349</span>
                <span className="text-sm text-gray-600">courses</span>
              </div>
            </div> */}

            {/* Information Section Content */}
            <h2 className="mb-4 text-xl font-semibold text-white">
              Important Information
            </h2>
            <p className="text-white">
              Here you can include any important information or guidelines
              related to the registration process.
            </p>
          </div>

          {/* Stepper Section */}
          <div className="w-full p-6 bg-[#e6f9f5] rounded-lg shadow-md md:w-2/3 max-h-max md:min-h-0">
            <div className="flex justify-center">
              <h1 className="mb-4 text-2xl font-semibold text-[#113cc0]">
                Let's get started
              </h1>
            </div>
            <form
              className="flex flex-col items-center justify-center w-full gap-2 mb-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col gap-3 mb-2 md:flex-row xs:flex-col sm:flex md:flex-nowrap">
                <div className="w-auto sm:w-full">
                  <label
                    htmlFor="mobileNumber"
                    className="block mb-2 text-xs font-bold tracking-wide text-gray-700"
                  >
                    Mobile Number
                  </label>

                  {/* <Controller
                    name="mobileNumber"
                    control={control}
                    defaultValue="" // Set default value for the input
                    render={({ field: { onChange, value }, field }) => (
                      <PhoneInput
                        {...field}
                        country={"in"} // Default country
                        onlyCountries={["in"]}
                        countryCodeEditable={false} // Country code is not editable
                        disableDropdown={true}
                        value={value}
                        onChange={onChange} // Update the value in the form
                      />
                    )}
                  /> */}
                  <div className="flex flex-row gap-2">
                    <div className="relative inline-block text-left">
                      <button
                        // onClick={toggleDropdown}
                        type="button"
                        className="inline-flex items-center px-2 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50"
                      >
                        <img
                          src={selectedCountry.flag}
                          // alt={${selectedCountry.name} Flag}
                          className="w-6 h-4 mr-2 rounded-sm"
                        />
                        <span>{selectedCountry.code}</span>
                        <svg
                          className="w-8 h-8 ml-2 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {/* Dropdown */}
                      {dropdownOpen && (
                        <div className="absolute right-0 w-56 mt-2 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="py-1">
                            {countries.map((country: any) => (
                              <button
                                key={country.code}
                                onClick={() => selectCountry(country)}
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <img
                                  src={country.flag}
                                  // alt={${country?.name} Flag}
                                  className="w-6 h-4 mr-2 rounded-sm"
                                />
                                {country.name} ({country.code})
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <Controller
                    name="mobileNumber"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        country={"in"} // Set the default country
                        value={value}
                        onChange={onChange}
                        onlyCountries={["in"]}
                        countryCodeEditable={false}
                        disableSearchIcon // Disable the search icon in dropdown
                        // enableSearch={true} // Enable country search
                        // preferredCountries={["in", "us"]} // Show preferred countries at top of dropdown
                        // masks={{ in: "(..) ....." }} // Mask for the mobile number in India
                        inputProps={{
                          name: "mobileNumber",
                          required: true,
                          placeholder: "Mobile Number",
                          //   readOnly: false, // Phone number field is editable
                          autoFocus: true,
                          //   className:
                          //     "w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:none ml-10",
                          className:
                            "border-0 focus:border-transparent focus:ring-0 focus:outline-none w-full p-2 ml-10", // Remove focus border and outline
                        }}
                        containerStyle={{ width: "100%" }}
                        buttonStyle={{
                          backgroundColor: "white",
                          border: "none",
                          borderTopLeftRadius: "4px",
                          borderBottomLeftRadius: "4px",
                        }}
                        dropdownStyle={{
                          border: "1px solid #ccc",
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                        }}
                        disableDropdown={true}
                      />
                    )}
                  /> */}

                    <input
                      type="number"
                      id="mobileNumber"
                      className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none block w-full px-4 py-3 leading-tight text-gray-700 border-2 border-gray-200 rounded focus:outline-none focus:bg-white focus:border-[#113cc0] bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50"
                      placeholder="Mobile number"
                      {...register("mobileNumber", {
                        required: "Mobile number is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Mobile number must be exactly 10 digits",
                        },
                      })}
                      defaultValue={mobileNumber}
                      onChange={async (e) => {
                        setValue("mobileNumber", e.target.value);
                        await trigger("mobileNumber");
                      }}
                    />
                  </div>
                  {mobile?.length === 10 && !registered && (
                    <p className="text-xs italic text-red-600">
                      Number not registered, Please do register
                    </p>
                  )}
                  <p className="text-xs italic text-red-600">
                    {errors.mobileNumber && (
                      <p>{errors.mobileNumber.message}</p>
                    )}
                  </p>

                  {mobile?.length === 10 && registered && !otpSent && (
                    <p className="flex justify-end text-xs italic text-gray-600">
                      <button
                        className="text-nowrap px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={handleSendOTP}
                      >
                        Send OTP
                      </button>
                    </p>
                  )}
                  {otpSent && !isAuthenticated && (
                    <p className="flex justify-end text-xs italic text-gray-600">
                      <button
                        className="text-nowrap px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={handleSendOTP}
                        disabled={btnDisable}
                      >
                        {timeRemaining > 0
                          ? `Resend OTP in ${timeRemaining}s`
                          : "Resend OTP"}
                      </button>
                    </p>
                  )}
                </div>
              </div>

              {otpSent && !isAuthenticated && (
                <div className="flex justify-center mb-2 sm:justify-normal">
                  <OTPInput
                    value={otpValue}
                    onChange={handleChangeOTP}
                    numInputs={6}
                    renderSeparator={false}
                    renderInput={(props) => (
                      <input
                        {...props}
                        // id="otp"
                        className="mr-2"
                        // placeholder=""
                        // {...register("otp")}
                        // defaultValue={otp}
                      />
                    )}
                    inputStyle={{
                      border: "none",
                      justifyContent: "center",
                      borderRadius: "8px",
                      width: width > 700 ? "54px" : "34px",
                      height: width > 700 ? "54px" : "34px",
                      fontSize: "1.5rem",
                      fontWeight: 800,
                      backgroundColor: "#fff",
                      color: "#000",
                      display: "flex",
                      // caretColor: "blue",
                      outline: "none",
                    }}
                  />
                </div>
              )}

              {error && <h1>{error?.message}</h1>}
              {otpValue.length === 6 && otpSent && !isAuthenticated && (
                <button
                  type="button"
                  onClick={handleVerifyOtp}
                  className={`transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white ease-in-out ${
                    loading && "cursor-not-allowed opacity-50"
                  }`}
                  style={{ padding: "8px 18px" }}
                  disabled={loading}
                >
                  {loading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Login"
                  )}
                </button>
              )}
              <div>
                <span className="text-sm">Don't have an account? </span>
                <a
                  href="/secure/register"
                  className="text-[#113cc0] font-semibold bg-transparent hover:underline focus:outline-none text-sm"
                  // onClick={(e) => navigate("/secure/register")}
                >
                  Register{" "}
                </a>
              </div>
              <footer className="bg-transparent-800">
                <div className="flex flex-row">
                  {/* <div
                    className={`mr-2 w-5 h-5 border-2 rounded-md flex items-center justify-center cursor-pointer transition ${
                      isChecked ? "border-[#00c798]" : "border-gray-500"
                    }${
                      !isValid
                        ? "cursor-not-allowed opacity-50"
                        : "cursor-pointer"
                    }`}
                    onClick={isValid ? handleCheckboxChange : undefined}
                  >
                    {isValid && (
                      <>
                        {isChecked ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-[#00c798]"
                            size="sm"
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div> */}
                  <div className="text-sm">
                    By continuing, you agree to our{" "}
                    <a
                      href="/terms-condition"
                      className="font-semibold text-[#113cc0] underline-none hover:no-underline"
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="/policy"
                      className="font-semibold text-[#113cc0] underline-none hover:no-underline"
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>
                </div>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevampedLogin;
