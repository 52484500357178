export const getBlogOrNewsUrl = (blogOrNewsDetails: any) =>
  blogOrNewsDetails?.category?.toLocaleLowerCase() == "news"
    ? `/news/${blogOrNewsDetails?.slug?.toLocaleLowerCase()}`
    : `/blogs/${
        blogOrNewsDetails?.topic || "category"
      }/${blogOrNewsDetails?.slug?.toLocaleLowerCase()}`;

export const getTextFromHtml = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
