import Banner from "../../components/banner-section/Banner";
import Universities from "../../components/universities/Universities";
import WhyCollegeMentor from "../../components/why-college-mentor/WhyCollegeMentor";
import DidYouKnow from "../../components/did-you-know/DidYouKnowData";
import CareerAssessmentTest from "../../components/career-assessment-test/CareerAssessmentTest";
import DreamMentors from "../../components/dream-mentors/DreamMentors";
import DreamCareer from "../../components/dream-careers/DreamCareer";
import ComprehensiveTest from "../../components/comprehensive-test/ComprehensiveTest";
import { DreamColleges } from "./components/dream-colleges/DreamColleges";

import { RankPredictor } from "./components/college-and-rank-predictor/RankPredictor";
import Scholarships from "./components/Scholarships";
import { CollegesByDestination } from "./components/colleges-by-destination/CollegesByDestination";
import Blogs from "../../components/blogs/Blogs";
import useFloatItemAnimation from "./useFloatItemAnimation";
import Posts from "./components/posts/Posts";
import DreamCourses from "./components/DreamCourses";
import StudyAbroadOptions from "./components/StudyAbroadOptions";

function LandingPage() {
  const { checkpointRefs, animationItemRef, animatedItemProps, isPulse } =
    useFloatItemAnimation();

  return (
    <div className="main-section">
      <Banner
        animationCheckPointRef={(el) => {
          checkpointRefs.current[0] = el;
        }}
        animationItemRef={animationItemRef}
        animatedItemProps={animatedItemProps}
        isPulse={isPulse}
      />
      <Universities />
      <WhyCollegeMentor
        animationCheckPointRef={(el) => {
          checkpointRefs.current[1] = el;
        }}
      />
      <DidYouKnow />
      <CareerAssessmentTest
        animationCheckPointRef={(el) => {
          checkpointRefs.current[2] = el;
        }}
      />
      <DreamMentors
        animationCheckPointRef={(el) => {
          checkpointRefs.current[3] = el;
        }}
      />
      <DreamCareer
        animationCheckPointRef={(el) => {
          checkpointRefs.current[4] = el;
        }}
      />
      <DreamCourses
        animationCheckPointRef={(el) => {
          checkpointRefs.current[5] = el;
        }}
      />
      <ComprehensiveTest
        animationCheckPointRef={(el) => {
          checkpointRefs.current[6] = el;
        }}
      />
      <DreamColleges
        animationCheckPointRef={(el) => {
          checkpointRefs.current[7] = el;
        }}
      />
      <CollegesByDestination
        animationCheckPointRef={(el) => {
          checkpointRefs.current[8] = el;
        }}
      />
      <StudyAbroadOptions
        animationCheckPointRef={(el) => {
          checkpointRefs.current[9] = el;
        }}
      />
      <RankPredictor
        animationCheckPointRef={(el) => {
          checkpointRefs.current[10] = el;
        }}
      />
      <Scholarships
        animationCheckPointRef={(el) => {
          checkpointRefs.current[11] = el;
        }}
      />
      {/* <BlogsAndArticles /> */}
      <Blogs />
      <Posts />
      {/* <DownloadApp/> */}
    </div>
  );
}
export default LandingPage;
