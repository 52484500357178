import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { useHighlight } from "./HighlightContext"; // Adjust the path as needed

const COMPARE_COLS = window.innerWidth < 768 ? 2 : 4;

const SECTIONS_AND_FIELDS = [
  {
    key: "overview",
    title: "Overview",
    fields: [
      "Location",
      "Institute Type",
      "Campus Size (i) & Type",
      "Study Mode",
      "Diversity",
      "Graduation OutComes (i) or Notable Alumini ",
      "Approval",
      "Infrastructure",
    ],
  },
  {
    key: "rankingsAndAccreditations",
    title: "Rankings & Accreditations",
    fields: [
      "NIRF Overall Ranking",
      "International Ranking India",
      "International World Rankings ",
      "Career360 University",
      "India Today",
      "ARIIA",
      "Others",
      "Accreditation NACC & NBA",
    ],
  },
  {
    key: "placementDetails",
    title: "Placement Offer",
    fields: [
      "Super Dream Package",
      "Dream Package",
      "Medium Package",
      "Average Package",
    ],
  },
  {
    key: "admissions",
    title: "Admission & Eligibility",
    fields: [
      "Accepted Exams",
      "Acceptance Rate",
      "Exams & Counselling",
      "Eligibility",
      "Applications Open",
      "Scholarships",
    ],
  },
  {
    key: "streamsAndCourses",
    title: "Streams & Courses Fee",
    fields: ["Engineering", "Management", "Law", "Arts"],
  },
  {
    key: "internationalTieUp",
    title: "International Tie-Up",
    fields: ["Semester Abroad Programme", "Dual Degree", "Twining Programme"],
  },
];

const ComparisonDetailsTable = () => {
  const { highlightField } = useHighlight();

  const colleges = useSelector(
    (state: RootState) => state.collegeCompare.comparisonColleges
  );
  console.log("ComparisonDetailsTable rendered", { highlightField });

  if (colleges.length === 0) return null;

  return (
    <div className="flex flex-col gap-4">
      {SECTIONS_AND_FIELDS.map((section) => {
        const rows: { title: string; data: string[] }[] = [];

        section.fields.map((field) => {
          const row = [];
          for (let i = 0; i < COMPARE_COLS; i++) {
            if (i < colleges.length) {
              const data = colleges[i][section.key]?.fields?.find(
                (item: { key: string; value: string }) =>
                  item.key.trim().toLowerCase() === field.trim().toLowerCase()
              )?.value;

              if (typeof data === "string" || typeof data === "undefined")
                row.push(data || "NA");
              else
                row.push(
                  data
                    .map((exam: { examName: string }) => exam.examName)
                    .join(" / ")
                );
            } else row.push("");
          }
          rows.push({ title: field, data: row });
        });

        const buttonRow = [];
        for (let i = 0; i < COMPARE_COLS; i++) {
          if (i < colleges.length) {
            buttonRow.push("Read more");
          } else buttonRow.push("");
        }
        rows.push({ title: "button", data: buttonRow });

        if (COMPARE_COLS === 2)
          return (
            <MobileSection
              title={section.title}
              rows={rows}
              highlightField={highlightField}
              colleges={colleges}
            />
          );
        else
          return (
            <DesktopSection
              title={section.title}
              rows={rows}
              highlightField={highlightField}
              colleges={colleges}
            />
          );
      })}
    </div>
  );
};
export default ComparisonDetailsTable;
interface ISectionProps {
  title: string;
  rows: { title: string; data: string[] }[];
  colleges: any[];
}

const DesktopSection: FC<ISectionProps & { highlightField: string | null }> = ({
  title,
  rows,
  highlightField,
  colleges,
}) => {
  const [expanded, setExpanded] = useState(true);
  console.log("DesktopSection rendered", { title, highlightField });

  return (
    <div>
      <div
        className="flex items-center justify-between border-t border-grey-3 p-4 cursor-pointer"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span className="text-xl font-bold">{title}</span>
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </div>
      {expanded && (
        <div className="flex flex-col">
          {rows.map((row) => (
            <div
              className={`flex gap-4 border-t border-grey-3 p-4 odd:bg-white ${
                highlightField ===
                `field-${row.title.replace(/\s+/g, "-").toLowerCase()}`
                  ? "bg-red-500"
                  : ""
              }`}
              key={row.title}
            >
              <div id={`${row.title}`} className="flex-1 text-base font-bold">
                {row.title !== "button" && row.title}
              </div>
              {row.data.map((item, index) =>
                row.title === "button" && item.trim().length > 0 ? (
                  <div className="flex-1">
                    <Link
                      to={`/college-details/${colleges[index]?.slug}`}
                      className="mx-auto w-fit flex px-6 py-2 text-white bg-blue rounded-full items-center"
                    >
                      {item}
                      <IoMdArrowForward className="ms-2 font-bold arrow-icon shrink-0" />
                    </Link>
                  </div>
                ) : (
                  <div className="flex-1 text-base text-grey text-center">
                    {item}
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const MobileSection: FC<ISectionProps & { highlightField: string | null }> = ({
  title,
  rows,
  highlightField,
  colleges,
}) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div>
      <div
        className="flex items-center justify-between p-4 bg-[#E5E5E5] cursor-pointer"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span className="text-xl font-bold">{title}</span>
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </div>
      {expanded && (
        <div className="flex flex-col py-4">
          {rows.map((row) => (
            <div
              id={`field-${row.title.replace(/\s+/g, "-").toLowerCase()}`}
              className={`flex ${
                highlightField ===
                `field-${row.title.replace(/\s+/g, "-").toLowerCase()}`
                  ? "bg-red-500"
                  : ""
              }`}
              key={row.title}
            >
              {row.data.map((item, index) => (
                <div className="flex-1 p-2 odd:border-r-2 border-grey-3">
                  {row.title === "button" && item.trim().length > 0 ? (
                    <div className="flex-1">
                      <Link
                        to={`/college-details/${colleges[index]?.slug}`}
                        className="mx-auto w-fit flex px-6 py-2 text-white bg-blue rounded-full items-center"
                      >
                        {item}
                        <IoMdArrowForward className="ms-2 font-bold arrow-icon shrink-0" />
                      </Link>
                    </div>
                  ) : (
                    <>
                      <div className="text-base font-bold">
                        {row.title !== "button" && row.title}
                      </div>
                      <div className="text-base text-grey">{item}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
