import React, { useState, useRef, useEffect, useMemo } from "react";
import "swiper/css";
import "swiper/css/bundle";
import "./ExploreOccupation.scss";
import OnlineCourseCard from "./ExploreOccupation/OnlineCourseCard";
import OtherCards from "./ExploreOccupation/OtherCards";
import CustomSwiper from "../../components/Slider/CustomSwiper";

import LeadGeneration from "../LeadGeneration";
interface Iprops {
  passion: any[];
  popular: any[];
  privilaged: any[];
  onlineDegrees: any[]; // Assuming a separate interface for OnlineDegree
}
const data: Iprops = {
  passion: [
    {
      title: "Creative interest",
      description: "Creative Designing and Making your own Rules",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Passional/artistic.png",
      filterKey: "creative",
    },
    {
      title: "Realistic interest",
      description: "Practical Hands on Problems and solutions",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Passional/realistic.png",
      filterKey: "realistic",
    },
    {
      title: "Investigative interest",
      description: "Ideas, Thinking and Figuring Things out",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Passional/investigative.png",
      filterKey: "investigative",
    },
    {
      title: "Social interest",
      description: "Helping People. Teaching and Talking",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Passional/social.png",
      filterKey: "social",
    },
    {
      title: "Conventional interest",
      description: "Creative Designing and Making your own Rules",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Passional/conventional.png",
      filterKey: "enterprising",
    },
    {
      title: "Enterprising interest",
      description: "Practical Hands on Problems and solutions",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Passional/Enterprise.png",
      filterKey: "conventional",
    },
  ],

  popular: [
    {
      title: "Space Science & Airline",
      description: "Travel, exploration & innovation",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Space%20Science_Airline%20copy.jpg",
      filterKey: "space_science_airline",
    },
    {
      title: "Sports & Physical Education",
      description: "Pride, unity, competition & fitness",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Sports_Physical%20Education%20copy.jpg",
      filterKey: "sports_physical_education",
    },
    {
      title: "Beauty, Welness & Health",
      description: "Radiance, Nourishment & Resilience",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Beauty%20Wellness_Health%20copy.jpg",
      filterKey: "beauty_wellness_health",
    },
    {
      title: "Hospitality, Travel & Tourism",
      description: "Experience, Adventure & Connection",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Hospitality%20Travel_Tourism%20copy.jpg",
      filterKey: "hospitality_travel_tourism",
    },
    {
      title: "Forestry & Wildlife",
      description: "Conservation, Biodiversity & Sustainability",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Forestry_Wildlife%20copy.jpg",
      filterKey: "forestry_wildlife",
    },
    {
      title: "Special Education & Rehabilitation",
      description: "Empowerment, Inclusion & Support",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Special%20Education_Rehabilitation%20copy.jpg",
      filterKey: "special_education_rehabilitation",
    },
  ],

  privilaged: [
    {
      title: "Indian AIRFORCE",
      description: "Sample Text",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Indian%20Airforce%20copy.jpg",
      filterKey: "indian_airforce",
    },
    {
      title: "Indian NAVY",
      description: "Sample Text",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Indian%20NAVY%20copy.jpg",
      filterKey: "indian_navy",
    },
    {
      title: "Indian ARMY",
      description: "Sample Text",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Indian%20ARMY%20copy.jpg",
      filterKey: "indian_army",
    },
    {
      title: "National Cadet Corps (NCC)",
      description: "Sample Text",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/National%20Cadet%20Corps%20copy.jpg",
      filterKey: "national_cadet_corps",
    },
    {
      title: "Indian ARMY",
      description: "Sample Text",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/Indian%20ARMY%20copy.jpg",
      filterKey: "indian_army",
    },
    {
      title: "National Cadet Corps (NCC)",
      description: "Sample Text",
      image:
        "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Explore%20occupations/National%20Cadet%20Corps%20copy.jpg",
      filterKey: "national_cadet_corps",
    },
  ],

  onlineDegrees: [
    {
      logo: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/online-course/amity.png",
      title: "Amity University Online",
      college: "Amity-university",
      approvedBy: "UGC",
      ownership: "Private",
      program: "Online MBA",
      feesYearly: "Rs. 45,000",
    },
    {
      logo: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/online-course/manipal.png",
      title: "Manipal University Online",
      college: "Manipal-university",
      approvedBy: "UGC",
      ownership: "Private",
      program: "Online MBA",
      feesYearly: "Rs. 45,000",
    },
    {
      logo: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/online-course/amrita.png",
      title: "Amrita Vishwa Vidyapeetham Online",
      college: "Amrita-vishwa-vidyapeetham",
      approvedBy: "UGC",
      ownership: "Private",
      program: "Online MBA",
      feesYearly: "Rs. 45,000",
    },
    {
      logo: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/online-course/lpu.png",
      title: "Lovely Professional University Online",
      college: "Lovely-professional-university",
      approvedBy: "UGC",
      ownership: "Private",
      program: "Online MBA",
      feesYearly: "Rs. 45,000",
    },
    {
      logo: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/online-course/anna.png",
      title: "Anna University Online",
      college: "Anna-university",
      approvedBy: "UGC",
      ownership: "Private",
      program: "Online MBA",
      feesYearly: "Rs. 45,000",
    },
    {
      logo: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/online-course/bharati-vidyapeeth.png",
      title: "Bharati Vidyapeeth Online",
      college: "Bharati-university",
      approvedBy: "UGC",
      ownership: "Private",
      program: "Online MBA",
      feesYearly: "Rs. 45,000",
    },
  ],
};

const ExploreOccupation: React.FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({
      marginLeft: 0,
      width: 0,
    });
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedUniversityLogo, setSelectedUniversityLogo] = useState("");

  const setApplyFormPopupLogo = (image: string) => {
    setSelectedUniversityLogo(image);
  };
  const tabRef = useRef<HTMLButtonElement[]>([]);

  const tabs = [
    {
      name: "Passional Courses",
      content: data.passion.map((data) => (
        <OtherCards {...data} name={"passion"} />
      )),
    },
    {
      name: "Popular Courses",
      content: data.popular.map((data) => (
        <OtherCards {...data} name={"popular"} />
      )),
    },
    {
      name: "Top Priviliged Courses",
      content: data.privilaged.map((data) => (
        <OtherCards {...data} name={"privilaged"} />
      )),
    },
    {
      name: "Online Degrees",
      content: data.onlineDegrees.map((data) => (
        <OnlineCourseCard
          {...data}
          handlePopup={() => setIsPopupVisible(true)}
          setPopupLogo={setApplyFormPopupLogo}
        />
      )),
    },
  ];
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
    setIndicatorToActiveTab(index);
  };

  const handleTabMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabRect = event.currentTarget.getBoundingClientRect();
    const containerRect =
      event.currentTarget.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;

    setTabIndicatorStyle({
      marginLeft: `${distanceTabStartToContainerStart + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  const handleTabMouseLeave = () => {
    setIndicatorToActiveTab(activeTabIndex);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;

    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;

    setTabIndicatorStyle({
      marginLeft: `${distanceTabStartToContainerStart + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    setIndicatorToActiveTab(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <>
      <div className="py-16 relative max-md:py-3">
        <div className="container mx-auto">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
              <h3 className="text-3xl md:text-[40px] font-bold lg:leading-[58px] text-black m-0">
                Explore Occupations by interest area
              </h3>
            </div>

            <div className="flex flex-col gap-7 ">
              <div className="relative">
                <div
                  onMouseLeave={handleTabMouseLeave}
                  className="flex tab-container-occupation gap-4 lg:gap-20 "
                >
                  {tabs.map((tab, index) => (
                    <button
                      ref={(el) => (tabRef.current[index] = el!)}
                      onClick={() => handleTabClick(index)}
                      onMouseEnter={handleTabMouseEnter}
                      className={`py-2 text-xs font-semibold leading-[1.375rem] font-open-sans md:text-xl hover:text-[#173CBA] transition-all duration-300 ease-in-out 
 ${
   activeTabIndex == index
     ? " text-[#173CBA] font-bold"
     : "font-normal text-[#A8A8A8]"
 }`}
                    >
                      {tab.name}
                    </button>
                  ))}
                  <div
                    className="absolute bottom-[-4px] h-[6px] bg-[#00C798]  rounded-lg transition-all duration-300 ease-in-out"
                    style={tabIndicatorStyle}
                  />
                </div>
              </div>
            </div>

            <div className="ExploreMore-occupation w-full mt-2">
              <CustomSwiper
                items={tabs[activeTabIndex].content}
                navigation={"bottom"}
                autoplay={true}
                breakpoints={{
                  320: { slidesPerView: 1, spaceBetween: 20 },
                  768: { slidesPerView: 2, spaceBetween: 20 },
                  1024: { slidesPerView: 3, spaceBetween: 20 },
                  1280: { slidesPerView: 4, spaceBetween: 30 },
                }}
                progressBar={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploreOccupation;
