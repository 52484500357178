import React, { useState } from "react";

interface AccordionItem {
  question: string;
  answer: string;
}

interface KnowledgeHubProps {
  faqData: Record<
    "destination" | "service" | "examsAndScholarships" | "studentLife",
    AccordionItem[]
  >;
}

const tabs = [
  { key: "destination", label: "Destinations" },
  { key: "service", label: "Services" },
  { key: "examsAndScholarships", label: "Exams & Scholarships" },
  { key: "studentLife", label: "Student Life" },
];

// Mock data
const studyabroadFaqData: KnowledgeHubProps["faqData"] = {
  destination: [
    {
      question: "Which country is best for my specific field of study?",
      answer:
        "The best destination can vary depending on your field. For instance, the USA and UK are strong in business and engineering, while Switzerland is known for its hospitality and finance programs. We can help identify the best country based on your academic and career goals.",
    },
    {
      question:
        "What factors should I consider when choosing a study abroad destination?",
      answer:
        "Consider factors such as your budget, the quality of education, cultural preferences, language, and career opportunities.",
    },
    {
      question:
        "How does the cost of studying and living vary between different countries?",
      answer:
        "Costs can differ greatly between countries. For instance, tuition and living costs in the USA are generally higher compared to European countries like Germany, where many universities offer free tuition.",
    },
    {
      question:
        "What are the visa requirements for studying in different countries?",
      answer:
        "Visa requirements vary by country. Common documents needed include proof of admission, financial sufficiency, and valid health insurance.",
    },
    {
      question:
        "How does the quality of life in different study destinations compare?",
      answer:
        "Quality of life is influenced by factors such as healthcare, safety, transportation, and cultural inclusivity. Scandinavian countries rank high in these aspects.",
    },
    {
      question:
        "Are there opportunities for international students to work while studying in different countries?",
      answer:
        "Yes, many countries like Canada, Australia, and Germany allow students to work part-time while studying.",
    },
  ],
  service: [
    {
      question: "What services do you provide?",
      answer:
        "We offer guidance on university applications, visa processing, scholarship assistance, and career counseling.",
    },
    {
      question: "How do I access your services?",
      answer:
        "You can access our services through our website, by scheduling a consultation, or visiting our local office.",
    },
    {
      question: "Is there a fee for your services?",
      answer:
        "Many of our services are free, while certain premium services, such as personalized counseling, may incur a fee.",
    },
    {
      question: "Can you help with finding accommodation abroad?",
      answer:
        "Yes, we assist students in finding suitable accommodations, including university housing, shared apartments, or private rentals.",
    },
    {
      question: "Do you offer support after admission?",
      answer:
        "Yes, we provide post-admission support, including guidance on pre-departure preparations and settling in abroad.",
    },
    {
      question: "What are the benefits of using your services?",
      answer:
        "Our expertise saves you time and effort, and we ensure you make informed decisions at every step of your study abroad journey.",
    },
  ],
  examsAndScholarships: [
    {
      question: "What exams are required for studying abroad?",
      answer:
        "Common exams include IELTS, TOEFL, GRE, GMAT, SAT, and ACT, depending on the country and course.",
    },
    {
      question: "How do I prepare for these exams?",
      answer:
        "Start early, use official preparation materials, and consider enrolling in coaching classes if needed.",
    },
    {
      question:
        "What are the available scholarships for international students?",
      answer:
        "Many universities and governments offer scholarships, such as Fulbright, Chevening, and Erasmus Mundus.",
    },
    {
      question: "How can I increase my chances of getting a scholarship?",
      answer:
        "Maintain a strong academic record, write compelling essays, and apply early to multiple opportunities.",
    },
    {
      question: "Are there scholarships specific to certain fields of study?",
      answer:
        "Yes, many scholarships target specific fields like STEM, arts, or social sciences.",
    },
    {
      question: "What is the process for applying for scholarships?",
      answer:
        "Research scholarships, gather required documents, write essays, and submit applications before the deadline.",
    },
  ],
  studentLife: [
    {
      question: "What is student life like abroad?",
      answer:
        "Student life abroad is a mix of academic learning, cultural exploration, and personal growth.",
    },
    {
      question: "How can I make friends in a new country?",
      answer:
        "Participate in campus events, join clubs, and engage with fellow students and locals.",
    },
    {
      question: "What are some tips for managing finances as a student?",
      answer:
        "Create a budget, track expenses, and look for part-time job opportunities if permitted.",
    },
    {
      question:
        "How can I maintain a healthy work-life balance while studying?",
      answer:
        "Plan your schedule, prioritize tasks, and set aside time for relaxation and hobbies.",
    },
    {
      question: "What should I know about cultural differences?",
      answer:
        "Research the local culture, respect traditions, and keep an open mind to adapt smoothly.",
    },
    {
      question: "Are there support systems for international students?",
      answer:
        "Yes, most universities offer support services, including counseling, mentorship, and international student associations.",
    },
  ],
};

const KnowledgeHub: React.FC = () => {
  const [activeTab, setActiveTab] =
    useState<keyof KnowledgeHubProps["faqData"]>("destination");
  const [activeAccordion, setActiveAccordion] = useState<number>(0);

  const handleAccordionClick = (index: number) => {
    setActiveAccordion(index === activeAccordion ? -1 : index);
  };

  const handleTabChange = (tabKey: keyof KnowledgeHubProps["faqData"]) => {
    setActiveTab(tabKey);
    setActiveAccordion(0);
  };

  const getAccordionData = () => {
    return studyabroadFaqData[activeTab] || [];
  };

  return (
    <section className="py-10 bg-[#EEF2FF]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-10 items-center">
          <div className="lg:w-3/5 flex flex-col">
            <div className="flex flex-col gap-6">
              <h3 className="text-4xl lg:text-5xl font-bold text-blue">
                Knowledge Hub (FAQ's)
              </h3>

              <div className="flex flex-wrap md:flex-row gap-4">
                {tabs.map((tab) => (
                  <button
                    key={tab.key}
                    className={`px-4 py-2 rounded-full text-sm font-medium ${
                      activeTab === tab.key
                        ? "bg-blue-900 text-white"
                        : "bg-transparent border border-blue-900 text-blue-900"
                    }`}
                    onClick={() =>
                      handleTabChange(
                        tab.key as keyof KnowledgeHubProps["faqData"]
                      )
                    }
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-12">
              {getAccordionData().map((item: AccordionItem, index: number) => (
                <div key={index} className="rounded-lg overflow-hidden">
                  <button
                    className={`w-full flex gap-3.5 items-center text-sm font-semibold text-black bg-transparent`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <span className="flex">
                      {activeAccordion === index ? (
                        <span className="text-xl">
                          <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="23"
                              height="23"
                              rx="3"
                              fill="#173CBA"
                            />
                            <path
                              d="M5 12H17"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span className="text-xl">
                          <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="23"
                              height="23"
                              rx="3"
                              fill="#173CBA"
                            />
                            <path
                              d="M5 12H17"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M11 18L11 6"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </span>
                      )}
                    </span>
                    <span className="text-lg font-medium text-left text-black">
                      {item.question}
                    </span>
                  </button>
                  {activeAccordion === index && (
                    <div className="p-4 text-black text-sm">
                      <p>{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="lg:w-2/5 flex justify-center">
            <div className="w-full h-full">
              <img
                src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/study-abroad-knowledgehub.png"
                alt="KnowledgeHubImage"
                className="w-full h-auto rounded-lg shadow-md object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KnowledgeHub;
