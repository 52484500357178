import React, { useState, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Scholarships.css';
import { useNavigate } from 'react-router-dom';
const images: string[] = [
  '/images/Scholarships/Education2.webp',
  '/images/Scholarships/Merits1.webp',
  '/images/Scholarships/Girls.webp',
  '/images/Scholarships/Sports1.webp',
  '/images/Scholarships/Minorities.webp',
  '/images/Scholarships/International.webp',
];

const paragraphs: string[] = [
  "Scholarship-Education loan",
  "Scholarship-Merit based",
  "Scholarship-For girls",
  "Scholarship-Sports talent",
  "Scholarship-Minorities",
  "Scholarship-International"
];

const paragraphs1: string[] = [
  "Secure your academic future with tailored education loans, covering tuition, books, and living expenses specific to conditions. These loans ease financial stress, allowing you to focus on studies with flexible repayment options.",
  "Merit-based scholarships reward academic excellence, providing financial aid to high-achieving students under conditions. These scholarships cover tuition and other expenses, supporting outstanding academic performance.",
  "Scholarships for girls support their educational journey by providing financial aid. Promoting gender equality, these scholarships help female students overcome barriers, fostering educated and capable women.",
  "Sports talent scholarships offer financial support to students excelling in sports, covering educational expenses. They allow students to balance academics and athletics, nurturing future sports professionals and leaders.",
  "Minority scholarships offer financial support to students from underrepresented groups, covering tuition and essential expenses. These scholarships promote diversity in higher education, helping students focus on their studies and achieve their academic goals.",
  "International scholarships offer financial support for studying abroad, covering tuition, travel, and living expenses. They encourage cultural exchange and academic enrichment, enhancing global perspectives and career prospects."
];

// Ensure both arrays are the same length
if (images.length !== paragraphs.length) {
  console.error("Images and paragraphs arrays must be of the same length.");
}

const transition = { duration: 0.7, ease: "easeInOut" };

const Scholarships = React.memo(
  ({
    animationCheckPointRef,
  }: {
    animationCheckPointRef?: (el: HTMLDivElement | null) => void;
  }) => {
    const [activeImage, setActiveImage] = useState<number>(0); // Start with the first image
    const [prevImage, setPrevImage] = useState<number | null>(null); // Previous image is initially null
    const [direction, setDirection] = useState<number>(0);
    const navigate = useNavigate();


    const handleButtonClick = useCallback(
      (index: number) => {
        const newIndex = index;
        setDirection(newIndex < activeImage ? -1 : 1);
        setPrevImage(activeImage);
        setActiveImage(newIndex);
      },
      [activeImage]
    );
    console.log(activeImage);
    // Memoize the images and paragraphs to prevent unnecessary recalculations
    const memoizedImages = useMemo(() => images, []);
    const memoizedParagraphs = useMemo(() => paragraphs, []);
    const memoizedParagraphs1 = useMemo(() => paragraphs1, []);

    return (
      <div className="flex py-8 md:py-24 relative bg-[#F4F7FB]">
        <div className="hp-panel-3 w-full flex flex-col-reverse lg:flex-row">
          <div className={`slider-section ${activeImage > 2 ? "justify-center lg:justify-end translate-x-0" : "justify-end  translate-x-16 md:translate-x-0"} flex w-full scale-[50%] md:scale-[75%] lg:scale-100 lg:w-1/2`}>
            <div className="image-slider">
              {/* Static Front Card */}
              <div className="front-card">
                <AnimatePresence initial={false} custom={direction}>
                  {/* Outgoing Image */}
                  {prevImage !== null && (
                    <motion.div
                      key={`prev-${prevImage}`}
                      initial={{ opacity: 1 }} // Keep opacity 1
                      animate={{ opacity: 0.5 }} // Fade out slower
                      exit={{ opacity: 0 }} // Finally disappear
                      transition={{ duration: 0.7, ease: "easeInOut" }} // Match the duration with transition
                      className="front-card-image"
                      style={{
                        backgroundImage: `url(${memoizedImages[prevImage]})`,
                        zIndex: 1 // Keep a lower z-index
                      }}
                    >
                      <h4 className="image-heading">
                        {memoizedParagraphs[prevImage]}
                      </h4>
                    </motion.div>
                  )}
                  {/* Incoming Image */}
                  <motion.div
                    key={activeImage}
                    initial={{
                      x: direction === -1 ? -300 : 300,
                      opacity: 0
                    }}
                    animate={{
                      x: 0,
                      opacity: 1
                    }}
                    transition={{ duration: 0.7, ease: "easeInOut" }} // Ensure same duration for smoothness
                    className="front-card-image"
                    style={{
                      backgroundImage: `url(${memoizedImages[activeImage]})`,
                      zIndex: 2 // Higher z-index to overlap outgoing
                    }}
                  >
                    <h4 className="image-heading font-bold">
                      {memoizedParagraphs[activeImage]}
                    </h4>
                  </motion.div>
                </AnimatePresence>
              </div>

              {/* Other Cards */}
              {memoizedImages.map((image, index) => (
                <div
                  key={index}
                  className={`image-card ${index <= activeImage ? 'active' : 'stacked'
                    }`}
                  style={{
                    '--index': activeImage - index,
                    '--zIndex': images.length - index,
                    backgroundImage: `url(${image})`
                  } as React.CSSProperties} // Type for inline styles
                >
                  <h4 className="image-heading font-bold">
                    {memoizedParagraphs[index]}
                  </h4>
                </div>
              ))}
            </div>
          </div>
          <div className="content-container flex w-full lg:w-1/2 justify-center px-1 md:px-0 lg:pl-8">
            <div className="content-inner">
              <div className="flex justify-center items-center">

                {animationCheckPointRef && (
                  <div
                    className="hidden md:block -translate-x-12 h-16 w-16"
                    ref={animationCheckPointRef}
                  ></div>
                )}

                <h3 className="text-3xl font-extrabold text-[#231F40] scholar">Scholarships and Loans</h3>
              </div>
              <h4 className="text-2xl font-bold text-[#231F40] subheading">
                {memoizedParagraphs[activeImage]}
              </h4>
              <div className="buttons">
                {memoizedImages.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handleButtonClick(index)}
                    className={index === activeImage ? 'active' : ''}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <p className='text-[#6B7280] paragraph md:mx-24 mb-4 '>
                {memoizedParagraphs1[activeImage]}
              </p>
              <div className='text-[#6B7280] font-bold'>
                Learn More
                <a href="/scholarships" className="ml-2 cta-button">
                  <span className=" arrow"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

export default Scholarships;
