import React, { useEffect, useState } from "react";
import { TestimonialCard } from "./TestimonialCard";

interface TestimonialData {
  name: string;
  university: string;
  imageSrc1: string;
  imageSrc2: string;
}

const testimonials: TestimonialData[] = [
  {
    name: "Arjun S.",
    university: "University of Edinburgh",
    imageSrc1: "/images/testimonials-student1.jpg",
    imageSrc2: "/images/testimonials-clg1.jpg",
  },
  {
    name: "Priya .M",
    university: "R.V university Bangalore",
    imageSrc1: "/images/testimonials-student2.jpg",
    imageSrc2: "/images/testimonials-clg2.jpg",
  },
  {
    name: "Ravi K.",
    university: "Srm University",
    imageSrc1: "/images/testimonials-student3.jpg",
    imageSrc2: "/images/testimonials-clg3.jpg",
  },
  {
    name: "Mrs. Sharma",
    university: "Amrita University",
    imageSrc1: "/images/testimonials-student4.jpg",
    imageSrc2: "/images/testimonials-clg4.jpg",
  },
  {
    name: "Mr. Verma,Parent of Maya V.,",
    university: "University of Toronto",
    imageSrc1: "/images/testimonials-student5.jpg",
    imageSrc2: "/images/testimonials-clg5.jpg",
  },
  {
    name: "Mrs. Gupta,Parent of Aarav G.,",
    university: "Thapar University",
    imageSrc1: "/images/testimonials-student6.jpg",
    imageSrc2: "/images/testimonials-clg6.jpg",
  },
];

const Testimonials: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Change image every 2 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);
  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div className="flex flex-col items-center w-full bg-[#FFF9E5] max-md:px-5 max-md:max-w-full mb-7 mt-10">
      <div className="flex flex-col items-center container px-[100px] py-20 min-max-md:px-10 max-md:px-2 min-max-lg:px-10 max-md:max-w-full">
        <div className="md:w-[708px] max-md:w-[400px]">
          <h2 className="text-4xl font-bold text-center text-[#173CBA]  ">
            <span className="font-light">High Praise From our Lovely</span>
            <br />
            <span className="text-[#0C9]">Students & Parents</span>
          </h2>
        </div>
        <div className="md:w-[854px] mt-4 min-max-md:w-full min-max-lg:w-full max-md:w-full">
          <p className=" text-base  text-center text-black ">
            College Mentor made my dream of studying abroad a reality. From the
            application process to receiving my acceptance letter, their
            guidance was invaluable. The personalized support helped me navigate
            the complexities of international admissions with confidence.
          </p>
        </div>

        <TestimonialCard {...currentTestimonial} />

        <div className="flex justify-center mt-5">
          {testimonials.map((_, index) => (
            <span
              key={index}
              className={`h-3 w-3 mx-2 rounded-full ${
                index === currentIndex ? "bg-[#294086]" : "bg-[#0C9]"
              } cursor-pointer`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
