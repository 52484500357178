import React from "react";

const SclorshipObjective = () => {
  return (
    <div className="px-20 py-10 w-full bg-[#FFF9E5] max-lg:px-7 max-lg:w-full my-6">
      <div className="flex gap-5 max-lg:flex-col-reverse">
        <div className="flex flex-col w-[51%] max-lg:ml-0 max-lg:w-full">
          <img
            src="https://cdncollegementor.blob.core.windows.net/cm-scholarship/scholorship-objective.jpg"
            alt="5 kinds standing together with a smile"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-lg:mt-10 max-lg:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 ml-5 w-[49%] max-lg:ml-0 max-lg:w-full my-auto leading-[30px]">
          <div>
            <h2 className="text-xl font-bold leading-[48px]">Objective</h2>
            <p>
            Identifying the right talent to provide scholarships for various category students of class 12 and to promote access high quality education in India. To make all the Indian youth future ready College Mentor scholarship test aims to provide
            </p>
            <p>
              
              <strong className="text-[#173CBA] lg:text-[24px]">
                Total Prize Pool of ₹1 Crore.
              </strong>
            </p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Vision</h2>
            <p>
            To make Indian students future ready and a belief that education serves as a catalyst for the growth of the student. With this scholarship we at College Mentor aim to inspire students to dream big, creating scientific temper and educate them to achieve their potential. Through our services we want to educate the students to achieve their potential to lead the nation with integrity and innovation. 
            </p>
          </div>
          <div>
            <h2 className="text-xl font-bold">
              Making India a Developed Nation by 2047
            </h2>
            <p>
              India's youth is its greatest asset. As they shape families and
              society over the next 25-30 years, their involvement in national
              development plans is essential.
            </p>
            <p>Your influence will guide this transformative journey.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SclorshipObjective;
