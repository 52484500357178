import React from "react";
import PhDStudents from "../research/PhDStudents";
import StudentsProjects from "../research/StudentsProjects";
import FundedProjects from "../research/FundedProjects";
import LatestUpdates from "../research/ResearchLatestUpdates";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";
import ContentMapping from "../research/ContentMaping";

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface SectionProps {
  description: string;
  title: string;
  array?: { description: string; orderIndex: string; title: string }[];
  orderIndex?: string;
}

interface ResearchTabProps {
  data: {
    [key: string]: SectionProps | College[] | undefined;
  };
}

const ResearchTab: React.FC<ResearchTabProps> = ({ data }) => {
  const similarColleges = data.similarColleges as College[] | undefined;
  const keys = Object.keys(data)
    .filter((key) => key.startsWith("key_") && data[key])
    .sort((a, b) => {
      const aOrder = parseInt((data[a] as SectionProps)?.orderIndex || "0", 10);
      const bOrder = parseInt((data[b] as SectionProps)?.orderIndex || "0", 10);
      return aOrder - bOrder;
    });

  return (
    <div className="bg-gray-100 p-6">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            {keys.map((key) => {
              const section = data[key] as SectionProps;

              if (!section?.title || !section?.description) {
                return null;
              }

              if (section.array?.length) {
                if (key === "key_6") {
                  return (
                    <FundedProjects
                      key={key}
                      title={section.title}
                      array={section.array}
                    />
                  );
                }
                if (key === "key_7") {
                  return (
                    <StudentsProjects
                      key={key}
                      title={section.title}
                      array={section.array}
                    />
                  );
                }
              }

              return (
                <ContentMapping
                  key={key}
                  title={section.title}
                  description={section.description}
                />
              );
            })}
          </div>
          <div className="w-full lg:w-3/12 hidden lg:block">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 ? (
          <div className="container mx-auto mt-8">
            <SimilarEngineeringColleges colleges={similarColleges} />
          </div>
        ) : (
          <p className="text-center text-gray-600">
            No similar colleges available at the moment.
          </p>
        )}
      </div>
    </div>
  );
};

export default ResearchTab;
