import React from "react";

import CustomSwiper from "../../../components/Slider/CustomSwiper";
import "swiper/swiper-bundle.css";
import "./CompareScholarship.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { resetComparison } from "../collegeCompareSlice";
import { useHighlight } from "./HighlightContext";

import {
  fetchAndAddCollegeData,
  fetchSearchData,
} from "../collegeCompareThunk";
interface College {
  collegeLogo: string;
  collegeName: string;
  collegeId: number;
}

interface CardProps {
  college1: College;
  college2: College;
  title: string;
  index: number;
}

const scrollToComponent = () => {
  const element = document.getElementById("Accepted Exams");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
const CompareScholarship: React.FC = () => {
  const scholarshipCompare = useSelector(
    (state: RootState) => state.collegeCompare.landingData?.scholarshipCompare
  );
  const dispatch = useDispatch<AppDispatch>();
  const { setHighlightField } = useHighlight();

  if (!scholarshipCompare || scholarshipCompare.length <= 0) return;

  const handleCompareClick = (index: number) => {
    const section = scholarshipCompare[index];
    if (section) {
      dispatch(resetComparison());
      const idsToDispatch = section.map((data: any) => data.collegeId);
      idsToDispatch.forEach((id: number) => {
        console.log("scholarship ", id);
        dispatch(fetchAndAddCollegeData(id));
      });
      setHighlightField("field-scholarships");
      setTimeout(() => {
        setHighlightField("field-scholarships");

        scrollToComponent();
      }, 2000); // 2000 milliseconds = 2 seconds
    }
  };

  const Card: React.FC<CardProps> = ({ college1, college2, title, index }) => (
    <div className="flex flex-col bg-white pt-4 w-full md:w-[285px] h-[277px] pb-4 flex justify-center items-center">
      <div className="flex flex-row">
        <img
          src={college1.collegeLogo}
          alt={college1.collegeName}
          className="w-[60px] h-[60px] rounded-full"
        />
        <div className="vs px-8 translate-y-6">Vs</div>
        <img
          src={college2.collegeLogo}
          alt={college2.collegeName}
          className="w-[60px] h-[60px] rounded-full"
        />
      </div>
      <div className="title-cs py-8">{title}</div>
      <button
        onClick={() => handleCompareClick(index)}
        className="compares button-styles white-color w-[122px] h-[40px] bg-[#173CBA] rounded-lg"
      >
        Compare
      </button>
    </div>
  );
  const compareContent = scholarshipCompare?.map(
    (pair: [College, College], index: number) => (
      <Card
        key={index}
        index={index}
        college1={pair[0]}
        college2={pair[1]}
        title={`Compare ${pair[0].collegeName} and ${pair[1].collegeName}`}
      />
    )
  );

  return (
    <div className="bg-[#F7F7F7] min-h-[329px] pb-12">
      <h1 className="news-alerts-title text-center pt-9 mb-10 min-max-xs:mb-7 min-max-xss:text-3xl text-[#383838] text-[40px] font-medium">
        Compare Scholarship{" "}
      </h1>
      <div className="container">
        <CustomSwiper
          className="!px-2"
          items={compareContent}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 4, spaceBetween: 20 },
            1280: { slidesPerView: 4, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default CompareScholarship;
