import React from "react";
import { Link } from "react-router-dom";

interface CollaborationsSectionProps {
  title: string;
  description: string;
  rightSideImage: {
    src: string;
    alt: string;
    caption: string;
    buttonText: string;
    buttonLink: string;
  };
}

const CollaborationsSection: React.FC<CollaborationsSectionProps> = ({
  title,
  description,
  rightSideImage,
}) => {
  return (
    <section
      className="collaborations-section py-10 max-sm:py-3"
      id="Collaborations"
    >
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-14 items-center">
        <div className="w-full lg:w-9/12">
          <h2 className="text-2xl font-bold text-[#113cc0] mb-4">{title}</h2>
          <div
            className="text-lg leading-relaxed collaborationsContent"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className="w-full lg:w-3/12">
          <div className="bg-white shadow-lg rounded-lg text-center">
            <div className="relative">
              <img
                src={rightSideImage.src}
                alt={rightSideImage.alt}
                className="w-full h-full rounded-lg"
              />
              <div className="flex flex-col gap-4 p-4 absolute bottom-0">
                <p
                  className="text-lg font-normal mb-4"
                  dangerouslySetInnerHTML={{ __html: rightSideImage.caption }}
                ></p>
                <Link
                  to={rightSideImage.buttonLink}
                  className="white-color map-mentor arrow-button button-styles flex flex-row items-center justify-center gap-2"
                >
                  {rightSideImage.buttonText}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    className="arrow-icon"
                  >
                    <path
                      d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollaborationsSection;
