import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PlaceholderChip from "./common/PlaceholderChip/PlaceholderChip";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { useForm } from "react-hook-form";
import {
  nextStep,
  updateForm,
  prevStep,
} from "../../../../store/slices/registrationFormSlice";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import OptionGroup from "./common/OptionsCard/OptionGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { signupPAFDetail } from "../../../../store/thunks/authThunks";

interface CurrentLevelStudyProps {
  currentLevelOfStudy: string;
}

export const CURRENT_LEVEL_OF_STUDY: string[] = [
  "8-11",
  "12",
  "UG",
  "PG",
  "Others",
];
const cards: string[] = ["Card 1", "Card 2", "Card 3"];
const CurrentLevelStudy = () => {
  const dispatch = useDispatch<any>();
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);
  const { step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  console.log("step", step);

  const { pafData } = useSelector((state: RootState) => state.auth);

  const [selectedCard, setSelectedCard] = useState<any>("");
  const [selectedSingle, setSelectedSingle] = useState<any>(null);

  const { register, handleSubmit } = useForm();

  const [selectedOption, setSelectedOption] = useState<any>(null);

  const [selectedEducationalBoards, setSelectedEducationalBoards] =
    useState<any>(null);
  const [selectedpercentageOptions, setSelectedpercentageOptions] =
    useState<any>(null);

  const [errorValidation, setErrorValidation] = useState<any>(false);

  const onSubmit = (data: any) => {
    dispatch(updateForm({ currentLevelOfStudy: selectedSingle }));
    dispatch(nextStep());
  };

  const handleClick = (message: string): void => {
    console.log("Message from Child: ", message);

    onSubmit(message);
  };

  const handleCardClick = (card: string) => {
    console.log("CurrentLevelStudy - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleSingleSelectionChange = (selected: any) => {
    const existingData = JSON.parse(localStorage.getItem("paf_latest") || "{}");

    const updatedData = {
      ...existingData,
      currentLevelOfStudy: selected,
    };

    localStorage.setItem("paf_latest", JSON.stringify(updatedData));
    setSelectedSingle(selected);
    setSelectedOption(selected);
  };
  const handleBoardSelectionChange = (selected: any) => {
    const existingData = JSON.parse(localStorage.getItem("paf_latest") || "{}");

    const updatedData = {
      ...existingData,
      educationalBoard: selected,
    };

    localStorage.setItem("paf_latest", JSON.stringify(updatedData));
    setSelectedEducationalBoards(selected);
    dispatch(updateForm({ boardOfEducation: selected }));
    // Reset percentage options whenever board changes
  };

  const handlePercentageOptionsChange = (selected: any) => {
    const existingData = JSON.parse(localStorage.getItem("paf_latest") || "{}");

    const updatedData = {
      ...existingData,
      percentages: selected,
    };

    localStorage.setItem("paf_latest", JSON.stringify(updatedData));
    setSelectedpercentageOptions(selected);
    // dispatch(updateForm({ percentages: selected }));
  };

  const handleNext = () => {
    dispatch(updateForm({ currentLevelOfStudy: selectedSingle }));

    dispatch(
      signupPAFDetail({
        streamId: 2,
        courseId: 0,
        state: 0,
        city: 0,
        currentStudy: selectedOption,

        lookingFor: "",
        higherEducation: "",
        educationFund: "",
        importantFactor: "",
        boardOfEducation: selectedEducationalBoards,
      })
    );

    dispatch(nextStep());
  };

  const handlePrev = () => {
    dispatch(prevStep());
  };
  useEffect(() => {
    dispatch(
      signupPAFDetail({
        streamId: 2,
        courseId: 0,
        state: 0,
        city: 0,
        currentStudy: selectedOption,

        lookingFor: "",
        higherEducation: "",
        educationFund: "",
        importantFactor: "",
        boardOfEducation: "",
      })
    );
  }, [selectedSingle]);

  console.log("CurrentLevelStudy", selectedSingle);
  const PercentagesArray = Object.freeze(pafData?.percentages) || [];

  const PercentagesReversedArray = [...PercentagesArray].reverse();
  console.log(PercentagesReversedArray);
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={false}
        showBack={false}
        stepName="What's Your Current Education Level?"
        stepDescription=""
      >
        <div className="w-full p-4 overflow-auto ">
          <OptionGroup
            options={pafData?.currentLevelOfStudy}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.title
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="48"
            localStorageKey="currentLevelOfStudy"
          />

          {/* Conditionally render the additional question */}
          {(selectedSingle === "Class 8-11" ||
            selectedSingle === "Class 12") && (
            <div className="flex flex-col gap-4 mt-4">
              <h4 className="text-[#113cc0] font-semibold">
                Which educational board are you currently studying under?
              </h4>
              <OptionGroup
                options={pafData?.boardOfEducation} // Assuming you have options for the educational boards
                mode="single"
                getTitle={(option) =>
                  typeof option === "string" ? option : option.title
                }
                onSelectionChange={handleBoardSelectionChange} // Define a function for handling board selection
                cardSize="48"
                localStorageKey="boardOfEducation"
              />
            </div>
          )}
          {selectedEducationalBoards && (
            <div className="flex flex-col gap-4 mt-4">
              <h4 className="text-[#113cc0] font-semibold">
                What percentage did you achieve or are expecting in your 12th
                grade (+2)?
              </h4>
              <OptionGroup
                options={PercentagesReversedArray} // Assuming you have options for the educational boards
                mode="single"
                getTitle={(option) =>
                  typeof option === "string" ? option : option.title
                }
                onSelectionChange={handlePercentageOptionsChange} // Define a function for handling board selection
                cardSize="48"
                localStorageKey="percentages"
              />
            </div>
          )}
          {/* <h3 className="mt-4">Selected Single Option (Full Object):</h3>
          <pre>{JSON.stringify(selectedSingle, null, 2)}</pre> */}
          <div className="flex items-center mt-10 ">
            {errorValidation && (
              <p className="italic text-red-600 text-md">
                Please select all the values
              </p>
            )}
            <div className="flex flex-row items-center justify-center flex-grow gap-2">
              <div className="flex items-center gap-2">
                {/* <button
                  
                  className="px-2 py-2 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handlePrev}
                >
                   Back
                </button> */}
                <span className="w-4"> </span>
                {/* <button
                  className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  // onClick={handleNext}
                  onClick={() => {
                    // Move to next step if Undergraduate or Post Graduate is selected
                    if (
                      selectedOption !== "8-11" &&
                      selectedOption !== "12th"
                    ) {
                      handleNext();
                    } else {
                      // debugger;
                      // setErrorValidation(!errorValidation);
                      handleNext();
                      // For 'Class 8-11' or 'Class 12', validate board selection if needed
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button> */}
                <button
                  className={`py-2.5 px-2 md:px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200 ${
                    selectedSingle
                      ? "opacity-100"
                      : "opacity-50 cursor-not-allowed"
                  }`}
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={() => {
                    if (selectedSingle) {
                      handleNext();
                    }
                  }}
                  disabled={!selectedSingle}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default CurrentLevelStudy;
