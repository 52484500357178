import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import medal from "../image/medal.svg";

const InspiringCard = ({
  image,
  name,
  designation,
  role,
  comments,
}: {
  image: string;
  name: string;
  designation: string;
  role: string;
  comments: string;
}) => (
  <div className="flex justify-center">
    <div className=" w-full md:w-[237px] pt-40">
      <div className="absolute -translate-y-40">
        <img
          src={image}
          className="rounded-xl object-contain w-[237px] h-[301px] "
        />
      </div>
      <a href="/college-predictor-intermediate relative">
        <div className="flex px-4   ">
          <div className="relative px-2 w-full  flex  flex-col   pb-2   z-2 rounded-2xl  ">
            <div className="pt-4 text-[12px] text-white z-10 font-open-sans  leading-5 line-clamp-5">
              {comments}
            </div>
            <div className="flex flex-col  mt-2 pt-4 pb-2 text-[14px] z-10 font-open-sans text-[#383838] ">
              <div className="line-clamp-1 font-bold leading-6 "> {name}</div>
              <div className="line-clamp-1 leading-6"> {designation}</div>

              <div className="line-clamp-1 mt-4 italic leading-5"> {role}</div>
            </div>
            <div className="absolute inset-0 bg-[#FF7BCD] opacity-70 w-full z-0 rounded-lg"></div>
          </div>
        </div>
      </a>
    </div>
  </div>
);

const InspiringStories = () => {
  const mentorsData = useSelector(
    (state: RootState) => state.scholarship.scholarshipSuccessStory
  );

  const cardContent = mentorsData?.map((card) => <InspiringCard {...card} />);
  return (
    <div className="bg-[#FFF0FA] py-12">
      <div className="container flex flex-col md:flex-row">
        <div className="w-full md:w-[25%] flex flex-col">
          <div className="md:h-[45%]"></div>
          <div className="text-[#383838] font-roboto text-5xl font-light leading-[46px]">
            Inspiring
          </div>
          <div className="text-[#383838] font-roboto text-2xl font-bold leading-[46px]">
            Success Stories
          </div>
          <div className="text-[#383838] font-roboto text-xl font-normal leading-[32px] pr-12">
            Now I know its possible, and I know I can finish it
          </div>
        </div>
        <div className="w-full md:w-[75%]">
          <div className="flex justify-center mt-6">
            <CustomSwiper
              className="!px-2"
              items={cardContent}
              navigation={"side"}
              autoplay={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 1, spaceBetween: 20 },
                1024: { slidesPerView: 2, spaceBetween: 20 },
                1280: { slidesPerView: 3, spaceBetween: 30 },
              }}
              progressBar={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspiringStories;
