import { useSelector } from "react-redux";
import Table from "./Table";
import { RootState } from "../../../../store/store";
import "./Details.scss";
import { Link } from "react-router-dom";
import { Key } from "react";

const isMobile = window.innerWidth < 768;

const Details = () => {
  const data = useSelector(
    (state: RootState) => state.collegePredictorIntermediate.landingData
  );

  const yearsSet = new Set<string>();
  const qualifyingMarkTableDataRaw =
    data?.previousYearsQualifyingMarks?.data || [];

  // Extract and sort years (with safety checks)
  qualifyingMarkTableDataRaw.forEach((category: { marks: {} }) => {
    if (category?.marks) {
      Object.keys(category.marks).forEach((key) => yearsSet.add(key));
    }
  });

  const yearsList: string[] = Array.from(yearsSet).reverse();
  const qualifyingMarkTableData = [["Category", ...yearsList]];

  // Generate rows for the table (with safety checks)
  qualifyingMarkTableDataRaw.forEach(
    (category: {
      category: any;
      marks: { [x: string]: { toString: () => any } };
    }) => {
      qualifyingMarkTableData.push([
        category?.category || "-",
        ...yearsList.map((year) =>
          category?.marks?.[year] ? category.marks[year].toString() : "-"
        ),
      ]);
    }
  );

  const participatingInstitutesTableDataRaw =
    data?.participatingInstitutes?.data || [];
  const participatingInstitutesTableData = [
    ["Participating Institutes", "Number of Institutes"],
  ];

  participatingInstitutesTableDataRaw.forEach((row: { [x: string]: any }) => {
    participatingInstitutesTableData.push([
      row?.["Participating Institutes"] || "-",
      row?.["Number of Institutes"] || "-",
    ]);
  });

  interface College {
    collegeName: string;
  }

  const topCollegeData = data?.topColleges?.data?.map((college: College) => [
    <Link
      to={`/exam-details/${college?.collegeName}`}
      key={college?.collegeName || Math.random().toString()}
    >
      {college?.collegeName || "-"}
    </Link>,
  ]);

  return (
    <section className="college-predictor-details py-12 bg-[#FFF1FA] text-black">
      <div className="container mx-auto">
        <h3 className="text-2xl font-semibold">
          {data?.collegePredictorSteps?.title || "College Predictor Steps"}
        </h3>
        <div
          className="description text-grey"
          dangerouslySetInnerHTML={{
            __html: data?.collegePredictorSteps?.description || "",
          }}
        ></div>

        <h3 className="py-3 text-2xl font-semibold">
          {data?.benefits?.title || "Benefits"}
        </h3>
        <div
          className="description text-grey"
          dangerouslySetInnerHTML={{
            __html: data?.benefits?.description || "",
          }}
        ></div>

        <h3 className="py-3 text-2xl font-semibold">
          {data?.previousYearsQualifyingMarks?.title ||
            "Previous Years Qualifying Marks"}
        </h3>
        <div
          className="description text-grey"
          dangerouslySetInnerHTML={{
            __html: data?.qualifyingMarkTableData?.description || "",
          }}
        ></div>
        {/* {qualifyingMarkTableDataRaw.length > 0 && (
          <Table
            data={qualifyingMarkTableData}
            justify={isMobile ? "left" : "center"}
            className="mt-6 text-grey w-full"
            colHeader={true}
          />
        )} */}

        <h3 className="py-3 text-2xl font-semibold">
          {data?.participatingInstitutes?.title || "Participating Institutes"}
        </h3>
        <div
          className="description text-grey"
          dangerouslySetInnerHTML={{
            __html: data?.participatingInstitutes?.description || "",
          }}
        ></div>
        {/* {participatingInstitutesTableDataRaw.length > 0 && (
          <Table
            data={participatingInstitutesTableData}
            justify={isMobile ? "left" : "center"}
            className="mt-6 text-grey w-full"
            rowHeader={true}
          />
        )} */}

        <h3 className="py-3 text-2xl font-semibold">
          {data?.stateCounselling?.title || "State Counselling"}
        </h3>
        <div
          className="description text-grey"
          dangerouslySetInnerHTML={{
            __html: data?.stateCounselling?.description || "",
          }}
        ></div>

        <h3 className="py-3 text-2xl font-semibold">
          {data?.topColleges?.title || "Top Colleges"}
        </h3>
        {topCollegeData && (
          <Table
            data={topCollegeData}
            className="mt-6 text-grey w-fit"
            justify="left"
          />
        )}
      </div>
    </section>
  );
};

export default Details;
