import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import TabSelectorV1 from "../../../../components/TabSelectorV1";
import Accordion from "../../components/Accordion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import axios from "axios";
import config from "../../../../util/config";
const mockHubData = {
  categories: [
    "Engineering",
    "Medical",
    "Management",
    "Design",
    "Law",
    "Hospitality",
    "Architecture",
    "Liberal Arts",
  ],
  fqas: [
    {
      category: "Engineering",
      faqs: [
        {
          question: "How accurate are Engineering college rank predictors?",
          answer:
            "Engineering college rank predictors use statistical models to estimate rankings based on past data and trends.",
        },
        {
          question:
            "Can I use a rank predictor for different Engineering entrance exams?",
          answer:
            "Rank predictors are often tailored for specific exams. Be sure to select the predictor for your exam type.",
        },
        {
          question:
            "How do college predictors help in choosing an Engineering college?",
          answer:
            "College predictors provide insights into colleges you may qualify for based on your rank and preferences.",
        },
        {
          question:
            "What factors influence the accuracy of Engineering rank predictors?",
          answer:
            "Factors like past trends, data updates, and the quality of statistical models affect accuracy.",
        },
        {
          question:
            "Can I rely on rank predictors for deciding my college preferences?",
          answer:
            "Rank predictors provide useful insights, but you should also consider factors like location and facilities.",
        },
        {
          question: "How frequently are Engineering rank predictors updated?",
          answer:
            "Most rank predictors are updated annually to reflect new exam trends and cutoff data.",
        },
      ],
    },
    {
      category: "Medical",
      faqs: [
        {
          question: "How accurate are Medical college rank predictors?",
          answer:
            "Medical rank predictors analyze NEET scores and trends to give accurate predictions.",
        },
        {
          question: "Are Medical rank predictors free to use?",
          answer:
            "Most Medical rank predictors are free, but some advanced features may require a subscription.",
        },
        {
          question: "Can I predict my rank for NEET UG with these tools?",
          answer:
            "Yes, most Medical rank predictors focus on NEET UG rankings specifically.",
        },
        {
          question: "Can rank predictors help with AIIMS admission?",
          answer:
            "Yes, some predictors include AIIMS cutoffs and data for better accuracy.",
        },
        {
          question: "Are Medical predictors useful for PG exams?",
          answer:
            "Some predictors are tailored for NEET PG and other postgraduate Medical exams.",
        },
        {
          question: "Do Medical rank predictors include state-level exams?",
          answer:
            "Yes, many predictors also include state-level Medical entrance exams.",
        },
      ],
    },
    {
      category: "Management",
      faqs: [
        {
          question: "What is a Management rank predictor?",
          answer:
            "A Management rank predictor estimates your chances of admission into MBA programs based on exam scores like CAT.",
        },
        {
          question: "Are rank predictors reliable for MBA colleges?",
          answer:
            "Rank predictors give a general idea but may not account for factors like interviews and work experience.",
        },
        {
          question: "Can rank predictors help with Executive MBA programs?",
          answer:
            "Some rank predictors include executive programs based on different criteria.",
        },
        {
          question: "Do rank predictors include non-CAT MBA exams?",
          answer:
            "Yes, many predictors include exams like XAT, MAT, and CMAT in their analysis.",
        },
        {
          question: "Can rank predictors suggest specializations?",
          answer:
            "Some predictors provide recommendations for MBA specializations based on exam scores.",
        },
        {
          question: "Are Management rank predictors region-specific?",
          answer:
            "Many predictors include region-specific data for colleges across India.",
        },
      ],
    },
    {
      category: "Design",
      faqs: [
        {
          question: "Can I predict admission chances for NID and NIFT?",
          answer:
            "Yes, design rank predictors often include exams like NID and NIFT.",
        },
        {
          question: "How do rank predictors help in design education?",
          answer:
            "They provide insights into which design schools you may qualify for based on your scores.",
        },
        {
          question: "Are rank predictors available for UCEED?",
          answer:
            "Yes, most design rank predictors include UCEED as part of their analysis.",
        },
        {
          question: "Do rank predictors consider portfolio reviews?",
          answer:
            "Some predictors factor in portfolio scores if they are part of the admission process.",
        },
        {
          question:
            "How reliable are rank predictors for niche design schools?",
          answer:
            "The reliability depends on the data coverage and accuracy for specific schools.",
        },
        {
          question: "Can rank predictors suggest international design schools?",
          answer:
            "Some advanced predictors also include global design schools based on scores.",
        },
      ],
    },
    {
      category: "Law",
      faqs: [
        {
          question: "How do Law rank predictors work?",
          answer:
            "Law rank predictors use your CLAT score to estimate admission chances in law colleges.",
        },
        {
          question: "Are Law rank predictors accurate?",
          answer:
            "Accuracy depends on the data model and how closely it reflects current CLAT cutoffs.",
        },
      ],
    },
    {
      category: "Hospitality",
      faqs: [
        {
          question: "Can Hospitality rank predictors help me choose colleges?",
          answer:
            "Yes, they provide a list of potential colleges based on your scores and preferences.",
        },
      ],
    },
    {
      category: "Architecture",
      faqs: [
        {
          question: "Do Architecture rank predictors include NATA scores?",
          answer:
            "Yes, most Architecture rank predictors include NATA and JEE Architecture scores.",
        },
        {
          question: "Can I predict admission into top Architecture schools?",
          answer:
            "Rank predictors provide an estimate, but final admission depends on multiple factors.",
        },
      ],
    },
    {
      category: "Liberal Arts",
      faqs: [
        {
          question: "How do rank predictors work for Liberal Arts?",
          answer:
            "Liberal Arts rank predictors analyze application essays and scores to provide estimates.",
        },
        {
          question: "Are Liberal Arts predictors widely available?",
          answer:
            "They are less common compared to other fields but are gaining popularity.",
        },
      ],
    },
  ],
};

const KnowledgeHub = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [title, setTitle] = useState<string>(mockHubData.categories[0]);
  const [datadynamic, setDatadynamic] = useState<any[]>([]);

  // const hubData = useSelector(
  //   (state: RootState) => state.predictorLanding.knowledgrHub
  // );
  // const categories = hubData?.categories ?? [];
  // const fqas = hubData?.fqas ?? [];

  // useEffect(() => {
  //   if (title) {
  //     axios({
  //       url: config.apiUrl + `/api/college/streamLevelFqs/${title}`,
  //       method: "GET",
  //     })
  //       .then((response) => {
  //         setDatadynamic(response.data.data || []);
  //         // console.log("title",datadynamic[0]);
  //       })
  //       .catch((error) => {
  //         console.error("Failed to fetch data", error);
  //         setDatadynamic([]);
  //       });
  //   }
  // }, [title]);
  useEffect(() => {
    if (title) {
      const categoryFAQs = mockHubData.fqas.find(
        (faq) => faq.category === title
      );
      setDatadynamic(categoryFAQs?.faqs || []);
    }
  }, [title]);

  useEffect(() => {
    if (mockHubData.categories.length > 0) {
      setTitle(mockHubData.categories[activeTab]);
    }
  }, [activeTab]);

  return (
    <section className="py-12 bg-[#FFF1FA] text-black">
      <div className="container flex flex-col gap-4">
        <h1 className="text-5xl">
          <span className="font-bold">Knowledge Hub</span> (FAQs)
        </h1>
        <TabSelectorV1
          tabList={mockHubData.categories}
          activeTab={activeTab}
          changeActiveTab={(index) => setActiveTab(index)}
        />
        <div className="w-full md:w-4/5 grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-2">
          {datadynamic.map((item, index) => (
            <Accordion
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default KnowledgeHub;
