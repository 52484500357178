import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import ProfileDetailEditModal from "./ProfileDetailEditModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { FormInput } from "./FieldInput";

interface IProps {
  name: string;
  subCategory: {
    name?: string;
    repeatedFieldGroupKey?: string;
    fields: {
      key: string;
      name: string;
      type: FormInput;
      required: boolean;
      options?: { key: string | number; name: string }[];
      validationRegex?: RegExp;
    }[];
  }[];
}

const ProfileDetailCard: FC<IProps> = ({ name, subCategory }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const userData = useSelector((state: RootState) => state.myProfile.userData);

  if (!userData) return;

  return (
    <>
      <div className="bg-white rounded-lg p-2">
        <div className="flex items-center justify-between p-2">
          <h2 className="text-xl font-bold">{name}</h2>
          <button>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => setShowEditPopup(true)}
            />
          </button>
        </div>
        <div className="divide-y">
          {subCategory.map((subCategory) => (
            <div className="p-2 flex flex-col gap-4">
              {subCategory.name && (
                <h3 className="text-md font-medium text-grey mb-1">
                  {subCategory.name}
                </h3>
              )}
              {subCategory.repeatedFieldGroupKey ? (
                (userData[subCategory.repeatedFieldGroupKey] || []).map(
                  (listItem: any) => (
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                      {subCategory.fields.map((field) => {
                        return (
                          <div>
                            <div>{field.name}</div>
                            <div className="text-ellipsis font-bold overflow-hidden">
                              {getFieldValue(field, listItem)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                )
              ) : (
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                  {subCategory.fields.map((field) => {
                    return (
                      <div>
                        <div>{field.name}</div>
                        <div className="text-ellipsis font-bold overflow-hidden">
                          {getFieldValue(field, userData)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <ProfileDetailEditModal
        isOpen={showEditPopup}
        onClose={() => setShowEditPopup(false)}
        name={name}
        subCategory={subCategory}
      />
    </>
  );
};

export default ProfileDetailCard;

const getFieldValue = (
  field: {
    key: string | number;
    type: FormInput;
    options?: { key: string | number; name: string }[];
  },
  userData: { [key: string]: any }
) => {
  let value: string | number = "";

  if (userData[field.key])
    if ([FormInput.Dropdown, FormInput.Radio].includes(field.type))
      value =
        field.options?.find((option) => option.key == userData[field.key])
          ?.name || "";
    else value = userData[field.key];

  return value;
};
