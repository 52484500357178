import config from "../../util/config";

const data = {
  "For Students": {
    "Learn About Yourself": {
      "Class 8 - 9": [
        { name: "Ikigai Discovery Program", url: "/" }
      ],
      "Class 10 - 12": [
        { name: "Stream & Subject Selection", url: "/" }
      ],
      "College & Graduates": [
        { name: "Master's Or Employment", url: "/" }
      ],
      "Campus Tour": [
        { name: "Visit to a College or University", url: "/" }
      ],
      "Study Abroad": [
        { name: "Top Countries", url: "/" }
      ],
      "Career Quiz": [
        { name: "Career Quiz", url: "/" }
      ]
    },
    "Explore Mentorship": [
      { name: "Explore Student Mentor: Peer Guidance", url: "/" },
      { name: "Explore Career Mentor: Professional Pathways", url: "/" },
      { name: "Explore Admission Mentor: Application Assistance", url: "/" },
      { name: "Explore Health Mentor: Wellness Support", url: "/" },
      { name: "Explore Scholarship Mentor: Funding Opportunities", url: "/" },
      { name: "Explore Loan Mentor: Financial Solutions", url: "/" }
    ],
    "Explore Knowledge": [
      { name: "College Mentor Scholarship Test: ₹1Cr Scholarship", url: "/" },
      { name: "Application to Admission", url: "/" },
      { name: "Institutions of Eminence (IoE): Notable IoEs in India", url: "/" },
      { name: "National Importance of Institutes: IITs | IISc | AIIMS | NITs", url: "/" },
      { name: "Deemed to Be Universities: All India Universities", url: "/" },
      { name: "State Private Universities: All India Universities", url: "/" },
      { name: "Fake Universities: All India Universities", url: "/" },
      { name: "Education Regulatory & Professional: Councils in India", url: "/" }
    ],
    "Explore Resources": [
      { name: "News & Articles: Colleges | Exams | Careers & Courses | Admissions", url: "/" },
      { name: "Refer & Earn: Refer a Friend", url: "/" },
      { name: "Education for All", url: "/" },
      { name: "College Rankings: NIRF Rankings 2024", url: "/" },
      { name: "College Predictors: All India College Predictors", url: "/" },
      { name: "About Us", url: "/" }
    ]
  },
  "Architecture": {
    "Degrees Specializations": {
      "After 10th": [
        { name: "Diploma in Architecture Engineering (D.Arch)", url: "/" }
      ],
      "After 12th": [
        { name: "Bachelor of Architecture (B.Arch) - 5 years", url: "/" },
        { name: "Bachelor of Planning (B.Plan) - 4 years", url: "/" },
        { name: "Integrated B.Plan - M.Plan - 5 years", url: "/" }
      ],
      "After Graduation": [
        { name: "Master of Architecture (M.Arch) - 2 Years", url: "/" }
      ],
      "After Post Graduation": [
        { name: "Ph.D - 3 to 6 Years", url: "/" }
      ]
    },
    "Entrance Exams": [
      { name: "National Aptitude Test for Architecture (NATA)", url: "/" },
      { name: "JEE Main: Architecture Paper (Paper 2)", url: "/" },
      { name: "AAT (Architecture Aptitude Test)", url: "/" },
      { name: "Graduate Aptitude Test in Engineering (GATE)", url: "/" },
      { name: "Common Entrance Examination for Design (CEED)", url: "/" },
      { name: "MAHADAC (Maharashtra)", url: "/" },
      { name: "Karnataka CET", url: "/" }
    ],
    "Resources": [
      { name: "Architecture Council", url: "/" },
      { name: "B.Arch College Predictor", url: "/" },
      { name: "B.Arch Mock Test Series", url: "/" },
      { name: "Compare Colleges", url: "/" },
      { name: "Architecture Colleges In India", url: "/" },
      { name: "Architectural Mentor Connect", url: "/" },
      { name: "News & Articles", url: "/" },
      { name: "Scholarships In Architecture", url: "/" },
      { name: "Architecture Admissions @ "+config.mobileNumber, url: "/" }
    ],
    "Top Colleges India": [
      { name: "Indian Institute of Technology, Roorkee", url: "/" },
      { name: "Indian Institute of Technology, Kharagpur", url: "/" },
      { name: "National Institute of Technology, Calicut", url: "/" },
      { name: "Indian Institute of Engineering Science and Technology, Shibpur", url: "/" },
      { name: "School of Planning and Architecture, New Delhi", url: "/" },
      { name: "Centre for Environmental Planning and Technology, Ahmedabad", url: "/" }
    ],
    "Top Colleges World": [
      { name: "Massachusetts Institute of Technology (MIT), USA", url: "/" },
      { name: "Delft University of Technology, Netherlands", url: "/" },
      { name: "ETH Zurich, Switzerland", url: "/" },
      { name: "Harvard University, USA", url: "/" },
      { name: "University College London (UCL), UK", url: "/" },
      { name: "University of California, Berkeley, USA", url: "/" }
    ],
    "Colleges By City": [
      { name: "Amravati", url: "/" },
      { name: "Bangalore", url: "/" },
      { name: "Chennai", url: "/" },
      { name: "Delhi", url: "/" },
      { name: "Hyderabad", url: "/" },
      { name: "Mumbai", url: "/" },
      { name: "Chandigarh", url: "/" }
    ]
  },
  "Engineering": {
    "Degrees Specializations": {
      "After 10th": [
        { name: "Diploma In Engineering - 3 Years", url: "/" }
      ],
      "After 12th": [
        { name: "Bachelor of Engineering (B.E | B.TECH) - 4 Years", url: "/" },
        { name: "Integrated Programs - 5 Years", url: "/" }
      ],
      "After Graduation": [
        { name: "Master of Engineering (M.E | M.TECH) - 2 Years", url: "/" }
      ],
      "PhD": [
        { name: "Ph.D. in Engineering - 3 to 6 Years", url: "/" }
      ]
    },
    "Entrance Exams": [
      { name: "JEE Main - 2025", url: "/" },
      { name: "JEE Advanced - 2025", url: "/" },
      { name: "CUCET - 2025", url: "/" },
      { name: "BITSAT - 2025", url: "/" },
      { name: "VITEEE - 2025", url: "/" },
      { name: "AEEE - 2025", url: "/" },
      { name: "SRMJEEE - 2025", url: "/" },
      { name: "GATE - 2025", url: "/" }
    ],
    "Specialization Courses": [
      { name: "Aerospace Engineering", url: "/" },
      { name: "Automobile Engineering", url: "/" },
      { name: "Biomedical Engineering", url: "/" },
      { name: "CSE Artificial Intelligence (AI)", url: "/" },
      { name: "Electronics and Communication Engineering", url: "/" },
      { name: "Marine Engineering", url: "/" },
      { name: "Environmental Engineering", url: "/" }
    ],
    "Top Colleges India": [
      { name: "Indian Institutes of Technology (IITs)", url: "/" },
      { name: "Institutions of Eminence (IoEs)", url: "/" },
      { name: "National Institutes of Technology (NITs)", url: "/" },
      { name: "Indian Institutes of Information Technology (IIITs)", url: "/" },
      { name: "Indian Institutes of Science Education and Research (IISERs)", url: "/" },
      { name: "Government Funded Technical Institutes (GFTIs)", url: "/" }
    ],
    "Resources": [
      { name: "Engineering Statutory Councils", url: "/" },
      { name: "NIRF 2024 Ranking Colleges", url: "/" },
      { name: "Engineering College Predictors", url: "/" },
      { name: "Engineering Mentors Connect", url: "/" },
      { name: "News & Articles", url: "/" },
      { name: "Scholarships & Loans", url: "/" },
      { name: "Engineering Admissions @"+config.mobileNumber, url: "/" }
    ],
    "Top Colleges World": [
      { name: "Massachusetts Institute of Technology (MIT)", url: "/" },
      { name: "Stanford University", url: "/" },
      { name: "Harvard University", url: "/" },
      { name: "California Institute of Technology (Caltech)", url: "/" },
      { name: "University of California, Berkeley", url: "/" },
      { name: "University of Cambridge", url: "/" }
    ],
    "Colleges By City": [
      { name: "Amravati", url: "/" },
      { name: "Bangalore", url: "/" },
      { name: "Chennai", url: "/" },
      { name: "Delhi", url: "/" },
      { name: "Hyderabad", url: "/" },
      { name: "Mumbai", url: "/" },
      { name: "Chandigarh", url: "/" }
    ]
  },
  "Medical Health Sciences": {
    "Degrees": [
      { name: "MBBS - 5.5 years", url: "/" },
      { name: "BDS - 5 years", url: "/" },
      { name: "B.Sc Nursing - 4 years", url: "/" },
      { name: "B.Pharm - 4 years", url: "/" },
      { name: "BVSc - 5 years", url: "/" },
      { name: "B.Sc. Allied Health Sciences - 3-4 years", url: "/" }
    ],
    "Entrance Exams": [
      { name: "NEET- UG", url: "/" },
      { name: "NEET - PG", url: "/" },
      { name: "NEET - SS", url: "/" },
      { name: "AIIMS - INI CET", url: "/" }
    ],
    "Top Colleges India": [
      { name: "AIIMS - New Delhi", url: "/" },
      { name: "PGIMER - Chandigarh", url: "/" },
      { name: "CMC - Vellore", url: "/" },
      { name: "NIMHANS - Bengaluru", url: "/" },
      { name: "JIPMER - Puducherry", url: "/" },
      { name: "SGPGIMS - Lucknow", url: "/" }
    ],
    "Top Colleges World": [
      { name: "Harvard University - United States", url: "/" },
      { name: "Stanford University - United States", url: "/" },
      { name: "Johns Hopkins University - United States", url: "/" },
      { name: "University of Oxford - United Kingdom", url: "/" },
      { name: "University of Cambridge - United Kingdom", url: "/" },
      { name: "Imperial College London - United Kingdom", url: "/" }
    ],
    "Medical Colleges": [
      { name: "Medical Colleges in India", url: "/" },
      { name: "Dental Colleges in India", url: "/" },
      { name: "Traditional Indian Medicine Colleges", url: "/" },
      { name: "Nursing Colleges in India", url: "/" },
      { name: "Veterinary & Animal Science Colleges", url: "/" },
      { name: "Paramedical (Allied Health Sciences)", url: "/" },
      { name: "Sports Medicine Colleges in India", url: "/" }
    ],
    "Resources": [
      { name: "Medical Regulatory Councils", url: "/" },
      { name: "Medical College Predictors", url: "/" },
      { name: "Medical Mentors Connect", url: "/" },
      { name: "News & Articles", url: "/" },
      { name: "Scholarships & Loans", url: "/" },
      { name: "Medical Admissions Counselling @"+config.mobileNumber, url: "/" }
    ]
  },
  "Management": {
    "Degrees Specializations": {
      "After 10th": [
        { name: "Diploma in Management - 1 to 3 years", url: "/" }
      ],
      "After 12th": [
        { name: "Bachelor of Business Administration (BBA) - 3 years", url: "/" },
        { name: "Integrated BBA + MBA - 5 years", url: "/" }
      ],
      "After Graduation": [
        { name: "Master of Business Administration (MBA) - 2 years", url: "/" },
        { name: "Doctor of Philosophy (Ph.D.) in Management - 3 to 5 years", url: "/" }
      ]
    },
    "Entrance Exams": [
      { name: "JET - Joint Entrance Test for Management", url: "/" },
      { name: "IPMAT - Integrated Program in Management Aptitude Test", url: "/" },
      { name: "AIMA UGAT - All India Management Association Under Graduate Aptitude Test", url: "/" },
      { name: "CUCET - Central Universities Common Entrance Test", url: "/" },
      { name: "CAT - Common Admission Test", url: "/" },
      { name: "XAT - Xavier Aptitude Test", url: "/" },
      { name: "GMAT - Graduate Management Admission Test", url: "/" }
    ],
    "Specializations": [
      { name: "Business Management", url: "/" },
      { name: "Marketing Management", url: "/" },
      { name: "Human Resource Management", url: "/" },
      { name: "Entrepreneurship", url: "/" },
      { name: "International Business", url: "/" },
      { name: "Healthcare Management", url: "/" },
      { name: "E-commerce Management", url: "/" },
      { name: "Digital Marketing", url: "/" }
    ],
    "Top Colleges India": [
      { name: "Indian Institute of Management Bangalore (IIMB)", url: "/" },
      { name: "Indian Institute of Management Ahmedabad (IIMA)", url: "/" },
      { name: "Indian Institute of Management Calcutta (IIMC)", url: "/" },
      { name: "Indian Institute of Management Lucknow (IIML)", url: "/" },
      { name: "Indian Institute of Management Kozhikode (IIMK)", url: "/" },
      { name: "Indian Institute of Management Indore (IIMI)", url: "/" },
      { name: "XLRI Jamshedpur", url: "/" }
    ],
    "Top Colleges World": [
      { name: "Massachusetts Institute of Technology (MIT) - United States", url: "/" },
      { name: "Imperial College London - United Kingdom", url: "/" },
      { name: "University of Oxford - United Kingdom", url: "/" },
      { name: "Harvard University - United States", url: "/" },
      { name: "University of Cambridge - United Kingdom", url: "/" },
      { name: "Stanford University - United States", url: "/" },
      { name: "ETH Zürich - Swiss Federal Institute of Technology - Switzerland", url: "/" }
    ],
    "Resources": [
      { name: "Management Council", url: "/" },
      { name: "Top BBA Colleges In India", url: "/" },
      { name: "Top MBA & PGDM Colleges In India", url: "/" },
      { name: "MBA Predictors", url: "/" },
      { name: "Scholarships in MBA", url: "/" },
      { name: "News & Articles", url: "/" },
      { name: "Scholarships & Loans", url: "/" },
      { name: "Management Admissions @ "+config.mobileNumber, url: "/" }
    ]
  },
  "Law": {
    "Degrees Specializations": {
      "Integrated Degrees": [
        { name: "BA LLB (Bachelor of Arts + Bachelor of Laws) - 5 years", url: "/" },
        { name: "BCom LLB (Bachelor of Commerce + Bachelor of Laws) - 5 years", url: "/" },
        { name: "B.Tech LLB (Bachelor of Technology + Bachelor of Laws) - 6 years", url: "/" }
      ],
      "After Graduation": [
        { name: "LLB - 3 Years", url: "/" },
        { name: "LLM - 1 Year", url: "/" },
        { name: "MBL - 1 to 2 Years", url: "/" },
        { name: "Ph.D. in Law - 3 to 5 Years", url: "/" }
      ]
    },
    "Entrance Exams": [
      { name: "CLAT - Common Law Admission Test", url: "/" },
      { name: "LSAT India - Law School Admission Test India", url: "/" },
      { name: "AILET - All India Law Entrance Test (National Law University Delhi)", url: "/" },
      { name: "CULEE - Christ University Law Entrance Exam", url: "/" },
      { name: "JSAT - Jindal Scholarly Aptitude Test (OP Jindal Global University)", url: "/" },
      { name: "CUET Law - Common University Entrance Test for Law", url: "/" },
      { name: "AP LAWCET - Andhra Pradesh Law Common Entrance Test", url: "/" },
      { name: "TS LAWCET - Telangana State Law Common Entrance Test", url: "/" }
    ],
    "Top Colleges India": [
      { name: "National Law School of India University (NLSIU), Bengaluru", url: "/" },
      { name: "National Law University (NLU), New Delhi", url: "/" },
      { name: "NALSAR University of Law, Hyderabad", url: "/" },
      { name: "The West Bengal National University of Juridical Sciences (NUJS), Kolkata", url: "/" },
      { name: "Symbiosis Law School, Pune", url: "/" },
      { name: "Jamia Millia Islamia, New Delhi", url: "/" }
    ],
    "Top Colleges World": [
      { name: "Harvard Law School (Harvard University, USA)", url: "/" },
      { name: "University of Oxford (UK)", url: "/" },
      { name: "Yale Law School (Yale University, USA)", url: "/" },
      { name: "Stanford Law School (Stanford University, USA)", url: "/" },
      { name: "University of Cambridge (UK)", url: "/" },
      { name: "Columbia Law School (Columbia University, USA)", url: "/" }
    ],
    "Resources": [
      { name: "Law Regulatory Councils in India", url: "/" },
      { name: "Law Colleges in India", url: "/" },
      { name: "Law Colleges Predictor", url: "/" },
      { name: "Connect Law Mentors", url: "/" },
      { name: "Scholarships in Law", url: "/" },
      { name: "News & Articles", url: "/" },
      { name: "Law Admission @ "+config.mobileNumber, url: "/" }
    ],
    "Colleges By City": [
      { name: "Amravati", url: "/" },
      { name: "Bangalore", url: "/" },
      { name: "Chennai", url: "/" },
      { name: "Delhi", url: "/" },
      { name: "Hyderabad", url: "/" },
      { name: "Mumbai", url: "/" },
      { name: "Chandigarh", url: "/" }
    ]
  },
  "Liberal Arts": {
    "Degrees Specializations": {
      "After 10th & 12 (3-4 Years)": [
        { "name": "Bachelor of Arts (BA) in Liberal Arts", "url": "/" },
        { "name": "Bachelor of Arts in Humanities", "url": "/" },
        { "name": "Bachelor of Fine Arts (BFA)", "url": "/" },
        { "name": "Bachelor of Arts in Social Sciences", "url": "/" },
        { "name": "Bachelor of Arts in Communication", "url": "/" },
        { "name": "Bachelor of Arts in Psychology", "url": "/" },
        { "name": "Bachelor of Arts in Philosophy", "url": "/" },
        { "name": "Bachelor of Arts in History", "url": "/" },
        { "name": "Bachelor of Arts in Political Science", "url": "/" },
        { "name": "Bachelor of Arts in Sociology", "url": "/" }
      ],
      "Specializations": [
        { "name": "Content Writer", "url": "/" },
        { "name": "Public Policy", "url": "/" },
        { "name": "Clinical Psychologist", "url": "/" },
        { "name": "Social Worker", "url": "/" },
        { "name": "Political Analyst", "url": "/" },
        { "name": "Archaeologist", "url": "/" },
        { "name": "Research Scientist", "url": "/" },
        { "name": "Graphic Designer", "url": "/" },
        { "name": "Art Director", "url": "/" },
        { "name": "Market Research Analyst", "url": "/" },
        { "name": "Journalist", "url": "/" },
        { "name": "Content Strategist", "url": "/" }
      ]
    },
    "Entrance Exams": [
      { "name": "CUCET (Central Universities Common Entrance Test)", "url": "/" },
      { "name": "Flame University Aptitude Test (FEAT)", "url": "/" },
      { "name": "Shiv Nadar University Entrance Exam (SNUEE)", "url": "/" },
      { "name": "Ashoka Aptitude Test (AAT)", "url": "/" },
      { "name": "Krea Immersive Case (KIC)", "url": "/" },
      { "name": "Symbiosis Entrance Test (SET)", "url": "/" },
      { "name": "Azim Premji University (AET)", "url": "/" },
      { "name": "Jindal Scholastic Aptitude Test (JSAT)", "url": "/" },
      { "name": "Karnavati University Aptitude Test (KUAT)", "url": "/" },
      { "name": "Christ University Entrance Test (CUET)", "url": "/" },
      { "name": "Tata Institute of Social Sciences (TISS)", "url": "/" }
    ],
    "Top Colleges India": [
      { "name": "Ashoka University", "url": "/" },
      { "name": "Flame University, Pune", "url": "/" },
      { "name": "Tata Institute of Social Sciences (TISS)", "url": "/" },
      { "name": "OP Jindal Global University", "url": "/" },
      { "name": "Azim Premji University", "url": "/" },
      { "name": "Krea University", "url": "/" },
      { "name": "Lady Shri Ram College for Women", "url": "/" },
      { "name": "Manipal University", "url": "/" },
      { "name": "Symbiosis School of Liberal Arts", "url": "/" },
      { "name": "St. Xavier's College", "url": "/" }
    ],
    "Top Colleges World": [
      { "name": "Harvard University (USA)", "url": "/" },
      { "name": "Stanford University (USA)", "url": "/" },
      { "name": "University of Chicago (USA)", "url": "/" },
      { "name": "Massachusetts Institute of Technology (MIT) (USA)", "url": "/" },
      { "name": "Columbia University (USA)", "url": "/" },
      { "name": "Yale University (USA)", "url": "/" },
      { "name": "Oxford University (UK)", "url": "/" },
      { "name": "Cambridge University (UK)", "url": "/" },
      { "name": "Swarthmore College (USA)", "url": "/" },
      { "name": "Wellesley College (USA)", "url": "/" }
    ],
    "Resources": [
      { "name": "Liberal Arts Quiz", "url": "/" },
      { "name": "Liberal Arts Education Councils", "url": "/" },
      { "name": "Liberal Arts Exam Predictors", "url": "/" },
      { "name": "Top Liberal Arts Colleges in India", "url": "/" },
      { "name": "Connect to Liberal Arts Mentors", "url": "/" },
      { "name": "Compare Liberal Arts Colleges", "url": "/" },
      { "name": "Liberal Arts Scholarships in India", "url": "/" },
      { "name": "News & Articles", "url": "/" },
      { "name": "Liberal Arts Admission @"+config.mobileNumber, "url": "/" }
    ]
  },
  "Study Abroad": {
    "Preferred Courses": [
      { "name": "Business and Management", "url": "/" },
      { "name": "Engineering", "url": "/" },
      { "name": "Computer Science", "url": "/" },
      { "name": "Health Sciences", "url": "/" },
      { "name": "Social Sciences", "url": "/" },
      { "name": "Environmental Science", "url": "/" },
      { "name": "Arts and Humanities", "url": "/" },
      { "name": "Hospitality and Tourism Management", "url": "/" },
      { "name": "Education", "url": "/" },
      { "name": "Law", "url": "/" }
    ],
    "Exams": [
      { "name": "SAT: Scholastic Assessment Test", "url": "/" },
      { "name": "ACT: American College Testing", "url": "/" },
      { "name": "GRE: Graduate Record Examination", "url": "/" },
      { "name": "GMAT: Graduate Management Admission Test", "url": "/" },
      { "name": "LSAT: Law School Admission Test", "url": "/" },
      { "name": "TOEFL: Test of English as a Foreign Language", "url": "/" },
      { "name": "IELTS: International English Language Testing System", "url": "/" }
    ],
    "Top Destinations": [
      { "name": "United States", "url": "/" },
      { "name": "United Kingdom", "url": "/" },
      { "name": "Canada", "url": "/" },
      { "name": "Australia", "url": "/" },
      { "name": "Germany", "url": "/" },
      { "name": "Netherlands", "url": "/" },
      { "name": "Sweden", "url": "/" },
      { "name": "New Zealand", "url": "/" },
      { "name": "Singapore", "url": "/" },
      { "name": "France", "url": "/" }
    ],
    "Top Universities (THE Rankings)": [
      { "name": "University of Oxford (United Kingdom)", "url": "/" },
      { "name": "Stanford University (United States)", "url": "/" },
      { "name": "Harvard University (United States)", "url": "/" },
      { "name": "California Institute of Technology", "url": "/" },
      { "name": "Massachusetts Institute of Technology", "url": "/" },
      { "name": "University of Cambridge (United Kingdom)", "url": "/" },
      { "name": "University of Chicago (United States)", "url": "/" },
      { "name": "Imperial College London (United Kingdom)", "url": "/" },
      { "name": "ETH Zurich - Swiss Federal Institute of Technology (Switzerland)", "url": "/" },
      { "name": "University College London (UCL) (United Kingdom)", "url": "/" }
    ],
    "Top Universities (QS Rankings)": [
      { "name": "Massachusetts Institute of Technology (MIT), USA", "url": "/" },
      { "name": "University of Cambridge, UK", "url": "/" },
      { "name": "University of Oxford, UK", "url": "/" },
      { "name": "Harvard University, USA", "url": "/" },
      { "name": "Stanford University, USA", "url": "/" },
      { "name": "California Institute of Technology (Caltech), USA", "url": "/" },
      { "name": "University of Chicago, USA", "url": "/" },
      { "name": "Imperial College London, UK", "url": "/" },
      { "name": "National University of Singapore (NUS), Singapore", "url": "/" },
      { "name": "University of California, Berkeley (UCB), USA", "url": "/" }
    ],
    "Resources": [
      { "name": "Study Abroad Quiz", "url": "/" },
      { "name": "News & Articles", "url": "/" },
      { "name": "International Scholarships", "url": "/" },
      { "name": "Connect Study Abroad Mentors", "url": "/" },
      { "name": "Study Abroad @"+config.mobileNumber, "url": "/" }
    ]
  },
  "Hospitality Tourism": {
    "Degrees Specializations": {
      "After 10th & 12th (1-3 Years)": [
        { "name": "Diploma in Hotel Management", "url": "/" },
        { "name": "Bachelor of Hotel Management (BHM)", "url": "/" },
        { "name": "Bachelor of Travel and Tourism Management", "url": "/" }
      ],
      "After Graduation": [
        { "name": "Master of Hotel Management (MHM)", "url": "/" },
        { "name": "MBA in Hospitality Management", "url": "/" },
        { "name": "MBA in Tourism and Travel Management", "url": "/" }
      ],
      "Popular Specializations": [
        { "name": "Bartending and Mixology", "url": "/" },
        { "name": "Food Safety and Hygiene", "url": "/" },
        { "name": "Event Planning and Management", "url": "/" },
        { "name": "Luxury Hospitality Management", "url": "/" },
        { "name": "Food and Beverage Management", "url": "/" },
        { "name": "Front Office Management", "url": "/" },
        { "name": "Housekeeping Management", "url": "/" },
        { "name": "Culinary Arts", "url": "/" },
        { "name": "Travel Guide & Operations Management", "url": "/" },
        { "name": "Health and Wellness Tourism", "url": "/" }
      ]
    },
    "Entrance Exams": [
      { "name": "IHM Aurangabad Exam - 2025", "url": "/" },
      { "name": "NCHM JEE -2025", "url": "/" },
      { "name": "MAH MHMCT CET -2025", "url": "/" },
      { "name": "PUTHAT -2025", "url": "/" },
      { "name": "AIHMCT OAT -2025", "url": "/" },
      { "name": "MET -2025", "url": "/" },
      { "name": "Oberoi STEP -2025", "url": "/" },
      { "name": "NCHM MSc JEE -2025", "url": "/" },
      { "name": "JMI Entrance Exam -2025", "url": "/" },
      { "name": "GNIHM JET -2025", "url": "/" },
      { "name": "CUCET -2025", "url": "/" },
      { "name": "Ecole Hoteliere Lavasa Exam -2025", "url": "/" }
    ],
    "Top Colleges India": [
      { "name": "Institute of Hotel Management (IHM), Delhi", "url": "/" },
      { "name": "Oberoi Centre of Learning and Development, Delhi", "url": "/" },
      { "name": "Les Roches Global Hospitality Education, Pune", "url": "/" },
      { "name": "Welcomegroup Graduate School (WGSHA), Manipal", "url": "/" },
      { "name": "Culinary Institute of America, San Antonio Noida", "url": "/" },
      { "name": "Institute of Hotel Management (IHM), Mumbai", "url": "/" },
      { "name": "Gurudev Nursing Institute of Hotel Management (GNIHM), Kolkata", "url": "/" },
      { "name": "Chandigarh University, Chandigarh", "url": "/" },
      { "name": "Ecole Hoteliere Lavasa, Maharashtra", "url": "/" },
      { "name": "Jaipur National University, Jaipur", "url": "/" }
    ],
    "Top Colleges World": [
      { "name": "Ecole hôtelière de Lausanne (EHL), Switzerland", "url": "/" },
      { "name": "Glion Institute of Higher Education, Switzerland", "url": "/" },
      { "name": "Les Roches Global Hospitality Education, Switzerland", "url": "/" },
      { "name": "Cornell University, School of Hotel Administration, USA", "url": "/" },
      { "name": "University of Nevada, Las Vegas (UNLV), USA", "url": "/" },
      { "name": "Oxford Brookes University, UK", "url": "/" },
      { "name": "The Hong Kong Polytechnic University", "url": "/" },
      { "name": "ESSEC Business School, France/Singapore", "url": "/" },
      { "name": "Johnson & Wales University, USA", "url": "/" },
      { "name": "IMI University Centre, Switzerland", "url": "/" }
    ],
    "Resources": [
      { "name": "News & Articles", "url": "/" },
      { "name": "Hospitality Career Quiz", "url": "/" },
      { "name": "Hospitality Council", "url": "/" },
      { "name": "Hospitality Predictors", "url": "/" },
      { "name": "Top Hospitality Colleges in India", "url": "/" },
      { "name": "Hospitality Scholarships in India", "url": "/" },
      { "name": "Hospitality Admission @"+config.mobileNumber, "url": "/" }
    ]
  }


};

export default data;
