import React from "react";
import Arrow from "../../../images/Arrow.svg";
import { Link } from "react-router-dom";

interface Career {
  name: string;
  title: string;
}

interface Props {
  image?: string;
  imageUrls?: string;
  title: string;
  description?: string; // Optional for tabs that require descriptions
  careers?: Career[]; // Optional for Career Interest tab
}

const CareerCards: React.FC<Props> = ({
  image,
  imageUrls,
  title,
  description,
  careers,
}) => {
  const displayImage = image || (imageUrls && imageUrls);

  return (
    <div className="block rounded-xl overflow-hidden">
      <div className="flex flex-col gap-5 overflow-hidden">
        {displayImage && (
          <img
            src={displayImage}
            alt={title}
            className="w-full h-full rounded-xl object-cover"
          />
        )}
      </div>
      <div className="flex px-6">
        <div className="relative w-full flex flex-col bg-white border border-[#CAC6C6] p-3.5 -translate-y-6 z-2 rounded-2xl justify-center items-center gap-2.5">
          <h4 className="text-base font-bold text-blue line-clamp-2">
            {title}
          </h4>
          {careers ? (
            <ul className="font-open-sans text-sm text-center text-[#383838] list-disc list-inside">
              {careers.slice(0, 4).map((career) => (
                <Link to={`/career-details/${career.name}`}>
                  <li
                    key={career.name}
                    className=" group flex items-center gap-1 cursor-pointer"
                  >
                    <span className="hover:text-blue transition-all">
                      {career.title}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      className="transition-transform group-hover:translate-x-1 group-hover:fill-blue"
                      fill="#383838"
                    >
                      <path d="M9.85742 5.24414L5.83203 1.21875L6.88672 0.164062L12.7227 6L6.88672 11.8359L5.83203 10.7812L9.85742 6.75586H0.734375V5.24414H9.85742Z" />
                    </svg>
                  </li>
                </Link>
              ))}
            </ul>
          ) : (
            <div className="font-open-sans text-sm line-clamp-2 text-center text-[#383838]">
              {description}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerCards;
