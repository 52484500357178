import React from "react";

interface ProcessStep {
  number: string;
  title: string;
  description: string[];
  image: string;
  bgColor: string;
}

const steps: ProcessStep[] = [
  {
    number: "1",
    title: "Take the Career Assessment Test",
    description: [
      `We’ve collaborated with top psychologists and psychometricians from leading institutions to develop India’s most reliable career assessment.`,
      `Our psychometric assessment (My Choice MyFuture), created by experts from IIT-Madras and top psychology colleges, provides a scientific evaluation of your interests, personality, and abilities, helping you discover the academic and career paths where you can truly excel.`,
      `After the assessment, you’ll receive a thorough analysis and personalized career counseling from our team of experts, including psychologists, educators, mentors, and career coaches. Plus, enjoy lifelong mentoring as you embark on your chosen career journey. Start shaping your future today!`,
    ],
    image: "/images/career-discovery/step-one-ikigai.webp",
    bgColor: "#EEF2FF",
  },
  {
    number: "2",
    title: " One-on-One Session with Certified Mentors",
    description: [
      ` After your IKIGAI Career Assessment, you'll connect with certified mentors to analyze your report and identify the best career options for you. Enjoy a one-on-one session with India’s top career counselors and coaches, where you'll explore your interests, potential careers, and abilities.`,
      `Together, we'll create a personalized learning plan to help you develop essential skills and shortlist ideal career paths. `,
      `For students in Class 8-12 and college graduates, parents are welcome to join the last 15-20 minutes of the session. Plus, Plan users receive multiple career counseling sessions with our expert certified counselors and customized reports after each session.`,
    ],
    image: "/images/career-discovery/step-two-ikigai.webp",
    bgColor: "#FFF9E5",
  },
  {
    number: "3",
    title: "Mentoring with Current Students and Industry Professionals",
    description: [
      ` Once our counselors have outlined your ideal course and career path, we want you to proceed with complete confidence.`,
      `We'll connect you with top students currently pursuing the career you're interested in. Engage in conversations to get all your questions answered by those who are living the experience.`,
      `Next, you’ll also connect with professionals in the same field who have over 5 years of experience. This will give you valuable insights and help clear any remaining doubts, ensuring you move forward with 100% confidence in your ikigai!`,
    ],
    image: "/images/career-discovery/step-three-ikigai.webp",
    bgColor: "#FFF1FA",
  },
  {
    number: "4",
    title: " Exploring College Options and Applications",
    description: [
      " Once your ikigai is confirmed, we’ll help you explore college options in India and abroad.",
      "We’ll guide you through the application process, ensuring your resume and documents meet the specific requirements for studying both domestically and internationally.",

      "In India, different entrance exams correspond to your chosen stream, and there are various types of institutions, including state universities, deemed universities, private universities, autonomous colleges, and institutes of excellence. Each has its own application procedures.",
      "Our counseling and college mentor admission team will create a tracking sheet for your exams and application to admissions managing the entire process to ensure you’re well-prepared for your next steps!",
    ],
    image: "/images/career-discovery/step-four-ikigai.webp",
    bgColor: "#EEFFFF",
  },
  {
    number: "5",
    title: "Building Confidence and Managing Stress",
    description: [
      " We want every student to approach their future with 100% confidence after years of preparation to achieve their ikigai. We understand that the last month can be filled with stress, anxiety, and pressure from parents and families.",
      "To support you, we’ll connect you with health mentors who will help you manage these feelings and boost your confidence.",
      "They’ll provide guidance and motivation to keep you focused on your goals. Remember, during this critical time, it’s essential to eat well and get plenty of rest to perform at your best!",
    ],
    image: "/images/career-discovery/step-five-ikigai.webp",
    bgColor: "#EEF2FF",
  },
  {
    number: "6",
    title: "Navigating Counseling and Financial Processes",
    description: [
      " Once you’ve completed your exams, we’ll track the entire counseling process with the expertise of our College Mentor team.",
      "To support you, we’ll connect you with health mentors who will help you manage these feelings and boost your confidence.",
      "Based on your rank, they’ll guide you through counseling procedures, scholarship opportunities, and financial aid options, ensuring you’re well-prepared and avoid last-minute pressure when it comes to paying fees.",
      "Our goal is to support every student in reaching their ikigai—your chosen path—while ensuring a smooth and stress-free experience. Your success and happiness are our top priorities!",
    ],
    image: "/images/career-discovery/step-six-ikigai.webp",
    bgColor: "#FFF9E5",
  },
];

export const IKIGAIProcessSteps: React.FC = () => {
  return (
    <>
      {steps.map((step, index) => (
        <section
          key={index}
          className={`px-20 md:py-10 w-full bg-[${step.bgColor}] max-md:px-5 max-lg:max-w-full my-1`}
        >
          <div className="flex justify-between max-lg:flex-col">
            <div className="flex flex-col w-5/12 max-md:ml-0 max-lg:w-full">
              <div className="flex flex-col self-stretch my-auto text-base leading-7 text-black max-lg:mt-10 max-lg:max-w-full">
                <p className="mt-2.5 max-lg:max-w-full">
                  <span className="font-semibold">
                    Step {step.number} : {step.title}
                  </span>
                  <br />
                  {step.description[0]}
                </p>
                {step.description.slice(1).map((paragraph, i) => (
                  <p key={i} className="mt-2.5 max-lg:max-w-full">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-lg:w-full">
              <img
                loading="lazy"
                src={step.image}
                alt={`Step ${step.number}: ${step.title}`}
                className="object-contain grow w-full rounded-xl aspect-[1.45] max-lg:mt-10 max-lg:max-w-full lg:h-[434px] min-max-md:h-80"
              />
            </div>
          </div>
        </section>
      ))}
    </>
  );
};
