import React from "react";

import type { SVGProps } from "react";

export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}
interface Ambassador {
  reviewBy: string;
  studyAt: string;
  comments: string;
  branch: string;
  rating: string;
  imageUrl: string;
}

interface ChatWithAmbassadorsProps {
  title: string;
  description: string;
  ambassadors: Ambassador[];
}

const ChatWithAmbassadors: React.FC<ChatWithAmbassadorsProps> = ({
  title,
  description,
  ambassadors,
}) => (
  <section className="ChatAmbassadors">
    <div className="flex flex-col gap-4 py-5">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3 w-full lg:w-9/12">
        {title}
      </h2>
      <p
        className="text-base text-black"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 py-6">
      {ambassadors.map((ambassador, index) => (
        <div
          key={index}
          className="bg-white text-balck overflow-hidden relative border border-[#D9D9D9]"
        >
          <div className="h-[60px] bg-blue absolute w-full z-0"></div>
          <div className="relative p-6">
            <div className="flex justify-center items-center bg-[#D9D9D9] border-2 border-white rounded-full w-24 h-24 mx-auto mb-4">
              <span className="text-white text-xl font-semibold">
                {ambassador.reviewBy.split(" ")[0]}
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <h3 className="text-center text-base font-bold text-[#747474]">
                  {ambassador.reviewBy}
                </h3>
                <p className="text-center text-sm text-white bg-green py-1">
                  {ambassador.branch}
                </p>
                <p className="text-[#747474] text-sm text-center">
                  {ambassador.comments}
                </p>
              </div>
              <div className="flex justify-center">
                <button className="bg-green text-white px-4 py-2 rounded-sm font-semibold hover:bg-green-600 flex gap-1 items-center">
                  Ask me a Question <EpRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default ChatWithAmbassadors;
