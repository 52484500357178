import { Link } from "react-router-dom";
import "./CareerDetailsStyles.scss";

const SalarySection: React.FC<{ salaryDetails: any }> = ({ salaryDetails }) => {
  if (!salaryDetails) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h2 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {salaryDetails.title}
                </h2>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 salaryDetailsDescription"
                dangerouslySetInnerHTML={{
                  __html: salaryDetails.description as string,
                }}
              ></div>

              {salaryDetails.table && (
                <div className="mt-6 px-8">
                  <table className="w-full border-collapse border-[#383838] border">
                    <thead>
                      <tr className="bg-[#E9F1FC]">
                        <th className="border border-gray-300 p-3 text-left text-lg font-semibold text-gray-700">
                          Experience Level
                        </th>
                        <th className="border border-gray-300 p-3 text-left text-lg font-semibold text-gray-700">
                          Average Salary (Approx.) Per Year
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {salaryDetails.table.map((row: any, index: number) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "bg-[#F9F9F9]" : ""}
                        >
                          <td className="border border-gray-300 p-3 text-gray-700">
                            {row.experienceLevel}
                          </td>
                          <td className="border border-gray-300 p-3 text-gray-700">
                            {row.salary}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center w-full lg:w-1/5 relative max-xl:hidden">
              <div className="course-quiz-box  bg-[#C7EAFB] pt-4 sticky top-0">
                <div>
                  <img
                    src="/images/course-details/course-quiz-text.png"
                    alt="course quiz text"
                    className="mx-auto"
                  />
                </div>
                <div>
                  <img
                    src="/images/course-details/course-quiz.png"
                    alt="course quiz"
                    className=""
                  />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <span className="w-full bg-[#F6C] text-white text-xl text-center font-extrabold">
                    Take Our Quiz
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SalarySection;
