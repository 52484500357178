import { useEffect, useRef, useState } from "react";
import {
  Control,
  Controller,
  FormState,
  SubmitHandler,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import termsAndConditionsTick from "./images/terms-conditions-tick.svg";
import config from "../../util/config";
import axios from "axios";
import OTPInput from "react-otp-input";
import { GetWindowDimensions } from "../../hooks/window-hooks/WindowHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const isMobile = window.innerWidth < 1024;

  if (isMobile)
    return (
      <section id="sch-reg-form">
        <Form />
      </section>
    );
  else
    return (
      <section
        id="sch-reg-form"
        style={{
          backgroundImage: `url('${"https://cdncollegementor.blob.core.windows.net/cm-scholarship/form-bg.jpg"}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container max-w-[1200px] flex gap-6 py-12">
          <div className="w-1/2 self-end">
            <h4 className="text-2xl text-yellow text-center">
              <span className="font-light">Empowering</span>{" "}
              <strong>+2 Students with</strong>
            </h4>
            <h2 className="text-5xl text-yellow font-bold text-center">
              ₹1 Crore in Scholarships
            </h2>
            <h2 className="text-5xl text-white font-light text-center">
              Don’t miss your opportunity
            </h2>
            <p className="mt-4 text-2xl text-yellow font-light text-center">
              Need Guidance Faster? Call us at
            </p>
            <p className="text-2xl text-yellow font-light text-center">
              Phone :{" "}
              <strong className="text-white">
                +91 89595 91818 , +91 79971 66666
              </strong>
            </p>
            <p className="text-2xl text-yellow font-light text-center">
              Email :{" "}
              <strong className="text-white">admin@collegementor.com</strong>
            </p>
          </div>
          <div className="w-1/2">
            <Form />
          </div>
        </div>
      </section>
    );
};

export default RegistrationForm;

const Form = () => {
  const [registered, setRegistered] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const navigate = useNavigate();

  const handleSaveData = async (data: any) => {
    try {
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/submit",
        data,
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setRegistered(true);
      } else setRegisterError(true);
    } catch (e) {
      setRegisterError(true);
    }
  };

  useEffect(() => {
    if (registered) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 3000); // Redirecting after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [registered, navigate]); //After successfull registration redirecting to Home landing page

  return (
    <div className="bg-white p-6 rounded-xl">
      <h3 className="text-3xl font-bold">Start your journey now</h3>
      <p className="font-light">Help us with a few details</p>
      {registered && (
        <div className="text-base text-green">Registered successfully!</div>
      )}
      {registerError && (
        <div className="text-base text-red">
          Error in registering your application!
        </div>
      )}
      {!registered && !registerError && (
        <DataCollection
          saveData={handleSaveData}
          handleAccessTokenChange={setAccessToken}
        />
      )}
    </div>
  );
};

const scholarshipTypes = [
  { value: "merit-based-scholarships", label: "Merit-Based Scholarships" },
  { value: "government-school-student", label: "Government School Student" },
  { value: "empowering-lgbtq-students", label: "Empowering LGBTQ+ Students" },
  { value: "minority-scholarships", label: "Minority Scholarships" },
  { value: "sports-scholarships", label: "Sports Scholarships" },
  { value: "talent-based-scholarships", label: "Talent-Based Scholarships" },
  {
    value: "disability-scholarships",
    label: "Students With Disability Scholarships",
  },
];

const preferredStreams = [
  { value: "engineering", label: "Engineering" },
  {
    value: "medical-and-allied-sciences",
    label: "Medical and Allied Sciences",
  },
  { value: "management", label: "Management" },
  { value: "law", label: "Law" },
  { value: "liberal-arts", label: "Liberal Arts" },
  { value: "hotel-management", label: "Hotel Management" },
  {
    value: "fashion-technology-and-design",
    label: "Fashion Technology and Design",
  },
  { value: "architecture", label: "Architecture" },
  { value: "agriculture", label: "Agriculture" },
  { value: "others", label: "Others" },
];

const indianStates = [
  { value: "andhra-pradesh", label: "Andhra Pradesh" },
  { value: "arunachal-pradesh", label: "Arunachal Pradesh" },
  { value: "assam", label: "Assam" },
  { value: "bihar", label: "Bihar" },
  { value: "chhattisgarh", label: "Chhattisgarh" },
  { value: "goa", label: "Goa" },
  { value: "gujarat", label: "Gujarat" },
  { value: "haryana", label: "Haryana" },
  { value: "himachal-pradesh", label: "Himachal Pradesh" },
  { value: "jharkhand", label: "Jharkhand" },
  { value: "karnataka", label: "Karnataka" },
  { value: "kerala", label: "Kerala" },
  { value: "madhya-pradesh", label: "Madhya Pradesh" },
  { value: "maharashtra", label: "Maharashtra" },
  { value: "manipur", label: "Manipur" },
  { value: "meghalaya", label: "Meghalaya" },
  { value: "mizoram", label: "Mizoram" },
  { value: "nagaland", label: "Nagaland" },
  { value: "odisha", label: "Odisha" },
  { value: "punjab", label: "Punjab" },
  { value: "rajasthan", label: "Rajasthan" },
  { value: "sikkim", label: "Sikkim" },
  { value: "tamil-nadu", label: "Tamil Nadu" },
  { value: "telangana", label: "Telangana" },
  { value: "tripura", label: "Tripura" },
  { value: "uttar-pradesh", label: "Uttar Pradesh" },
  { value: "uttarakhand", label: "Uttarakhand" },
  { value: "west-bengal", label: "West Bengal" },
  {
    value: "andaman-and-nicobar-islands",
    label: "Andaman and Nicobar Islands",
  },
  { value: "chandigarh", label: "Chandigarh" },
  {
    value: "dadra-and-nagar-haveli-and-daman-and-diu",
    label: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { value: "delhi", label: "Delhi" },
  { value: "jammu-and-kashmir", label: "Jammu and Kashmir" },
  { value: "ladakh", label: "Ladakh" },
  { value: "lakshadweep", label: "Lakshadweep" },
  { value: "puducherry", label: "Puducherry" },
];

const preferredFeesOptions = [
  { value: "upto-1-lakh-yearly", label: "Upto 1 Lakh Yearly" },
  { value: "1-lakh-2-lakh-yearly", label: "1 Lakh - 2 Lakh Yearly" },
  { value: "2.5-lakh-3.5-lakh-yearly", label: "2.5 Lakh - 3.5 Lakh Yearly" },
  { value: "4+-lakh-yearly", label: "4+ Lakh Yearly" },
];

interface FormData {
  firstName: string;
  lastName: string;
  gender: number;
  mobile1: string;
  mobile2: string;
  email: string;
  scholarshipType: string[];
  preferredStream: string;
  preferredState: string[];
  preferredFees: string;
}

const DataCollection = ({
  saveData,
  handleAccessTokenChange,
}: {
  saveData: (data: any) => void;
  handleAccessTokenChange: (accessToken: string) => void;
}) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState,
    trigger,
    watch,
    getValues,
  } = useForm<FormData>({ mode: "onBlur" });

  const mobile = watch("mobile1");
  const isPrimaryNumberValid =
    formState?.dirtyFields?.mobile1 && formState?.errors?.mobile1 == undefined;
  const [otpValue, setOtpValue] = useState("");
  const otpValid = /^\d{6}$/.test(otpValue);

  // if otp error show error message
  const [otpError, setOtpError] = useState(false);

  const [otpSent, setOtpSent] = useState(false);
  const [numberAlreadyUsed, setNumberAlreadyUsed] = useState(false);
  const [otpLimitExceeded, setOtpLimitExceeded] = useState(false);

  // if otp verified show submit btn
  const [verified, setVerified] = useState(false);

  const { width } = GetWindowDimensions();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log("form submitted");
    saveData(data);
  };

  const handleSendOtp = async () => {
    setOtpValue("");
    setOtpError(false);
    setOtpSent(false);
    setOtpLimitExceeded(false);
    setNumberAlreadyUsed(false);
    try {
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/initiate",
        {
          phoneNumber: mobile,
          scholarShipName: "viksitBharatScholarship",
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            "X-Auth-Token":
              "e711724f44c71abf0c2794572472ed6edcea55a351f6428fe67060a692aa9316",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setOtpSent(true);
      }
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        e.response?.data?.message == "Otp limit exceeded !!"
      )
        setOtpLimitExceeded(true);
      else if (
        axios.isAxiosError(e) &&
        e.response?.data?.message == "You have already applied"
      )
        setNumberAlreadyUsed(true);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/validate-otp",
        {
          mobileNumber: mobile,
          otp: otpValue,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response?.data?.data?.body?.accessToken) {
        setVerified(true);
        const accessToken = response.data?.data?.body?.accessToken;
        handleAccessTokenChange(accessToken);
      } else {
        setOtpError(true);
      }
    } catch (e) {
      setOtpError(true);
    }
  };

  const firstNameField = (
    <div className="flex flex-col flex-1">
      <input
        {...register("firstName", {
          required: "First name is required",
          pattern: {
            value: /^[A-Za-z]+$/,
            message: " Use alphabets only",
          },
        })}
        placeholder="First name"
        className="border border-grey-4 rounded px-2 py-1 w-full"
      />
      {formState.errors.firstName ? (
        <p style={{ color: "red" }}>
          {formState.errors.firstName.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const lastNameField = (
    <div className="flex flex-col flex-1">
      <input
        {...register("lastName", {
          required: "Last name is required",
          pattern: {
            value: /^[A-Za-z]+$/,
            message: "Use alphabets only",
          },
        })}
        placeholder="Last name"
        className="border border-grey-4 rounded px-2 py-1 w-full"
      />
      {formState.errors.lastName ? (
        <p style={{ color: "red" }}>
          {formState.errors.lastName.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const emailField = (
    <div className="flex flex-col flex-1">
      <input
        {...register("email", {
          required: "Email is required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: "Invalid email address",
          },
        })}
        placeholder="Email"
        className="border border-grey-4 rounded px-2 py-1"
      />
      {formState.errors.email ? (
        <p style={{ color: "red" }}>
          {formState.errors.email.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const genderOptions = ["male", "female", "others"];

  const genderField = (
    <div className="flex flex-col">
      <select
        {...register("gender", { required: "Gender is required" })}
        className="border border-grey-4 rounded px-2 py-1"
      >
        <option value="">Select Gender</option>
        {genderOptions.map((option) => (
          <option key={option} value={option}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </option>
        ))}
      </select>
      {formState.errors.gender ? (
        <p style={{ color: "red" }}>
          {formState.errors.gender.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const mobileNumber1Field = (
    <div className="flex flex-col flex-1">
      <div className="flex items-center">
        <span className="bg-gray-100 border border-grey-4 rounded-l px-2 py-1 text-gray-500 select-none">
          +91
        </span>
        <input
          {...register("mobile1", {
            required: "Primary mobile number is required",
            pattern: {
              value: /^\d{10}$/,
              message: "Invalid primary mobile number (10 digits required)",
            },
            onChange: (e) => {
              setValue("mobile1", e.target.value); // Update value
              trigger("mobile1"); // Trigger validation manually
            },
          })}
          disabled={verified}
          placeholder="Primary mobile number"
          className="border border-grey-4 rounded-r px-2 py-1 flex-1"
        />
      </div>
      {formState.errors.mobile1 ? (
        <p style={{ color: "red" }}>
          {formState.errors.mobile1.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const mobileNumber2Field = (
    <div className="flex flex-col flex-1">
      <div className="flex items-center">
        <span className="bg-gray-100 border border-grey-4 rounded-l px-[6px] py-1 text-gray-500 select-none">
          +91
        </span>
        <input
          {...register("mobile2", {
            required: "Secondary mobile number is required",
            pattern: {
              value: /^\d{10}$/,
              message: "Invalid secondary mobile number (10 digits required)",
            },
            validate: (value) => {
              if (value === getValues("mobile1")) {
                return "Secondary mobile number must be different from the primary mobile number";
              }
              return true;
            },
          })}
          placeholder="Secondary mobile number"
          className="border border-grey-4 rounded-r px-2 py-1 flex-1"
        />
      </div>
      {formState.errors.mobile2 ? (
        <p style={{ color: "red" }}>
          {formState.errors.mobile2.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const scholarshipTypeDropdown = (
    <MultiSelectDropdown
      control={control}
      register={register}
      formState={formState}
      fieldKey="scholarshipType"
      title="Select Up to Two Scholarship Types"
      min={1}
      max={2}
      options={scholarshipTypes}
    />
  );

  const preferredStreamDropdown = (
    <div className="flex flex-col">
      <select
        {...register("preferredStream", {
          required: "Please select a preferred stream",
        })}
        className="border border-grey-4 rounded px-2 py-1"
      >
        <option value="">Your Preferred Stream</option>
        {preferredStreams.map((stream) => (
          <option key={stream.value} value={stream.value}>
            {stream.label}
          </option>
        ))}
      </select>
      {formState.errors.preferredStream ? (
        <p style={{ color: "red" }}>
          {formState.errors.preferredStream.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const preferredStateDropdown = (
    <MultiSelectDropdown
      control={control}
      register={register}
      formState={formState}
      fieldKey="preferredState"
      title="Select Up to 3 Choices of States to Study"
      min={1}
      max={3}
      options={indianStates}
    />
  );

  const preferredFeesDropdown = (
    <div className="flex flex-col">
      <select
        {...register("preferredFees", {
          required: "Please select a preferred fee range",
        })}
        className="border border-grey-4 rounded px-2 py-1"
      >
        <option value="">Your Preferred Tution Fees</option>
        {preferredFeesOptions.map((fee) => (
          <option key={fee.value} value={fee.value}>
            {fee.label}
          </option>
        ))}
      </select>
      {formState.errors.preferredFees ? (
        <p style={{ color: "red" }}>
          {formState.errors.preferredFees.message?.toString()}
        </p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalVisible]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-4 flex flex-col gap-1"
    >
      <div className="flex gap-1 lg:gap-8">
        {firstNameField}
        {lastNameField}
      </div>
      <div className="flex gap-1 lg:gap-8">
        {mobileNumber1Field}
        {!verified && (
          <button
            className="bg-blue px-4 py-1 text-white rounded h-fit disabled:opacity-40 disabled:cursor-not-allowed"
            type="button"
            disabled={!isPrimaryNumberValid}
            onClick={() => handleSendOtp()}
          >
            {!otpSent ? "Send otp" : "Resend otp"}
          </button>
        )}
      </div>
      {otpLimitExceeded && (
        <div className="text-base text-red">OTP limit exceeded!</div>
      )}
      {numberAlreadyUsed && (
        <div className="text-base text-red">
          You already applied with this number {mobile}!
        </div>
      )}
      {verified && (
        <div className="text-base text-green">
          {mobile} verified successfully!
        </div>
      )}
      {otpSent && !verified && (
        <>
          <div className="flex gap-1 lg:gap-8">
            <OTPInput
              value={otpValue}
              onChange={setOtpValue}
              numInputs={6}
              renderSeparator={false}
              renderInput={(props) => <input {...props} className="mr-2" />}
              inputStyle={{
                border: "none",
                justifyContent: "center",
                borderRadius: "8px",
                width: width > 700 ? "54px" : "34px",
                height: width > 700 ? "54px" : "34px",
                fontSize: "1.5rem",
                fontWeight: 800,
                backgroundColor: "#eee",
                color: "#000",
                display: "flex",
                outline: "none",
              }}
            />
          </div>
          <div className="flex gap-4 py-2">
            {otpValid && (
              <button
                type="button"
                className="bg-green px-4 py-1 text-white rounded h-fit"
                onClick={handleVerifyOtp}
              >
                Verify OTP
              </button>
            )}
            <button
              type="button"
              className="bg-blue px-4 py-1 text-white rounded h-fit"
            >
              Clear
            </button>
          </div>
        </>
      )}
      {genderField}
      <div className="flex max-md:flex-col gap-1 lg:gap-8">
        {mobileNumber2Field}
        {emailField}
      </div>
      {scholarshipTypeDropdown}
      {preferredStreamDropdown}
      {preferredStateDropdown}
      {preferredFeesDropdown}

      <div className="flex items-start gap-2">
        <input
          type="checkbox"
          id="acceptTerms"
          className="mt-1"
          checked={isTermsAccepted}
          onChange={(e) => setIsTermsAccepted(e.target.checked)}
        />
        <label htmlFor="acceptTerms" className="text-sm">
          I accept the{" "}
          <span
            onClick={(e) => {
              e.preventDefault();
              setIsModalVisible(true);
            }}
            className="text-blue-500 underline cursor-pointer"
          >
            terms and conditions
          </span>
        </label>
      </div>

      {isModalVisible && (
        <div className="fixed bg-black bg-opacity-50 flex justify-end items-end z-50 inset-0 max-md:justify-center max-md:items-center xl:right-2">
          <div className="bg-white rounded-lg w-[720px] p-4 shadow-lg relative mb-4">
            <button
              onClick={() => setIsModalVisible(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-black"
            >
              ✕
            </button>
            <p className="text-sm text-[#000]">
              By clicking this button i would be accepting the terms and
              conditions of Dreams and Degrees Edutech Private limited. I
              authorize Dreams and Degrees Private Limited and its associates to
              contact me with updates and notifications via Email, SMS, Whatsapp
              and Voice call as per the Privacy policy. I hereby consent that
              even if my mobile number is registered with DNC/NDNC, I would like
              to receive calls from dreams and Degrees private limited and its
              partner institutions, employees, officers, authorized agencies,
              service partners and representatives to call, manage, WhatsApp or
              email for any matter College Mentor deals with.
            </p>
          </div>
        </div>
      )}

      <div className="mt-1 flex justify-center">
        <button
          type="submit"
          className="bg-green px-4 py-1 text-white rounded disabled:opacity-40 disabled:cursor-not-allowed"
          disabled={!verified || !isTermsAccepted}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

const OtpValidation = ({
  mobile,
  handleRegister,
}: {
  mobile: string;
  handleRegister: (accessToken: string) => void;
}) => {
  const [otpValue, setOtpValue] = useState("");
  const otpValid = /^\d{6}$/.test(otpValue);

  // if otp error show error message
  const [otpError, setOtpError] = useState(false);

  const [otpSent, setOtpSent] = useState(false);
  const [numberAlreadyUsed, setNumberAlreadyUsed] = useState(false);
  const [otpLimitExceeded, setOtpLimitExceeded] = useState(false);

  // if otp verified show submit btn
  const [verified, setVerified] = useState(false);

  const { width } = GetWindowDimensions();

  const handleSendOtp = async () => {
    setOtpValue("");
    setOtpError(false);
    setOtpSent(false);
    try {
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/initiate",
        {
          phoneNumber: mobile,
          scholarShipName: "viksitBharatScholarship",
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            "X-Auth-Token":
              "e711724f44c71abf0c2794572472ed6edcea55a351f6428fe67060a692aa9316",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setOtpSent(true);
      }
    } catch (e) {
      console.log(
        axios.isAxiosError(e) &&
          e.response?.data?.message === "Otp limit exceeded !!"
      );
      if (
        axios.isAxiosError(e) &&
        e.response?.data?.message === "Otp limit exceeded !!"
      )
        setOtpLimitExceeded(true);
      else if (
        axios.isAxiosError(e) &&
        e.response?.data?.message === "You have already applied"
      )
        setNumberAlreadyUsed(true);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        config.apiUrl + "/api/scholarship/validate-otp",
        {
          mobileNumber: mobile,
          otp: otpValue,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response?.data?.data?.body?.accessToken) {
        setVerified(true);
        const accessToken = response.data?.data?.body?.accessToken;
        handleRegister(accessToken);
      } else {
        setOtpError(true);
      }
    } catch (e) {
      setOtpError(true);
    }
  };

  useEffect(() => {
    setVerified(false);
    setOtpError(false);
    setNumberAlreadyUsed(false);
    setOtpLimitExceeded(false);
    setOtpValue("");
    handleSendOtp();
  }, []);

  if (verified) return <div className="text-base">Registering!</div>;
  else if (otpLimitExceeded)
    return <div className="text-base text-red">OTP limit exceeded</div>;
  else if (numberAlreadyUsed)
    return (
      <div className="text-base text-red">
        You already applied with this number {mobile}
      </div>
    );
  else if (otpSent)
    return (
      <div className="flex flex-col gap-4">
        <p>Enter OTP sent to your mobile number {mobile}</p>
        <OTPInput
          value={otpValue}
          onChange={setOtpValue}
          numInputs={6}
          renderSeparator={false}
          renderInput={(props) => <input {...props} className="mr-2" />}
          inputStyle={{
            border: "none",
            justifyContent: "center",
            borderRadius: "8px",
            width: width > 700 ? "54px" : "34px",
            height: width > 700 ? "54px" : "34px",
            fontSize: "1.5rem",
            fontWeight: 800,
            backgroundColor: "#eee",
            color: "#000",
            display: "flex",
            // caretColor: "blue",
            outline: "none",
          }}
        />
        {otpError && <p>Invalid OTP! Please check.</p>}
        <div className="flex gap-4">
          {otpValid && (
            <button
              className="w-fit bg-blue px-4 py-1 text-white rounded"
              onClick={handleVerifyOtp}
            >
              Verify OTP
            </button>
          )}
          <button className="text-blue" onClick={handleSendOtp}>
            Resend OTP
          </button>
        </div>
      </div>
    );
  else return <div>Loading</div>;
};

const MultiSelectDropdown = ({
  control,
  register,
  formState,
  fieldKey,
  title,
  min,
  max,
  options,
}: {
  control: Control<FormData, any>;
  register: UseFormRegister<FormData>;
  formState: FormState<FormData>;
  fieldKey: "scholarshipType" | "preferredState";
  title: string;
  min: number;
  max: number;
  options: { value: string; label: string }[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchKey, setSearchKey] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  let error: string | undefined = undefined;
  if (formState.errors[fieldKey]) {
    error = formState.errors[fieldKey]?.message?.toString();
  }

  // Handling keypress for jumping to options
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen) return;

    const key = e.key.toLowerCase();
    setSearchKey(key);

    // Finding the first option starting with the pressed key
    const matchedOption = options.find((option) =>
      option.label.toLowerCase().startsWith(key)
    );

    if (matchedOption && dropdownRef.current) {
      const optionElement = dropdownRef.current.querySelector(
        `label[data-value="${matchedOption.value}"]`
      );
      optionElement?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  return (
    <div className="flex flex-col" onKeyDown={handleKeyPress} tabIndex={0}>
      <Controller
        name={fieldKey}
        control={control}
        defaultValue={[]}
        rules={{
          validate: (value) => {
            if (value.length < min)
              return `Please select at least ${min} item(s).`;
            if (value.length > max)
              return `You can select a maximum of ${max} item(s).`;
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <div className="relative">
            <button
              className="w-full pl-3 pr-1 py-0.5 border border-grey-4 rounded flex items-center justify-between"
              type="button"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span>
                {value.length > 0 ? `${value.length} selected` : title}
              </span>
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            </button>
            {isOpen && (
              <>
                <button
                  className="fixed inset-0 bg-black opacity-20 z-[1000]"
                  onClick={() => setIsOpen(false)}
                ></button>
                <div
                  className="absolute top-full rounded left-0 p-2 bg-white z-[1001] flex flex-col gap-2 max-h-48 overflow-y-auto"
                  ref={dropdownRef}
                >
                  {options.map((option) => {
                    const isSelected = value.includes(option.value);
                    const disableCheckbox = !isSelected && value.length >= max;

                    return (
                      <label
                        key={option.value}
                        data-value={option.value}
                        className={`flex items-center gap-2 ${
                          disableCheckbox ? "opacity-50" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          value={option.value}
                          checked={isSelected}
                          disabled={disableCheckbox}
                          onChange={(e) => {
                            if (e.target.checked) {
                              if (value.length < max) {
                                onChange([...value, option.value]);
                              }
                            } else {
                              if (value.length > min) {
                                onChange(
                                  value.filter((v) => v !== option.value)
                                );
                              }
                            }
                          }}
                        />
                        {option.label}
                      </label>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
      />
      {error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        <p className="opacity-0">empty</p>
      )}
    </div>
  );
};
