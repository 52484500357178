import React, { useState } from "react";
import { BannerSlides } from "./BannerSlides";
import Modal from "../../../components/Modal";
import QuizWidget, {
  QuizQuestion,
} from "../../../components/courses/QuizWidget";
import { useNavigate, useLocation } from "react-router-dom";

import type { SVGProps } from "react";
import LeadGeneration from "../../LeadGeneration";
const quizQuestions: QuizQuestion[] = [
  {
    question: "How many hours do you study daily?",
    options: [
      { answer: "Less than 2 hours" },
      { answer: "2-4 hours" },
      { answer: "4-6 hours" },
      { answer: "More than 6 hours" },
    ],
  },
  {
    question: "What is your preferred method of learning?",
    options: [
      { answer: "Self-study" },
      { answer: "Group study" },
      { answer: "Online courses or videos" },
      { answer: "Coaching or tuitions" },
    ],
  },
  {
    question: "How do you usually approach solving problems in your studies?",
    options: [
      { answer: "Practice with multiple exercises" },
      { answer: "Focus on understanding concepts deeply" },
      { answer: "Review and revise notes regularly" },
      { answer: "Depend on last-minute preparation" },
    ],
  },
  {
    question: "How do you rate your time management skills?",
    options: [
      { answer: "Very effective" },
      { answer: "Moderately effective" },
      { answer: "Needs improvement" },
      { answer: "I often procrastinate" },
    ],
  },
  {
    question: "How confident are you about your preparation?",
    options: [
      { answer: "Very confident" },
      { answer: "Somewhat confident" },
      { answer: "Not very confident" },
      { answer: "I feel underprepared" },
    ],
  },
  {
    question: "How frequently do you take mock tests?",
    options: [
      { answer: "Once a week or more" },
      { answer: "Once every two weeks" },
      { answer: "Once a month" },
      { answer: "Rarely or never" },
    ],
  },
  {
    question: "What do you usually do when you don’t understand a topic?",
    options: [
      { answer: "Ask my teacher or mentor" },
      { answer: "Search online for explanations" },
      { answer: "Skip and move to the next topic" },
      { answer: "Spend extra time figuring it out myself" },
    ],
  },
  {
    question: "How do you prefer to revise your syllabus?",
    options: [
      { answer: "Re-read notes and textbooks" },
      { answer: "Solve previous years' questions" },
      { answer: "Take quizzes or tests" },
      { answer: "Use flashcards or summaries" },
    ],
  },
  {
    question: "How do you feel about competitive exams?",
    options: [
      { answer: "Excited and motivated" },
      { answer: "Challenged but nervous" },
      { answer: "Indifferent or neutral" },
      { answer: "Overwhelmed and anxious" },
    ],
  },
  {
    question: "How often do you focus on weak areas during preparation?",
    options: [
      { answer: "Always" },
      { answer: "Often" },
      { answer: "Sometimes" },
      { answer: "Rarely or never" },
    ],
  },
];

export function LineMdArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g transform="translate(24 0) scale(-1 1)">
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path strokeDasharray={20} strokeDashoffset={20} d="M21 12H3.5">
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              dur="0.3s"
              values="20;0"
            ></animate>
          </path>
          <path
            strokeDasharray={12}
            strokeDashoffset={12}
            d="M3 12L10 19M3 12L10 5"
          >
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              begin="0.3s"
              dur="0.2s"
              values="12;0"
            ></animate>
          </path>
        </g>
      </g>
    </svg>
  );
}
const bannerData = {
  title: "Find the Best Career For You",
  description:
    "Discover how you match with more than 800 careers based on your personality, interests, experience, and ambitions.",
  shortDescription: "Reflect who you are with our career quiz",
  buttonText: "Map My Career in 2 Mins",
};

interface BannerProps {
  bannerInfo: {
    image: string;
    title: string;
    description: string;
  }[];
}

export const Banner: React.FC<BannerProps> = ({ bannerInfo }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleMapPopup = () => {
    if (!user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    } else {
      setIsPopupVisible(!isPopupVisible);
    }
  };

  return (
    <div
      className="py-16 max-sm:py-6"
      style={{
        background: "linear-gradient(180deg, #EDFCFF 0%, #89C9ED 100%)",
      }}
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-14">
          <div className="w-full lg:w-1/4">
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-5">
                <h2 className="text-5xl leading-[55px] font-bold text-blue">
                  {bannerData.title}
                </h2>
                <p className="text-base mt- text-black">
                  {bannerData.description}
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <p className="text-sm mt-3 text-black">
                  {bannerData.shortDescription}
                </p>
                <div className="flex">
                  <button
                    onClick={handleMapPopup}
                    className="bg-green arrow-btn text-white py-1.5 px-3 hover:bg-green  text-base font-bold flex flex-wrap flex-row w-auto gap-3 items-center cursor-pointer button-styles"
                  >
                    {bannerData.buttonText}
                    <LineMdArrowRight className="arrow-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-3/4">
            <BannerSlides bannerInfo={bannerInfo} />
          </div>
        </div>
      </div>
      <MapMyCareerPopup
        isOpen={isPopupVisible}
        onClose={() => {
          setIsPopupVisible(false);
        }}
      />
    </div>
  );
};

const MapMyCareerPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <QuizWidget questions={quizQuestions} destinationLink="" />{" "}
    </Modal>
  );
};
