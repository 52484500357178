import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import axios from "axios";
import config from "../../util/config";
interface CourseDetailsContextProps {
  data: any | null;
  loading: boolean;
}

const CourseDetailsContext = createContext<
  CourseDetailsContextProps | undefined
>(undefined);

export const useCourseDetails = (): CourseDetailsContextProps => {
  const context = useContext(CourseDetailsContext);
  if (!context) {
    throw new Error(
      "useCourseDetails must be used within a CourseDetailsProvider"
    );
  }
  return context;
};
interface CourseDetailsProviderProps {
  courseName: string;
  children: ReactNode;
}

export const CourseDetailsProvider: React.FC<CourseDetailsProviderProps> = ({
  courseName,
  children,
}) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + `/api/course/get-course-details/${courseName}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <CourseDetailsContext.Provider value={{ data, loading }}>
      {children}
    </CourseDetailsContext.Provider>
  );
};
