import { title } from "process";
import React from "react";

interface OverviewSectionProps {
  title: string;
  content: string;
}

const OverviewSection: React.FC<OverviewSectionProps> = ({
  title,
  content,
}) => {
  return (
    <div className="overviewtab">
      <h2 className="text-5xl font-normal text-blue">{title}</h2>
      <div className="text-base text-black whitespace-pre-line collageOveriew">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default OverviewSection;
