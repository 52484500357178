import React, { useState, useRef, useEffect } from "react";
import BlogTab from "./BlogTab";
import CourseSpotlightsTab from "./CourseSpotlightsTab";
import CourseVisualStoriesTab from "./CourseVisualStoriesTab";
import WebinarsTab from "./WebinarsTab";
import "./ExploreMore.scss";
interface SpotLight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
}

interface VisualStory {
  id: number;
  title: string;
  description: string;
  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}

interface Article {
  publishDate: string;
  description: string;
  title: string;
  slug: string;
}

interface Webinar {
  date: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}

interface ExploreMore {
  spotLight: SpotLight[];
  visualStories: VisualStory[];
  articles: Article[];
  webinar: Webinar[];
}
interface ExploreMoreProps {
  exploreMore: ExploreMore;
}

const ExploreMore: React.FC<ExploreMoreProps> = ({ exploreMore }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({ marginLeft: 0, width: 0 });
  const tabRef = useRef<HTMLButtonElement[]>([]);
  console.log(exploreMore);
  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setIndicatorToActiveTab(index);
  };

  const handleTabMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabRect = event.currentTarget.getBoundingClientRect();
    const containerRect =
      event.currentTarget.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;
    const halfWidth = tabRect.width / 2;
    const marginLeft =
      distanceTabStartToContainerStart + (tabRect.width - halfWidth) / 2;

    setTabIndicatorStyle({
      marginLeft: `${marginLeft}px`,
      width: `${halfWidth}px`,
    });
  };

  const handleTabMouseLeave = () => {
    setIndicatorToActiveTab(selectedTab);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;
    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;

    setTabIndicatorStyle({
      marginLeft: `${distanceTabStartToContainerStart + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    setIndicatorToActiveTab(selectedTab);
  }, [selectedTab]);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col justify-between gap-4 xl:w-4/5">
          <h3 className="text-3xl md:text-[40px] font-bold md:leading-[58px] text-black m-0">
            Explore More
          </h3>
        </div>
        <div className="flex flex-col gap-7">
          <div className="relative">
            <div
              className="tab-container gap-4 lg:gap-20"
              onMouseLeave={handleTabMouseLeave}
            >
              {["Blogs & Articles"].map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => (tabRef.current[index] = el!)}
                  onClick={() => handleTabClick(index)}
                  onMouseEnter={handleTabMouseEnter}
                  className={`py-2 text-xs font-semibold leading-[1.375rem] font-open-sans md:text-xl hover:text-[#173CBA] transition-all duration-300 ease-in-out ${
                    selectedTab === index
                      ? " text-[#173CBA] font-bold"
                      : "font-normal text-[#A8A8A8]"
                  }`}
                >
                  {tab}
                </button>
              ))}
              <div
                className="absolute bottom-[-4px] h-[6px] bg-[#00C798]  rounded-lg transition-all duration-300 ease-in-out"
                style={tabIndicatorStyle}
              />
            </div>
          </div>
          {selectedTab === 0 && <BlogTab articles={exploreMore.articles} />}
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;
