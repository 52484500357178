import React, { useEffect, useState } from "react";
import axios from "axios";

import Preparation from "./components/Preparation";
import Predictor from "./components/Predictor";
import Mocktests from "./components/Mocktests";
import Examjourneys from "./components/Examjourneys";
import Examalerts from "./components/Examalerts";
import Subscribe from "./components/Subscribe";
import Popularexams from "./components/popularexams/Popularexams";
import Loader from "../../components/Loader/Loader";
import SamplePapers from "./components/SamplePapers";
import PrepareSuccess from "./components/PrepareSuccess";
import ExploreRankPredictor from "./components/ExploreRankPredictor";
import ExamBanner from "./components/banner/Banner";
import { FeaturedExams } from "./components/FeaturedExams";
import { ExploreMockTestSeries } from "./components/ExploreMockTestSeries";
import ExamDownloads from "./components/ExamDownloads";
import DreamersToAchiever from "./components/dreamersachiever/DreamersToAchiever";

import NewsAlerts from "./components/NewsAlerts";
import ExcelinCompetitiveExams from "./components/competitiveexams/ExcelinCompetitiveExams";
import ExploreTopAdmissionMentors from "./components/exploretopadmission/ExploreTopAdmissionMentors";
import { ExamResources } from "./components/exam-resources/ExamResources";
import config from "../../util/config";

import { ExamLandingMockResponse } from "./mock/mockData";
export const Exams = () => {
  const [examData, setExamData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/exam/getexamLandingPage"
        );
        setExamData(response.data.data);

        setLoading(false);
      } catch (error) {
        // setError(error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, []);

  if (loading) {
    return <Loader />;
  }
  const examBannerData = {
    examBanner: ExamLandingMockResponse.examBanner,
  };
  const featuredExamsData = {
    featuredExams: ExamLandingMockResponse.featuredExams,
  };

  const downloadsData = {
    downloads: ExamLandingMockResponse.downloads,
  };
  return (
    <div>
      <ExamBanner data={examBannerData} />
      <FeaturedExams data={featuredExamsData} />
      <ExcelinCompetitiveExams />
      <NewsAlerts />
      <PrepareSuccess />
      <ExploreRankPredictor />
      {/* <ExploreMockTestSeries data={examData} /> */}
      <ExploreTopAdmissionMentors data={examData} />
      {/* {data?.mockTest && <Mocktests data={data.mockTest} />} */}
      <ExamDownloads data={downloadsData} />
      <DreamersToAchiever />
      <ExamResources />
      {/* <Predictor /> */}
      {/* <Preparation /> */}
      {/* <Popularexams /> */}

      {/* <Examjourneys /> */}
      {/* <Examalerts /> */}
      {/* <SamplePapers /> */}
      {/* <Subscribe /> */}
    </div>
  );
};
