import React, { useState, useRef, useEffect } from "react";
import "./DreamCareersStyles.scss";
import { Link } from "react-router-dom";

import type { SVGProps } from "react";

export function IcBaselinePlayArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="currentColor" d="M8 5v14l11-7z"></path>
    </svg>
  );
}

export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14px"
      height="14px"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}

export function LineMdPlayToPauseTransition(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 15L8 18L8 6L13 9L13 9M13 9L18 12L18 12L13 15L13 15"
      >
        <animate
          fill="freeze"
          attributeName="d"
          dur="0.6s"
          keyTimes="0;0.33;1"
          values="M13 15L8 18L8 6L13 9L13 9M13 9L18 12L18 12L13 15L13 15;M13 15L8 18L8 6L13 9L13 15M13 9L18 12L18 12L13 15L13 9;M9 18L7 18L7 6L9 6L9 18M15 6L17 6L17 18L15 18L15 6"
        ></animate>
      </path>
    </svg>
  );
}

export function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 18L18 6M6 6l12 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LineMdArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path strokeDasharray={20} strokeDashoffset={20} d="M3 12h17.5">
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            dur="0.2s"
            values="20;0"
          ></animate>
        </path>
        <path
          strokeDasharray={12}
          strokeDashoffset={12}
          d="M21 12l-7 7M21 12l-7 -7"
        >
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            begin="0.2s"
            dur="0.2s"
            values="12;0"
          ></animate>
        </path>
      </g>
    </svg>
  );
}
interface Occupation {
  name: string;
  icon: string;
  slug: string;
}

interface Career {
  name: string;
  iconUrl: string;
  title: string;
  occupations: Occupation[];
}

interface DiscoverDream {
  videoUrl: string;
  imageUrl: string;
  title: string;
}

interface DreamCareersProps {
  dreamCareers: {
    discoverDream: DiscoverDream;
    careers: Career[];
  };
}
const DreamBgColors = [
  "#F7F7F7",
  "#E8FDF6",
  "#F7F7E3",
  "#E5F1FB",
  "#FBE0E6",
  "#F7F7E3",
  "#DFFBDB",
];

const DreamCareers: React.FC<DreamCareersProps> = ({ dreamCareers }) => {
  const { discoverDream, careers } = dreamCareers;
  const [expandedCareerIndex, setExpandedCareerIndex] = useState<number | null>(
    null
  );
  const expandRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [heights, setHeights] = useState<number[]>([]);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [showAll, setShowAll] = useState(false);

  const defaultVideoUrl =
    "https://cdncollegementor.blob.core.windows.net/careers/career_landing/dream-career-video.mp4";
  const videoUrl = discoverDream?.videoUrl || defaultVideoUrl;

  const handleVideoError = () => {
    if (videoRef.current) {
      videoRef.current.src = defaultVideoUrl;
      videoRef.current.play();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (videoRef.current && entry.isIntersecting) {
            videoRef.current.play();
            setIsPlaying(true);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      expandedCareerIndex !== null &&
      expandRefs.current[expandedCareerIndex]
    ) {
      const updatedHeights = [...heights];
      updatedHeights[expandedCareerIndex] =
        expandRefs.current[expandedCareerIndex]?.scrollHeight || 0;
      setHeights(updatedHeights);
    }
  }, [expandedCareerIndex, showAll, careers]);

  const handleCareerClick = (index: number) => {
    if (expandedCareerIndex === index) {
      setExpandedCareerIndex(null);
      return;
    }

    const updatedHeights = [...heights];
    if (expandRefs.current[index]) {
      updatedHeights[index] = expandRefs.current[index]?.scrollHeight || 0;
    }

    setExpandedCareerIndex(index);
    setHeights(updatedHeights);
  };

  const togglePlayback = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="py-8 max-sm:py-4">
      <div className="container mx-auto">
        <div className="flex justify-center items-center">
          <h3 className="text-black text-5xl font-extrabold mb-6 ">
            Dream Careers
          </h3>
        </div>
        <div className="flex flex-col lg:flex-row gap-1 h-full">
          <div className="w-full lg:w-9/12 relative">
            <ul className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-1">
              {careers.map((career, index) => {
                const bgColor = DreamBgColors[index % DreamBgColors.length];
                const isExpanded = expandedCareerIndex === index;
                const contentHeight = heights[index] || 0;
                const totalHeight = 151 + contentHeight;

                return (
                  <li
                    key={index}
                    className={`dreamCareerSec transition-all duration-300 ${
                      isExpanded ? "expanded mb-10" : ""
                    }`}
                    style={{
                      height: isExpanded ? `${151 + contentHeight}px` : "151px",
                    }}
                  >
                    <div
                      className="dreamCareerIcon relative p-4 border flex flex-col gap-3 justify-center items-center cursor-pointer"
                      style={{ backgroundColor: bgColor }}
                      onClick={() => handleCareerClick(index)}
                    >
                      <img
                        src={career.iconUrl}
                        alt={career.title}
                        className="w-14 h-14 object-cover mb-2"
                      />
                      <h5 className="text-sm text-black text-center">
                        {career.title}
                      </h5>
                    </div>

                    <div
                      className={`dreamCareerSecChild z-10 transition-all duration-300 overflow-hidden relative`}
                      ref={(el) => (expandRefs.current[index] = el)}
                      style={{
                        maxHeight: isExpanded ? `${contentHeight}px` : "0",
                        transition: "max-height 0.3s ease",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="bg-[#F8F8F8] shadow-md p-4 border border-[#cacaca] rounded-lg">
                        <button
                          className="closeButton absolute top-2 right-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandedCareerIndex(null);
                          }}
                        >
                          <CloseIcon />
                        </button>
                        <div className="careerExpandHeader flex flex-col gap-4">
                          <h6 className="text-blue text-base lg:text-lg font-bold">
                            Explore occupations by {career.title}
                          </h6>
                          <p className="text-sm text-black">
                            Select a learning area that interests you to view
                            related occupations:
                          </p>
                          {career.occupations.length > 0 ? (
                            <div className="flex flex-col">
                              <ul className="list-none grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                {career.occupations
                                  .slice(
                                    0,
                                    showAll ? career.occupations.length : 4
                                  )
                                  .map((occupation, occIndex) => (
                                    <li
                                      key={occIndex}
                                      className="flex items-center gap-2 border-[#E0E0E0] border rounded-full overflow-hidden"
                                    >
                                      <Link
                                        to={`/career-details/${occupation.slug}`}
                                        className="text-sm text-blue-500 hover:underline flex gap-3 items-center cursor-pointer"
                                      >
                                        <div className="w-12 h-12 overflow-hidden">
                                          <img
                                            src={occupation.icon}
                                            alt={occupation.name}
                                            className="w-full h-full object-cover"
                                          />
                                        </div>
                                        <div className="text-sm font-medium text-black">
                                          {occupation.name}
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                              {career.occupations.length > 4 && (
                                <div className="mt-5 text-center w-full flex items-center justify-center">
                                  <button
                                    onClick={toggleShowAll}
                                    className="text-blue text-sm font-medium hover:underline flex items-center gap-1"
                                  >
                                    {showAll ? "Show less" : "View more"}
                                    {!showAll && <EpRight />}
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <p className="text-sm text-gray-500">
                              No occupations available for this career.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="w-full lg:w-3/12 hidden lg:flex flex-col">
            <div className="flex flex-col gap-4 relative w-full h-full">
              <div className="flex flex-col relative h-full lg:h-[600px]">
                <video
                  ref={videoRef}
                  src={videoUrl}
                  className="w-full h-full object-cover"
                  muted={isMuted}
                  controls={false}
                  autoPlay
                  loop
                  playsInline
                  onError={handleVideoError}
                ></video>
                <div className=" absolute top-1 left-1">
                  <p className="text-white text-sm">{discoverDream?.title}</p>
                </div>
              </div>
              <div className="absolute bottom-2 right-2 flex gap-2">
                <button
                  className="bg-white rounded-full p-2"
                  onClick={togglePlayback}
                >
                  {isPlaying ? (
                    <LineMdPlayToPauseTransition />
                  ) : (
                    <IcBaselinePlayArrow />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamCareers;
