import React from "react";
import CareerDiscoveryFAQ from "./components/CareerDiscoveryFAQ";
import { CareerDiscoveryBanner } from "./components/CareerDiscoveryBanner";
import { ChildrenFuture } from "./components/ChildrenFuture";
import { IkigaiCareerPath } from "./components/IkigaiCareerPath";
import { HowItWorks } from "./components/HowItWorks";
import DiscoveringGoals from "./components/DiscoveringGoals";

import { IKIGAIProcessSteps } from "./components/IKIGAIProcessSteps";
import { IkigaiDiscoveryProgram } from "./components/IkigaiDiscoveryProgram";
import IkigaiMentorSection from "./components/IkigaiMentorSection";
import { IkigaiPricingPlan } from "./components/IkigaiPricingPlan";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";

const CareerDiscoveryIkigai = () => {
  const projectName = "College Mentors";
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Career Discovery with Ikigai | {projectName}</title>
          <meta
            name="description"
            content="Explore the Ikigai methodology to discover your true career potential. Find your purpose, passion, and profession through guided mentorship."
          />
          <meta
            name="keywords"
            content="Ikigai, Career Discovery, Mentorship, Career Guidance, Find Passion, Career Goals"
          />
          <link
            rel="canonical"
            href={`${config.apiUrl}/ikigai-career-discovery`}
          />

          <meta
            property="og:title"
            content="Career Discovery with Ikigai | Find Your Purpose"
          />
          <meta
            property="og:description"
            content="Discover your purpose and passion through the Ikigai Career Discovery program."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Career Discovery with Ikigai | Find Your Purpose"
          />
          <meta
            name="twitter:description"
            content="Explore the Ikigai methodology to discover your true career potential."
          />
          <meta name="twitter:image" content={logo} />
        </Helmet>
      </HelmetProvider>
      <div>
        <CareerDiscoveryBanner />
        <IkigaiDiscoveryProgram />
        <ChildrenFuture />
        <IkigaiCareerPath />
        <HowItWorks />
        <IKIGAIProcessSteps />
        <DiscoveringGoals />
        <IkigaiMentorSection />
        <IkigaiPricingPlan />
        <CareerDiscoveryFAQ />
      </div>
    </>
  );
};

export default CareerDiscoveryIkigai;
