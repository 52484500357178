import React, { useState } from "react";
import BannerImg from "../../images/study-abroad/University/BannerHigherEducation.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";

interface BannerSectionProps {
  data: {
    title: string;
    description: string;
    paragraphText: string;
    buttonText: string;
  };
}

const StudyUniversityBanner: React.FC<BannerSectionProps> = ({ data }) => {
  return (
    <section className="bg-[#D9F6EF] py-12 md:py-16 lg:px-24">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-7 lg:gap-14">
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-4">
                <h2 className="text-blue text-4xl lg:text-5xl font-light max-lg:font-bold">
                  {data.title}
                </h2>
                <p
                  className="text-base text-black"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></p>
                <p
                  className="text-base text-black"
                  dangerouslySetInnerHTML={{ __html: data.paragraphText }}
                ></p>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <LeadGenCtaBtn
                    pageName="study-abroad-university"
                    ctaName="connect-to-marketing-head"
                    className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
                  >
                    {data.buttonText} <EpRight className="green-arrow-icon" />
                  </LeadGenCtaBtn>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="rounded-lg">
              <div>
                <img
                  src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/University/studyabroad-university-banner.png"
                  alt="BannerImage"
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudyUniversityBanner;
