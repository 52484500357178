import React from "react";
import {
  MdiHeartOutline,
  MingcuteQuestionLine,
  SiDownloadOfflineLine,
} from "../../screens/college-details/CollegeDetails";
import { Link } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

interface Button {
  text: string;
  link: string;
  type: "primary" | "secondary";
}

interface BannerProps {
  data: {
    examTitle: string;
    examAbout: string;
    buttons: Button[];
    examBanner: string;
  };
}

const Banner: React.FC<BannerProps> = ({ data }) => {
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    {
      name: "List of Exams in India",
      path: "/list-of-exams-in-india",
    },
    {
      name: data.examTitle,
      path: `/course-details/${data.examTitle}`,
    },
  ];
  return (
    <div
      className="flex flex-col lg:flex-row items-center bg-[#0a0a5c] text-white py-16 px-4 lg:px-10 relative"
      style={{
        backgroundImage:
          window.innerWidth >= 767 ? `url(${data.examBanner})` : "none",
        backgroundSize: "contain",
        backgroundPosition: "right center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="lg:w-1/2 space-y-4">
        <div className="relative lg:absolute lg:top-10 lg:mb-7 text-[#00CC99] font-open-sans text-sm">
          <Breadcrumbs links={breadcrumbLinks} />
        </div>
        <h1 className="text-4xl font-bold">{data.examTitle}</h1>
        <p className="text-xl">{data.examAbout}</p>
        <div className="flex flex-col gap-7">
          <div className="flex items-center space-x-6">
            <div className="flex gap-4 mt-8">
              <button className="border-none bg-transparent text-base text-green font-bold flex gap-1.5 items-center">
                <MdiHeartOutline /> <span className="text-white">Save</span>
              </button>
              <Link
                to={"/college-compare"}
                className="border-none bg-transparent text-base font-bold text-green flex gap-1.5 items-center"
              >
                <MingcuteQuestionLine />
                <span className="text-white">Compare College</span>
              </Link>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            {data.buttons.map((button, index) => (
              <a
                key={index}
                href={button.link}
                className={`button-styles green-bg items-center rounded-none ${
                  button.type === "primary"
                    ? "bg-green hover:bg-blue text-white"
                    : "bg-green text-green hover:bg-blue hover:text-white"
                }`}
              >
                {button.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
