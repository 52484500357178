import { useState } from "react";
import img from "../../images/courses/College-Mentors-You-Can-Count-On.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const accordionData = [
  {
    title: "Student Buddy Mentor",
    description:
      "A student buddy mentor provides peer support and guidance, helping new students integrate and thrive in their academic journey. They offer encouragement and practical advice to enhance student experience and confidence.",
  },
  {
    title: "Career Mentor",
    description:
      "A career mentor offers guidance and expertise to help individuals navigate career paths and achieve professional goals. They provide insights, networking opportunities, and support in career development.",
  },
  {
    title: "Health Mentor",
    description:
      "A mental health mentor provides support and strategies to promote emotional well-being and resilience. They offer guidance on managing stress, accessing resources, and maintaining a healthy balance.",
  },
  {
    title: "Admission Mentor",
    description:
      "An admission mentor assists students through the college application process, offering personalized guidance and expertise. They ensure students navigate admissions successfully and achieve their educational aspirations.",
  },
  {
    title: "Scholarship Mentor",
    description:
      "A scholarship mentor helps students navigate the scholarship application process, offering guidance and support to secure funding for their education. They provide strategies, resources, and encouragement to maximize opportunities.",
  },
  {
    title: "Loan Mentor",
    description:
      "A loan mentor guides students through understanding and managing student loans, offering advice on borrowing responsibly and navigating repayment options. They provide support to ensure financial decisions align with long-term goals.",
  },
];

const CollegeMentors = () => {
  return (
    <div className="bg-pink-light py-12 max-sm:py-6">
      <div className="container max-w-screen-3xl flex flex-col md:flex-row gap-6 md:gap-16">
        <div className="w-full md:w-3/5">
          <h2 className="text-3xl md:text-5xl text-black font-bold font-roboto">
            College Mentors You Can Count On
          </h2>
          <p className="text-base md:text-xl text-grey font-open-sans mt-4">
            Each college mentor is professional-certified and trained for
            online-offline mentoring, receiving ongoing professional
            development. They personalize education to meet student needs and
            inspire success.
          </p>
          <div className="mt-12 flex flex-col gap-2 p-4 rounded-lg bg-white max-sm:mt-3">
            {accordionData.map((item, index) => (
              <>
                {index > 0 && <div className="border-t border-t-grey-3"></div>}
                <AccordionItem {...item} />
              </>
            ))}
          </div>
        </div>
        <div className="w-full md:w-2/5">
          <img
            src="https://cdncollegementor.blob.core.windows.net/courses/courses-landing/College-Mentors-You-Can-Count-On.png"
            className="w-full mx-auto"
            alt="college-mentors"
          />
          <p className="text-base md:text-xl text-grey font-open-sans mt-6">
            “Six mentors dedicated to one student for comprehensive,
            personalized guidance across all aspects of academic and personal
            development, ensuring holistic mentoring and success."
          </p>
        </div>
      </div>
    </div>
  );
};

export default CollegeMentors;

const AccordionItem = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="py-2 group">
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex items-center justify-between cursor-pointer"
      >
        <h5 className="text-sm md:text-xl">{title}</h5>
        <div className="group-hover:-translate-x-2 transition ease-in-out">
          <FontAwesomeIcon
            icon={isOpen ? faMinusCircle : faPlusCircle}
            size="lg"
            className="text-blue"
          />
        </div>
      </div>
      {isOpen && (
        <>
          <p className="text-xs md:text-base text-grey font-open-sans">
            {description}
            <Link
              to={"/mentors"}
              className="inline-block pl-2 transition-all hover:pl-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="#173cba"
              >
                <path d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z" />
              </svg>
            </Link>
          </p>
        </>
      )}
    </div>
  );
};
