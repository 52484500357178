import React from "react";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import TopRecruiters from "../placements/TopRecruiters";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface SectionProps {
  title: string;
  description: string;
  orderIndex?: string;
}

interface PlacementsTabProps {
  placementData: {
    [key: string]: SectionProps | College[] | undefined;
  };
}

const PlacementSection: React.FC<SectionProps> = ({ title, description }) => {
  return (
    <section className="placement-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const PlacementsTab: React.FC<PlacementsTabProps> = ({ placementData }) => {
  const similarColleges = placementData.similarColleges as College[];
  const keys = Object.keys(placementData)
    .filter((key) => key.startsWith("key_") && placementData[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (placementData[a] as SectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (placementData[b] as SectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            <div className="flex flex-col gap-5">
              {keys.map((key) => {
                const section = placementData[key] as SectionProps;
                if (!section?.title || !section?.description) {
                  return null;
                }

                if (key === "key_6") {
                  return (
                    <TopRecruiters
                      key={key}
                      title={section.title}
                      description={section.description}
                    />
                  );
                }

                return (
                  <PlacementSection
                    key={key}
                    title={section.title}
                    description={section.description}
                  />
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-3/12 hidden lg:block">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 && (
          <div className="container mx-auto mt-8">
            <SimilarEngineeringColleges colleges={similarColleges} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlacementsTab;
