import React, { useState } from "react";
import collegeLogo from "../../../images/college/details/college-logo.png";
import ChatOptions from "./ChatOptions";
import {
  MdiHeartOutline,
  MingcuteQuestionLine,
  SiDownloadOfflineLine,
} from "../../../screens/college-details/CollegeDetails";
import { Link } from "react-router-dom";

import type { SVGProps } from "react";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";

export function TdesignArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.5 11h11.586l-4.5-4.5L13 5.086L19.914 12L13 18.914L11.586 17.5l4.5-4.5H4.5z"
      ></path>
    </svg>
  );
}
interface CollegeBannerProps {
  collegeInfo: {
    collegeTitle: string;
    collegeDescription: string;
    collegeType: string;
    videoUrl?: string;
    bannerUrl?: string;
    collegeLogo?: string;
  };
}

const CollegeBanner: React.FC<CollegeBannerProps> = ({ collegeInfo }) => {
  const [showChatOptions, setShowChatOptions] = useState(true);

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    {
      name: "List of Collges in India",
      path: "/list-of-colleges-in-india",
    },
    {
      name: collegeInfo.collegeTitle,
      path: `/college-details/${collegeInfo.collegeTitle}`,
    },
  ];
  console.log("Video URL:", collegeInfo.videoUrl);

  return (
    <div className="college-banner flex flex-col bg-[#f1f1f1] py-16 max-sm:py-8 px-5 lg:px-0">
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="w-full lg:w-[45%] flex flex-col min-h-full justify-between">
          <div className="relative lg:absolute lg:px-20  lg:mb-7 text-[#0c9] font-open-sans text-sm">
            <Breadcrumbs links={breadcrumbLinks} />
          </div>
          <div className="lg:px-20 lg:py-12 flex justify-center flex-col items-center h-full">
            <div className="flex flex-col justify-center items-left gap-5 max-sm:gap-4">
              <div className="flex flex-col gap-1">
                <img
                  src={collegeInfo?.bannerUrl}
                  alt="College"
                  className="w-28 h-auto overflow-hidden"
                />
                <h1 className="text-xl font-normal">
                  {collegeInfo?.collegeTitle}
                </h1>
                <p
                  className="text-sm font-light"
                  dangerouslySetInnerHTML={{
                    __html: collegeInfo?.collegeType,
                  }}
                />
              </div>

              <p
                className="text-xl text-black mt-8 font-normal"
                dangerouslySetInnerHTML={{
                  __html: collegeInfo?.collegeDescription,
                }}
              />
              <div className="flex gap-4 mt-8 max-sm:mt-2">
                <button className="border-none bg-transparent text-base text-green font-bold flex gap-1.5 items-center">
                  <MdiHeartOutline /> <span className="text-black">Save</span>
                </button>
                <Link
                  to={"/college-compare"}
                  className="border-none bg-transparent text-base font-bold text-green flex gap-1.5 items-center"
                >
                  <MingcuteQuestionLine />
                  <span className="text-black">Compare College</span>
                </Link>
                <button className="border-none bg-transparent text-base font-bold text-green flex gap-1.5 items-center max-md:hidden md:hidden">
                  <SiDownloadOfflineLine />{" "}
                  <span className="text-black">Download Guide</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-[55%] flex justify-center items-center rounded-2xl">
          {collegeInfo?.videoUrl ? (
            <video
              width="100%"
              height="100%"
              className="rounded-2xl"
              muted
              autoPlay
              playsInline
              loop
              poster={collegeInfo?.bannerUrl || "/default-poster.jpg"}
            >
              <source src={collegeInfo.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={collegeInfo?.bannerUrl || collegeInfo.collegeLogo}
              alt="College Banner"
              className="rounded-2xl w-full h-auto"
            />
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <button
          onClick={() => setShowChatOptions(!showChatOptions)}
          className="absolute top-[35%] right-0 transform -translate-y-[35%] p-2 bg-blue text-white z-50"
        >
          {showChatOptions ? (
            <TdesignArrowRight />
          ) : (
            <TdesignArrowRight className="rotate-180" />
          )}
        </button>

        <div
          className={`absolute top-0 right-0 h-full z-10 transition-transform duration-500 ease-in-out ${
            showChatOptions ? "translate-x-0" : "translate-x-full"
          }`}
          style={{ width: "300px" }}
        >
          <ChatOptions collegeTitle={collegeInfo?.collegeTitle || ""} />
        </div>
      </div>
    </div>
  );
};

export default CollegeBanner;
