import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Banner from "./components/Banner";
import FeaturedPredictors from "./components/FeaturedPredictors";
import PredictorTools from "./components/PredictorTools";
import KnowledgeHub from "./components/KnowledgeHub";
import { update } from "../../../store/slices/predictorLanding"; // Correct import path
import News from "./components/News";
import FeaturedColleges from "../../colleges-new/landing/components/FeaturedColleges";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import EntranceExams from "./components/EntranceExams";
import TestimonialPredictor from "./components/TestimonialPredictor";
import Blogs from "./components/Blogs";
import Footer from "./components/Footer";
import config from "../../../util/config";
import collegeLandingMock from "../../exams/mock/mockData";

const CollegePredictorLanding: React.FC = () => {
  const { featuredColleges } = collegeLandingMock;
  const dispatch = useDispatch();
  const [newsAlertsData, setNewsAlertsData] = useState<any[]>([]);
  const [blogsArticleData, setArticles] = useState<any[]>([]);

  // const applyToDirectAdmissions = useSelector(
  //   (state: RootState) => state.predictorLanding.directAdmissions
  // );

  useEffect(() => {
    // Fetching Predictor Landing page data
    axios
      .get(config.apiUrl + "/api/college/predictorLandingPage")
      .then((response) => {
        dispatch(update(response.data.data));
      })
      .catch((error) => {
        console.error("Failed to fetch data", error);
      });

    // Fetching blogs and news data
    axios
      .get(`${config.apiUrl}/api/blogs/blogsandnews`)
      .then((response) => {
        const sections = response?.data?.sections?.[0];

        // Extracting latest news
        const latestNews =
          sections?.["Exams And Admissions"]?.subcategories?.["Latest News"]
            ?.results || [];
        setNewsAlertsData(latestNews);

        // Extracting Articles & Blogs
        const data = sections?.["College And Scholarship"]?.subcategories;
        const fetchedArticles = data?.["Articles & Blogs"]?.results || [];
        setArticles(fetchedArticles);
      })
      .catch((error) => {
        console.error("Error fetching blogs and news data", error);
      });
  }, [dispatch]);

  return (
    <div className="font-inter">
      <Banner />
      <FeaturedPredictors />
      <PredictorTools />
      <FeaturedColleges
        title="Apply To Direct Admissions"
        featuredColleges={featuredColleges}
      />
      <News newsAlertsData={newsAlertsData} />
      <EntranceExams />
      <TestimonialPredictor />
      <Blogs blogsArticleData={blogsArticleData} />
      <KnowledgeHub />
      <Footer />
    </div>
  );
};

export default CollegePredictorLanding;
