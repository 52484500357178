import { FC } from "react";
import CustomSwiper from "../../../components/Slider/CustomSwiper";
import "./SuccessStories.scss";

interface ICard {
  story: string;
  userName: string;
  userEmail: string;
  userImage: string;
}

const SuccessStories = () => {
  // Mock data for success stories
  const mockData: ICard[] = [
    {
      story:
        "Raj evaluated medical colleges through a comparison tool and, guided by his mentor, is now specializing at a renowned hospital.",
      userName: "Raj",
      userEmail: "Student@Pennsylvania",
      userImage: "/images/college-compare/success-img.png",
    },
    {
      story:
        "Amit used a college comparison tool to choose a top engineering institute in India and, with mentor support, secured a tech job after excelling in his studies.",
      userName: "Amit",
      userEmail: "Student@Pennsylvania",
      userImage: "/images/college-compare/success-img.png",
    },
    {
      story:
        "Sneha compared business schools to find the best fit for entrepreneurship, and with her mentor's help, launched a successful startup after graduating.",
      userName: "Sneha",
      userEmail: "Student@Pennsylvania",
      userImage: "/images/college-compare/success-img.png",
    },
    {
      story:
        "Priya explored global universities using a comparison platform and secured a full scholarship at a prestigious institution to pursue her dream career in artificial intelligence.",
      userName: "Priya",
      userEmail: "Student@Harvard",
      userImage: "/images/college-compare/success-img.png",
    },
  ];

  return (
    <div className="success-story py-12 bg-[#DAFFF7] text-black">
      <div className="container overflow-hidden">
        <h1 className="text-4xl lg:text-5xl font-bold text-center">
          Success Stories
        </h1>
        <CustomSwiper
          className="mt-4 max-md:w-[200vw] max-md:-translate-x-[27%]"
          items={mockData.map((item: ICard) => (
            <Card {...item} />
          ))}
          navigation={"bottom"}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: 3, spaceBetween: 40 },
          }}
          autoplay={true}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default SuccessStories;

const Card: FC<ICard> = ({ story, userName, userEmail, userImage }) => {
  return (
    <div className="success-story-card my-4 p-4 bg-white rounded-md">
      <p className="text-xs lg:text-xl text-grey">{story}</p>
      <div className="flex items-center gap-2 mt-4 lg:mt-6">
        <img
          className="rounded-full w-10 lg:w-14"
          src={userImage}
          alt={userName}
        />
        <div>
          <p className="text-xs lg:text-lg">{userName}</p>
          <p className="text-[10px] lg:text-base text-grey">{userEmail}</p>
        </div>
      </div>
    </div>
  );
};
