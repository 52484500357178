import classNames from "classnames";
import React, { useState } from "react";
import config from "../../../../util/config";

interface CardData {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  link: string;
}

const cardsData: CardData[] = [
  {
    id: 1,
    title: "Exam Articles",
    description:
      "SRMJEE, the Joint Engineering Entrance Exam, is conducted twice a year in Phase 1 and Phase 2.",
    imageUrl: "/images/exams/exam-resource-01.jpeg",
    link: config.apiUrl + "/blogs/exams",
  },
  {
    id: 2,
    title: "Exam News",
    description:
      "SRMJEE, the Joint Engineering Entrance Exam, is conducted twice a year in Phase 1 and Phase 2.",
    imageUrl: "/images/exams/exam-resource-02.jpeg",
    link: config.apiUrl + "/blogs/exams",
  },
  {
    id: 3,
    title: "Exam Alerts",
    description:
      "TSRMJEE, the Joint Engineering Entrance Exam, is conducted twice a year in Phase 1 and Phase 2.",
    imageUrl: "/images/exams/exam-resource.png",
    link: config.apiUrl + "/blogs/exams",
  },
];

const backgroundColors = [
  "bg-[#00B489]",
  "bg-[#173CBA]",
  "bg-[#00B489]",
  "bg-[#F0C52B]",
  "bg-[#FF66CC]",
];

export const ExamResources = () => {
  const [expandedCardId, setExpandedCardId] = useState<number | null>(
    cardsData[1].id
  );

  const handleCardClick = (id: number) => {
    if (id !== expandedCardId) {
      setExpandedCardId(id);
    }
  };

  return (
    <div className="exam-resources-section bg-[#EEF2FF] pt-10 pb-14 max-sm:pt-5">
      <div className="lg:w-[1280px] mx-auto">
        <h2 className="text-[#383838] text-5xl font-semibold mb-11 max-sm:mb-4 ml-[108px] max-md:mx-auto max-md:text-center">
          Exam Resources
        </h2>

        <div className="flex w-full gap-3 min-max-xs:gap-1">
          {cardsData.map((card, index) => (
            <div
              key={card.id}
              onClick={() => handleCardClick(card.id)}
              className={classNames(
                "relative h-[340px] cursor-pointer transform transition-all duration-500 ease-in-out overflow-hidden rounded-tl-2xl rounded-bl-2xl",
                expandedCardId === card.id
                  ? "w-[788px] rounded-br-2xl rounded-tr-2xl min-max-xs:w-[256px]"
                  : "w-[231px] min-max-xs:w-20"
              )}
              style={{
                backgroundImage: `url(${card.imageUrl})`,
                backgroundSize: "cover",
              }}
            >
              <div
                className={`bg-opacity-75 bg-black text-white ${
                  expandedCardId === card.id ? " justify-center" : "p-0"
                } h-full flex flex-row relative`}
              >
                <div
                  className={`w-6/12 min-max-xss:p-2 p-4 ${
                    expandedCardId === card.id &&
                    backgroundColors[index % backgroundColors.length]
                  }`}
                >
                  <div
                    className={classNames(
                      "w-14",
                      expandedCardId === card.id
                        ? "text-xl rotate-0"
                        : "text-lg -rotate-90   absolute left-[-8px] bottom-16 min-max-xs:bottom-9",
                      expandedCardId !== card.id &&
                        backgroundColors[index % backgroundColors.length]
                    )}
                  >
                    <h2
                      className={classNames(
                        "font-semibold transform transition-transform duration-500 ease-in-out  p-2 whitespace-nowrap min-max-xs:text-xs"
                      )}
                    >
                      {card.title}
                    </h2>
                  </div>

                  <p
                    className={classNames(
                      "mt-2 text-sm transition-opacity duration-500 ease-in-out min-max-xs:text-xs",
                      expandedCardId === card.id ? "opacity-100" : "opacity-0"
                    )}
                  >
                    {card.description}
                  </p>
                  <div
                    className={classNames(
                      "mt-20",
                      expandedCardId === card.id ? "block" : "hidden"
                    )}
                  >
                    <a
                      href={card.link}
                      className="bg-white text-[#173CBA] min-max-xss:px-4 min-max-xss:py-1 min-max-xss:w-[185px] px-8 py-3 rounded-3xl w-[223px] absolute bottom-7 text-center min-max-xs:text-[10px] min-max-xs:px-3 min-max-xs:py-1 min-max-xs:w-[90px]"
                    >
                      View more
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="currentColor"
                        className="arrow-icon ml-2 min-max-xs:ml-1"
                      >
                        <path
                          d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div
                  className={classNames(
                    "w-6/12",
                    expandedCardId === card.id ? `block ` : "hidden"
                  )}
                >
                  <img
                    src={card.imageUrl}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
